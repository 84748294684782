import React from "react";
import { useGetEmployeeDataQuery } from "@/api/endpoints/payrollApi";
import PersonalData from "@/feature/payroll/forms/questionnaire/components/employeeData/steps/PersonalData";
import ChildrenData from "@/feature/payroll/forms/questionnaire/components/employeeData/steps/ChildrenData";
import { questionnaireTranslation } from "@/feature/payroll/components/TranslationConstants";
import EmploymentData from "@/feature/payroll/forms/questionnaire/components/employeeData/steps/EmploymentData";
import TaxData from "@/feature/payroll/forms/questionnaire/components/employeeData/steps/TaxData";

import VwlData from "@/feature/payroll/forms/questionnaire/components/employeeData/steps/VwlData";
import TaxableData from "@/feature/payroll/forms/questionnaire/components/employeeData/steps/TaxableData";
import {
  Employee,
  EmployeeEvent,
} from "@/feature/payroll/forms/questionnaire/schema/employee/EmployeeSchema";
import { HorizontalFlex, VerticalFlex } from "@/components/layout/Flex";
import { Title } from "@/components/text/Title";
import useHasInfo from "@/feature/payroll/forms/questionnaire/hooks/UseHasInfo";
import { QuestionnairePropsEmployee, SelectEnum } from "@/models/payroll";
import {
  SocialSecurityData
} from "@/feature/payroll/forms/questionnaire/components/employeeData/steps/SocialSecurityData";
import { FormSchemaEmployee } from "@/feature/payroll/forms/questionnaire/schema/employee/FormSchemaEmployee";
import { z } from "zod";

const EmployeeData: React.FC<{
  form?: QuestionnairePropsEmployee["form"];
  currentTitle?: string;
  selectedEmployee?: Employee;

}> = ({ currentTitle, selectedEmployee, form }) => {
  // API query to fetch employee data using the employee Id
  const { data: employee } = useGetEmployeeDataQuery(
    { employeeId: selectedEmployee?.id || "" },
    { skip: !selectedEmployee?.id },
  );
  // check if employee has vwl, taxableInformation or children
  const hasInfo = useHasInfo(employee);

  // get the relevant employee fill questionnaire event data from the API
  const employeeData = employee?.events?.find(
    (event: EmployeeEvent) => event?.employeeFillQuestionnaireEvent,
  )?.employeeFillQuestionnaireEvent;

  // render content based on the current section title
  const renderContent = () => {
    switch (currentTitle) {
      case questionnaireTranslation("employmentCard.occupation"):
        return (
          <VerticalFlex className="mt-12">
            {employeeData?.personalDetails && (
              <PersonalData
                personalDetails={employeeData.personalDetails}
                selectedEmployee={selectedEmployee}
              />
            )}
            {hasInfo?.hasChildren === SelectEnum.YES && (
              <ChildrenData children={employeeData?.children || []} />
            )}
            {employeeData?.employmentDetails && (
              <EmploymentData
                employmentDetails={employeeData?.employmentDetails}
                selectedEmployee={selectedEmployee}
              />
            )}
          </VerticalFlex>
        );
      case questionnaireTranslation("taxCard.taxes"):
        return (
          <VerticalFlex className="mt-12">
            <TaxData taxInformation={employeeData?.taxInformation || {}} />
            {hasInfo?.hasTaxableEmployment.valueOf() === SelectEnum.YES &&
              employeeData?.taxableEmployment && (
                <TaxableData
                  taxableEmployment={employeeData?.taxableEmployment}
                />
              )}
          </VerticalFlex>
        );

      case questionnaireTranslation("socialSecurityCard.socialSecurity"):
        return (
          <VerticalFlex className="mt-12">
            {employeeData?.socialSecurity && (
              <SocialSecurityData form={form} selectedEmployee={selectedEmployee}
                socialSecurity={employeeData?.socialSecurity}
              />
            )}
          </VerticalFlex>
        );

      case questionnaireTranslation("benefitsCard.benefits"):
        return (
          <VerticalFlex className="mt-12">
            {hasInfo?.hasVwl.valueOf() === SelectEnum.YES &&
              employeeData?.vwlDetails && (
                <VwlData vwlDetails={employeeData?.vwlDetails} />
              )}
          </VerticalFlex>
        );

      default:
        return (
          <VerticalFlex className="mt-12">
            {" "}
            <p>{questionnaireTranslation("employerFill")}</p>
          </VerticalFlex>
        );
    }
  };

  return (
    <VerticalFlex className="rounded-lg">
      <Title level={2}>
        {questionnaireTranslation("employeeQuestionnaire")}
      </Title>
      <HorizontalFlex className={"text-sm text-muted-foreground"}>
        {renderContent()}
      </HorizontalFlex>
    </VerticalFlex>
  );
};

export default EmployeeData;
