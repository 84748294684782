import React, { useEffect, useMemo, useState } from "react";
import { HorizontalFlex } from "@/components/layout/Flex";
import { Plus, X } from "lucide-react";
import { TransactionDocumentEmptyCard } from "@/feature/transactions/components/TransactionDocumentEmptyCard";
import { BankTransaction } from "@/models/bankTransaction";
import { useDocumentFilterQuery } from "@/api/endpoints/documentApi";

import { DocumentPreview } from "@/feature/documents/components/Card/CardFooter/DocumentPreview";
import { BiDocument } from "@/models/document";
import { matchingApi } from "@/api/endpoints/matchingApi";
import { BiTabs } from "@/components/layout/BiTabs";
import { Skeleton } from "@/components/ui/skeleton";

interface TransactionDocumentHandlerProps {
  transaction: BankTransaction;
}

export const TransactionDocumentTabs = ({
  transaction,
}: TransactionDocumentHandlerProps) => {
  const documentIds = useMemo(() => {
    return transaction.match?.documents.map((d) => d.documentId) || [];
  }, [transaction]);

  const [selectedTab, setSelectedTab] = useState<string>("");

  const { data: documentData } = useDocumentFilterQuery({
    kind: "oneof",
    attribute: "id",
    value: documentIds,
  });

  const [selectedDocument, setSelectedDocument] = useState<BiDocument | null>(
    null,
  );

  useEffect(() => {
    if (documentData && documentData.items.length > 0 && !selectedDocument) {
      setSelectedDocument(documentData.items[0]);
    } else {
      if (selectedDocument && !documentIds.includes(selectedDocument?.id))
        setSelectedDocument(null);
    }
  }, [documentData, documentIds, selectedDocument]);

  const [deleteMatch] = matchingApi.useDeleteMatchMutation();
  const [createMatch] = matchingApi.useCreateMatchMutation();

  const remove = async () => {
    if (transaction.match) {
      await deleteMatch(transaction.match).unwrap();
    }

    let newDocumentIds = documentIds.filter(
      (id) => id !== selectedDocument?.id,
    );

    if (newDocumentIds.length > 0) {
      createMatch({
        transactionIds: [transaction.id],
        documentIds: [...newDocumentIds],
      }).then(() => {});
    }
  };

  const tabs =
    documentData?.items.map((doc, index) => ({
      key: "" + index,
      label: (
        <HorizontalFlex align={"center"}>
          <span>{doc.invoiceNumber || <Skeleton />}</span>
          {`${index}` === selectedTab && (
            <X
              onClick={() => remove().then()}
              className={"hover:text-red-500"}
              size={14}
            />
          )}
        </HorizontalFlex>
      ),
      content: <DocumentPreview document={doc} noButton />,
    })) || [];

  tabs.push({
    key: "add",
    label: <Plus size={20} />,
    content: <TransactionDocumentEmptyCard transaction={transaction} />,
  });

  return <BiTabs tabs={tabs} onChange={setSelectedTab} />;
};
