import { Button } from "@/components/ui/button";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Form } from "@/components/ui/form";
import { useLoginMutation } from "@/api/endpoints/authApi";
import { Link, useNavigate } from "react-router-dom";
import { ReloadIcon } from "@radix-ui/react-icons";
import Logo from "@/components/page/components/Logo";
import { useAuth } from "@/feature/auth/AuthProvider";
import { useTranslation } from "react-i18next";
import { UserCompanyStatus } from "@/models/userCompany";
import { FormSimpleText } from "@/components/form/FormSimpleText";

const loginFormSchema = z.object({
  mail: z.string().email(),
  password: z.string().min(1),
});

export const Login = () => {
  const auth = useAuth();
  const navigation = useNavigate();
  const { t } = useTranslation();

  const form = useForm<z.infer<typeof loginFormSchema>>({
    resolver: zodResolver(loginFormSchema),
    defaultValues: {
      mail: "",
      password: "",
    },
  });

  const [login, loginResult] = useLoginMutation();

  const onSubmit = (value: { mail: string; password: string }) => {
    login(value).then((result) => {
      if ("data" in result) {
        let identities = result.data.identities.filter(
          (i) => i.status == UserCompanyStatus.ACTIVE,
        );
        auth.login(result.data.token, identities[0].company);
        navigation("/");
      }
    });
  };

  return (
    <>
      <div className="flex h-full flex-row-reverse flex-wrap text-lg font-medium">
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="flex h-full min-w-96 flex-1 flex-col items-center justify-center gap-4"
          >
            <Link className="absolute right-12 top-12" to={"/register"}>
              {t("component.auth.login.actionRegister")}
            </Link>
            <span className="text-2xl font-semibold">
              {t("component.auth.login.loginAction")}
            </span>
            <FormSimpleText
              form={form}
              className={"w-[350px]"}
              placeholder={t("model.user.mail")}
              name="mail"
              type="email"
            />
            <FormSimpleText
              form={form}
              placeholder={t("model.user.password")}
              className={"w-[350px]"}
              name="password"
              type="password"
            />
            <div className="flex h-20 flex-col items-center justify-end gap-4">
              {loginResult.isError && (
                <span className="text-base">
                  {t("component.auth.login.error")}
                </span>
              )}
              <Button
                type="submit"
                className="w-[350px]"
                disabled={loginResult.isLoading}
              >
                {loginResult.isLoading && (
                  <ReloadIcon className="mr-2 h-4 w-4 animate-spin" />
                )}
                {t("component.auth.login.loginAction")}
              </Button>
            </div>
            <Link
              to="/onboarding/forgot"
              className="text-xs hover:text-gray-300"
            >
              {t("component.auth.login.actionResetPassword")}
            </Link>
          </form>
        </Form>
        <div className="flex h-full min-w-96 flex-1 flex-col justify-between bg-muted/60">
          <div className="m-12 flex flex-row items-center gap-4">
            <Logo />
          </div>
          <span className="relative m-12">
            {t("component.auth.login.slogan")}
          </span>
        </div>
      </div>
    </>
  );
};
