import { z } from "zod";

export const ManagementSchema = z.object({
  employees: z.array(
    z.object({
      firstName: z.string(),
      lastName: z.string(),
      hourlyWage: z.coerce.number().optional().nullable(),
      weeklyWorkHours: z.coerce.number(),
      totalWorkedHours: z.coerce.number(),
      totalWage: z.coerce.number()
    })
  ),
});
