import React, { useEffect, useState } from "react";
import { useFieldArray } from "react-hook-form";
import { Button } from "@/components/ui/button";
import RadioFormField from "@/feature/payroll/components/formFields/RadioFormField";
import {
  payrollTranslation,
  questionnaireTranslation,
} from "@/feature/payroll/components/TranslationConstants";
import { DateInputField } from "@/feature/payroll/components/formFields/DateInputField";
import { FormSimpleNumber } from "@/components/form/FormSimpleNumber";
import { FormSimpleText } from "@/components/form/FormSimpleText";
import { Plus, X } from "lucide-react";

import { useParams } from "react-router";
import { useGetEmployeeQuestionnaireQuery } from "@/api/endpoints/payrollApi";
import { HorizontalFlex, VerticalFlex } from "@/components/layout/Flex";
import {
  EmploymentTypeEnum,
  QuestionnairePropsEmployee,
} from "@/models/payroll";

export function TaxableEmployment({ form }: QuestionnairePropsEmployee) {
  // manage dynamic fields for employee previousEmployments
  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: "taxableEmployment.previousEmployment",
  });

  const [employmentType, setEmploymentType] = useState<EmploymentTypeEnum>();

  //get employee token from url
  const { token } = useParams<{ token: string }>();

  //get employee data from api
  const { data: employee } = useGetEmployeeQuestionnaireQuery({
    employeeToken: token!,
  });

  //set employmentType based on fetched data
  useEffect(() => {
    setEmploymentType(employee?.sendQuestionnaireEvent?.employmentType);
  }, [employee]);

  return (
    <VerticalFlex>
      <VerticalFlex>
        {fields.map((field, index) => (
          <HorizontalFlex key={field.id} className="flex gap-4">
            <HorizontalFlex className="grid flex-grow gap-4 md:grid-cols-2 lg:grid-cols-4">
              <DateInputField
                control={form.control}
                name={`taxableEmployment.previousEmployment.${index}.dateFrom`}
                labelKey={questionnaireTranslation("taxableCard.from")}
              />

              <DateInputField
                control={form.control}
                name={`taxableEmployment.previousEmployment.${index}.dateUntil`}
                labelKey={questionnaireTranslation("taxableCard.until")}
              />

              <FormSimpleNumber
                form={form}
                name={`taxableEmployment.previousEmployment.${index}.daysOccupation`}
                label={questionnaireTranslation("taxableCard.daysOccupation")}
              />

              <FormSimpleText
                form={form}
                name={`taxableEmployment.previousEmployment.${index}.typeOccupation`}
                label={questionnaireTranslation("taxableCard.typeOccupation")}
              />
            </HorizontalFlex>
            <VerticalFlex className="flex justify-end sm:items-end">
              <Button
                type="button"
                variant="secondary"
                size={"icon"}
                onClick={() => remove(index)}
              >
                <X />
              </Button>
            </VerticalFlex>
          </HorizontalFlex>
        ))}
        <HorizontalFlex className={"flex items-center justify-center"}>
          <Button
            type="button"
            size={"icon"}
            variant={"secondary"}
            className={"mb-4"}
            onClick={() =>
              append({
                dateFrom: "",
                dateUntil: "",
                typeOccupation: "",
                daysOccupation: undefined,
              })
            }
          >
            <Plus />
          </Button>
        </HorizontalFlex>
      </VerticalFlex>

      {employmentType === EmploymentTypeEnum.MINIJOB && (
        <RadioFormField
          name={"taxableEmployment.miniJobberLimit.moneyLimit"}
          control={form.control}
          labelKey={questionnaireTranslation("taxableCard.moneyLimit")}
          options={[
            { label: payrollTranslation("universal.yes"), value: "yes" },
            { label: payrollTranslation("universal.no"), value: "no" },
          ]}
        />
      )}
    </VerticalFlex>
  );
}
