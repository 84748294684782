import React from "react";
import { payrollTranslation } from "@/feature/payroll/components/TranslationConstants";
import SelectFormField from "@/feature/payroll/components/formFields/SelectFormField";
import { FieldPath, UseFormReturn } from "react-hook-form";
import { z } from "zod";

interface MonthDropdownProps<T extends z.ZodTypeAny> {
  name: FieldPath<z.infer<T>>;
  control: UseFormReturn<z.infer<T>>["control"];
  labelKey?: string;
}
const MONTHS = [
  "january",
  "february",
  "march",
  "april",
  "may",
  "june",
  "july",
  "august",
  "september",
  "october",
  "november",
  "december",
];

export const MonthDropdown = <T extends z.ZodTypeAny>({
  control,
  name,
  labelKey,
}: MonthDropdownProps<T>) => {
  const monthOptions = MONTHS.map((month) => ({
    label: payrollTranslation(`months.${month}`),
    value: month,
  }));

  return (
    <SelectFormField
      name={name}
      control={control}
      labelKey={labelKey}
      options={monthOptions}
    />
  );
};

export default MonthDropdown;
