import React, { useState } from "react";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import { payrollTranslation } from "@/feature/payroll/components/TranslationConstants";
import { Trans } from "react-i18next";
import CloseButton from "@/feature/documents/components/Card/CardHeader/CloseButton";

interface CancelButtonProps {
  onCancelConfirm: () => void;
}

const CancelButton: React.FC<CancelButtonProps> = ({ onCancelConfirm }) => {
  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleCancelClick = () => {
    setShowConfirmation(true);
  };

  const handleCloseConfirmation = () => {
    setShowConfirmation(false);
  };

  const handleConfirmCancel = () => {
    onCancelConfirm();
    setShowConfirmation(false);
  };

  return (
    <div className="justify-left top-0 float-right flex items-start">
      <CloseButton onClick={handleCancelClick}/>
      {showConfirmation && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <Card className="w-[400px] p-4">
            <p className={"mb-4 text-sm"}>
              <Trans
                i18nKey={payrollTranslation("universal.onCancel")}
                components={{ strong: <strong />, br: <br /> }}
              />
            </p>

            <div className="flex justify-end space-x-2">
              <Button type="button" variant="ghost" onClick={handleCloseConfirmation}>
                {payrollTranslation("universal.no")}
              </Button>
              <Button type="button" variant="secondary" onClick={handleConfirmCancel}>
                {payrollTranslation("universal.yes")}
              </Button>
            </div>
          </Card>
        </div>
      )}
    </div>
  );
};

export default CancelButton;
