import { FilterParameters, FilterResult } from "@/api/types";
import { HorizontalFlex } from "@/components/layout/Flex";
import {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "@/components/ui/pagination";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import React, {
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";

export interface DataTablePaginationProps<T> {
  onChange?: (p: FilterParameters<T>) => void;
  result?: FilterResult<T>;
  defaultPageSize?: number;
}

export interface DataTablePaginationElement<T> {
  getPage: () => number;
  getPageSize: () => number;
  setPage: (page: number) => void;
  nextPage: () => void;
  prevPage: () => void;
}

function InnerDataTablePagination<T>(
  { onChange, result, defaultPageSize }: DataTablePaginationProps<T>,
  ref: React.ForwardedRef<DataTablePaginationElement<T>>,
) {
  const [pageSize, setPageSize] = useState<number>(defaultPageSize || 25);
  const [page, setPage] = useState<number>(0);

  const numPages = useMemo(() => {
    if (!result) {
      return undefined;
    }
    return Math.ceil(result.total / pageSize);
  }, [pageSize, result?.total]);

  useEffect(() => {
    if (!result) {
      return;
    }
    if (page * pageSize >= result?.total) {
      setPage(0);
    }
  }, [result?.total, pageSize, page]);

  const pagesShown = useMemo(() => {
    if (!numPages) {
      return [];
    }

    let pages: number[] = [];
    pages.push(0);
    if (page === 0 || page === 1) {
      pages.push(1, 2);
    }
    pages.push(page);
    if (
      page === numPages - 2 ||
      page === numPages - 3 ||
      page === numPages - 1
    ) {
      pages.push(numPages - 3, numPages - 2);
    }
    pages.push(numPages - 1);

    // only in range
    pages = pages.filter((p) => p >= 0 && p < numPages);
    // remove duplicates
    pages = pages.filter(function (item, pos) {
      return pages.indexOf(item) === pos;
    });
    // sort
    pages = pages.sort((a, b) => a - b);
    return pages;
  }, [page, numPages]);

  useEffect(() => {
    onChange?.({ limit: pageSize, offset: page * pageSize });
  }, [pageSize, page, onChange]);

  useImperativeHandle(ref, () => ({
    getPage: () => page,
    getPageSize: () => pageSize,
    setPage: (p) => setPage(p),
    nextPage: () => {
      if (numPages && page < numPages - 1) {
        setPage(page + 1);
      }
    },
    prevPage: () => {
      if (page && page > 0) {
        setPage(page - 1);
      }
    },
  }));

  return (
    <HorizontalFlex>
      <Select
        value={pageSize + ""}
        onValueChange={(v) => setPageSize(Number(v))}
      >
        <SelectTrigger className="w-[96px]">
          <SelectValue />
        </SelectTrigger>
        <SelectContent>
          <SelectItem value="10">10</SelectItem>
          <SelectItem value="15">15</SelectItem>
          <SelectItem value="25">25</SelectItem>
          <SelectItem value="50">50</SelectItem>
          <SelectItem value="100">100</SelectItem>
          <SelectItem value="250">250</SelectItem>
        </SelectContent>
      </Select>
      <Pagination>
        <PaginationContent>
          <PaginationItem>
            <PaginationPrevious
              disabled={page === 0 || !numPages}
              onClick={() => setPage(page - 1)}
            />
          </PaginationItem>
          {pagesShown.flatMap((p, index) => {
            let res = [
              <PaginationItem key={p}>
                <PaginationLink
                  isActive={page === p}
                  onClick={() => setPage(p)}
                >
                  {p + 1}
                </PaginationLink>
              </PaginationItem>,
            ];

            let showEllipsis = index > 0 && pagesShown[index - 1] < p - 1;
            if (showEllipsis) {
              res.unshift(
                <PaginationItem key={"e" + p}>
                  <PaginationEllipsis />
                </PaginationItem>,
              );
            }
            return res;
          })}

          <PaginationItem>
            <PaginationNext
              disabled={page === (numPages ?? 0) - 1 || !numPages}
              onClick={() => setPage(page + 1)}
            />
          </PaginationItem>
        </PaginationContent>
      </Pagination>
    </HorizontalFlex>
  );
}

export const DataTablePagination = React.forwardRef(InnerDataTablePagination);
