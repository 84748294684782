import { BiDocument, BiDocumentKind } from "@/models/document";
import React from "react";
import {
  FlexOne,
  HorizontalFlex,
  VerticalFlex,
} from "@/components/layout/Flex";
import { formatMoney, formatSimpleDate } from "@/util/format";
import { Skeleton } from "@/components/ui/skeleton";

interface DocumentResultProps {
  document: BiDocument;
  selected?: boolean;
  onClick?: () => void;
  highlight?: boolean;
}

export const DocumentResult = ({
  document,
  selected,
  onClick,
  highlight,
}: DocumentResultProps) => {
  return (
    <HorizontalFlex
      onClick={onClick}
      className={`relative cursor-pointer select-none items-center justify-center overflow-hidden rounded-[6px] border border-foreground/5 py-2 pl-8 pr-4 text-sm text-foreground transition-colors duration-300 hover:bg-foreground/25 ${selected ? "bg-foreground/20" : ""} ${highlight ? "bg-foreground/5" : ""}`}
    >
      <div
        className={`absolute left-0 top-0 h-full w-2 ${
          document.kind === BiDocumentKind.Invoice
            ? "bg-teal-500"
            : "bg-neutral-300"
        }`}
      ></div>
      <VerticalFlex gap={1}>
        <span className={"text-foreground/50"}>
          {document.invoiceNumber || (
            <Skeleton className={"h-[20px] w-[150px]"} />
          )}
        </span>
        {document.kind === BiDocumentKind.Receipt && (
          <span>
            {document.issuer.name || (
              <Skeleton className={"h-[20px] w-[300px]"} />
            )}
          </span>
        )}
        {document.kind === BiDocumentKind.Invoice && (
          <span>
            {document.recipient.name || (
              <Skeleton className={"h-[20px] w-[300px]"} />
            )}
          </span>
        )}
      </VerticalFlex>
      <FlexOne />
      <VerticalFlex gap={1}>
        <span className={"text-foreground/50"}>
          {formatSimpleDate(document.invoiceDate) || (
            <Skeleton className={"h-[20px] w-[100px]"} />
          )}
        </span>
        <span className={"text-right font-semibold"}>
          {formatMoney(document.derived.totalAmount)}
        </span>
      </VerticalFlex>
    </HorizontalFlex>
  );
};
