import React from "react";
import HorizontalGradientLine from "@/components/layout/HorizontalGradientLine";
import {
  payrollTranslation,
  questionnaireTranslation,
} from "@/feature/payroll/components/TranslationConstants";
import { QuestionnaireEmployeeType } from "@/feature/payroll/forms/questionnaire/schema/employee/FormSchemaEmployee";
import { Title } from "@/components/text/Title";
import { Underline } from "@/components/text/Underline";
import { HorizontalFlex } from "@/components/layout/Flex";

const VwlData: React.FC<{
  vwlDetails: QuestionnaireEmployeeType["vwlDetails"];
}> = ({ vwlDetails }) => {
  return (
    <section className="mb-8 font-light">
      <Underline>
        <Title level={4}>
          {questionnaireTranslation("benefitsCard.benefits")}
        </Title>
      </Underline>

      {vwlDetails?.length > 0 &&
        vwlDetails?.map((vwl, index) => (
          <HorizontalFlex
            key={index}
            className="mb-4 grid grid-cols-2 gap-4 md:grid-cols-4"
          >
            <span className="col-span-1 font-medium">
              {questionnaireTranslation("benefitsCard.receptionThrough")}:
            </span>
            <span className="col-span-1">{vwl.receptionThrough || "-"}</span>

            <span className="col-span-1 font-medium">
              {questionnaireTranslation("benefitsCard.sumBenefits")}:
            </span>
            <span className="col-span-1">
              {vwl.sumBenefits != null ? vwl.sumBenefits + " €" : "-"}
            </span>
            <span className="col-span-1 font-medium">
              {questionnaireTranslation("benefitsCard.since")}:
            </span>
            <span className="col-span-1">
              {vwl.dateSince
                ? new Date(vwl.dateSince).toLocaleDateString("de-DE")
                : "-"}
            </span>
            <span className="col-span-1 font-medium">
              {questionnaireTranslation("benefitsCard.contractNr")}:
            </span>
            <span className="col-span-1">{vwl.contractNumber || "-"}</span>
            <span className="col-span-1 font-medium">
              {payrollTranslation("universal.iban")}:
            </span>
            <span className="col-span-1">{vwl.iban || "-"}</span>
            <span className="col-span-1 font-medium">
              {payrollTranslation("universal.bic")}:
            </span>
            <span className="col-span-1">{vwl.bic || "-"}</span>
            <HorizontalGradientLine className={"col-span-4"} />
          </HorizontalFlex>
        ))}
    </section>
  );
};

export default VwlData;
