import React from "react";
import { payrollTranslation } from "@/feature/payroll/components/TranslationConstants";
import { AnimatePresence, motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import {
  AbsenceSchema,
  FormSchemaAbsence,
} from "@/feature/payroll/forms/absence/FormSchemaAbsence";
import { useStepManagement } from "@/feature/payroll/forms/questionnaire/hooks/UseStepManagement";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { defaultValuesAbsence } from "@/feature/payroll/forms/absence/DefaultValues";
import { MailOpen } from "lucide-react";
import { ClockLoader } from "react-spinners";
import useGetCurrentEmployee from "@/feature/payroll/hooks/UseGetCurrentEmployee";

interface AbsenceFormProps {
  setCurrentTitle?: (title: string) => void;
}

function AbsenceForm({setCurrentTitle }: AbsenceFormProps) {
  const form = useForm<z.infer<typeof FormSchemaAbsence>>({
    resolver: zodResolver(FormSchemaAbsence),
    defaultValues: defaultValuesAbsence,
  });
  //get current employee
  const { employee } = useGetCurrentEmployee();
  const { currentStep, totalSteps, handleStepChange, handleTotalStepsChange } =
    useStepManagement();

  const navigate = useNavigate();
  const handleClose = () => {
    navigate("/payroll");
  };

  async function onSubmit(value: AbsenceSchema) {
    try {
      console.log("Successfully submitted:", value);
    } catch (error) {
      console.error(
        "Error while submitting the employee questionnaire:",
        error,
      );
    }
  }
  return (
    <AnimatePresence mode="wait">
      <motion.div
        className={"flex flex-1 flex-col"}
        key="edit-absence"
        initial={{ opacity: 0, x: "100%" }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: "100%" }}
        transition={{ duration: 0.5, ease: "easeInOut" }}
      >
        <div className={"flex flex-1 flex-col items-center justify-center"}>
          <MailOpen className="h-16 w-16 text-gray-500" />
          <h1 className="mt-4 text-4xl font-bold">
            {payrollTranslation("absence")}
          </h1>
          <p className="mt-2 text-xl text-gray-500">Kommt bald</p>
          <ClockLoader color="#bdbdbd" />
        </div>

        {/*<VerticalFlex className="m-2 w-full">
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
              <HorizontalFlex
                className={"flex w-full items-center justify-between"}
              >
                <Title className={"mb-4"}>{payrollTranslation("absence")}</Title>
                <CancelButton onCancelConfirm={handleClose} />
              </HorizontalFlex>
               <Card className="h-auto w-full border-none pb-4 pl-8 pr-8 pt-4">
                <EmployeeDetail selectedEmployee={employee} editMode={true} />
              </Card>
              <Card className="mt-4 h-auto w-full pl-8 pr-8 pt-8">
                <MultiStepCard<typeof FormSchemaAbsence>
                  steps={StepsAbsence(form)}
                  form={form}
                  setCurrentTitle={setCurrentTitle}
                  onStepChange={handleStepChange}
                  onTotalStepsChange={handleTotalStepsChange}
                />

                {currentStep === totalSteps - 1 && (
                  <HorizontalFlex className={"mb-8 flex justify-center"}>
                    <Button type="submit">
                      {payrollTranslation("universal.save")}
                    </Button>
                  </HorizontalFlex>
                )}
              </Card>
            </form>
          </Form>
        </VerticalFlex>*/}
      </motion.div>
    </AnimatePresence>
  );
}

export default AbsenceForm;
