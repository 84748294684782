import React from "react";
import SelectFormField from "@/feature/payroll/components/formFields/SelectFormField";
import { questionnaireTranslation } from "@/feature/payroll/components/TranslationConstants";
import { FormSimpleNumber } from "@/components/form/FormSimpleNumber";
import { FormSimpleText } from "@/components/form/FormSimpleText";
import { FormSimpleMoney } from "@/components/form/FormSimpleMoney";
import { TaxesMiniJobber } from "@/feature/payroll/forms/questionnaire/components/TaxesMiniJobber";
import { useEmploymentType } from "@/feature/payroll/forms/questionnaire/hooks/UseEmploymentType";

import { Title } from "@/components/text/Title";
import { Underline } from "@/components/text/Underline";
import { HorizontalFlex, VerticalFlex } from "@/components/layout/Flex";
import {
  EmploymentTypeEnum,
  QuestionnairePropsEmployee,
  QuestionnairePropsEmployer,
  SelectEnum,
} from "@/models/payroll";

// tax information for employee
export function TaxesEmployee({ form }: QuestionnairePropsEmployee) {
  //watch tax class from form
  const selectedTaxClass = form.getValues("taxInformation.taxClass");
  const hasChildren = form.watch("additionalInfos.hasChildren");
  return (
    <VerticalFlex>
      <Underline>
        <Title level={4}>{questionnaireTranslation("taxCard.taxData")}</Title>
      </Underline>

      <HorizontalFlex
        className={"mb-4 grid gap-4 md:grid-cols-2 lg:grid-cols-4"}
      >
        <FormSimpleText
          form={form}
          name={"taxInformation.taxId"}
          label={questionnaireTranslation("taxCard.taxId")}
          inputProps={{
            maxLength: 11,
          }}
        />

        <SelectFormField
          name={"taxInformation.taxClass"}
          control={form.control}
          labelKey={questionnaireTranslation("taxCard.taxClass")}
          defaultValue={"taxClass1"}
          options={[
            {
              label: `${questionnaireTranslation("taxCard.taxClass")} 1`,
              value: "1",
            },
            {
              label: `${questionnaireTranslation("taxCard.taxClass")} 2`,
              value: "2",
            },
            {
              label: `${questionnaireTranslation("taxCard.taxClass")} 3`,
              value: "3",
            },
            {
              label: `${questionnaireTranslation("taxCard.taxClass")} 4`,
              value: "4",
            },
            {
              label: `${questionnaireTranslation("taxCard.taxClass")} 5`,
              value: "5",
            },
            {
              label: `${questionnaireTranslation("taxCard.taxClass")} 6`,
              value: "6",
            },
          ]}
        />

        {selectedTaxClass === "4" ? (
          <FormSimpleNumber
            hoverInfoTitle={questionnaireTranslation("taxCard.factor")}
            hoverInfoDescription={questionnaireTranslation(
              "taxCard.infoCard.infoTaxFactor",
            )}
            form={form}
            name={"taxInformation.taxFactor"}
            label={questionnaireTranslation("taxCard.factor")}
          />
        ) : null}

        {hasChildren === SelectEnum.YES && (
          <FormSimpleNumber
            hoverInfoTitle={questionnaireTranslation("taxCard.childAllowance")}
            hoverInfoDescription={questionnaireTranslation(
              "taxCard.infoCard.infoChildAllowance",
            )}
            form={form}
            name={"taxInformation.childAllowance"}
            label={questionnaireTranslation("taxCard.childAllowance")}
          />
        )}

        <SelectFormField
          name={"taxInformation.identificationEmployer"}
          control={form.control}
          labelKey={questionnaireTranslation("taxCard.identificationEmployer")}
          defaultValue={"taxClass1"}
          options={[
            {
              label: questionnaireTranslation("taxCard.mainEmployer"),
              value: "mainEmployer",
            },
            {
              label: questionnaireTranslation("taxCard.sideEmployer"),
              value: "sideEmployer",
            },
          ]}
        />
      </HorizontalFlex>
    </VerticalFlex>
  );
}

// tax information for employer
export function TaxesEmployer({
  form,
  selectedEmployee,
}: QuestionnairePropsEmployer) {
  //get employment type for employer
  const { employmentType } = useEmploymentType(selectedEmployee!);
  return (
    <VerticalFlex>
      <Underline>
        <Title level={4}>{questionnaireTranslation("taxCard.taxData")}</Title>
      </Underline>
      {employmentType === EmploymentTypeEnum.MINIJOB ? (
        <TaxesMiniJobber form={form} />
      ) : (
        questionnaireTranslation("employeeFill")
      )}
    </VerticalFlex>
  );
}
