import { Button } from "@/components/ui/button";
import { PromptForm } from "./PromptForm";
import { ButtonScrollToBottom } from "./ButtonScrollToButton";
import { RefreshCwIcon, StopCircleIcon } from "lucide-react";
import React from "react";

export function ChatPanel({
  id,
  isLoading,
  stop,
  append,
  reload,
  input,
  setInput,
  messages,
  isChatOverlay,
  uiContext, // BiDocument | undefined
  removeContext, // : () => void,
}) {
  return (
    <div
      className={` ${!isChatOverlay ? "absolute -bottom-12" : "relative -bottom-0"} left-0 w-full transform sm:left-1/2 sm:min-w-[600px] sm:-translate-x-1/2 lg:min-w-[800px]`}
    >
      <ButtonScrollToBottom />
      <div
        className={`px-4 ${
          isChatOverlay ? "sm:max-w-md" : "sm:max-w-3xl"
        } sm:mx-auto sm:px-1`}
      >
        {/* {isLoading && (
          <div className="mb-1 mt-2 flex w-full flex-col items-center space-y-2 pb-2">
            <span className="text-cacao-dark text-sm">Analysiere Daten...</span>
          </div>
        )} */}
        <div className="flex h-10 items-center justify-center">
          {isLoading ? (
            <Button
              variant="outline"
              onClick={() => stop()}
              className="bg-background"
            >
              <StopCircleIcon className="mr-2" />
              Abbrechen
            </Button>
          ) : (
            messages?.length > 0 && (
              <Button
                variant="outline"
                onClick={() => reload()}
                className="bg-background"
              >
                <RefreshCwIcon className="mr-2" />
                Neu generieren
              </Button>
            )
          )}
        </div>
        <div
          className={
            isLoading
              ? "bg-cacao animate-pulseGlow space-y-4 border-t px-4 py-2 shadow-lg sm:rounded-t-xl sm:border md:py-4"
              : "bg-cacao items-center space-y-4 border-t px-4 py-2 shadow-lg sm:rounded-t-xl sm:border md:py-4"
          }
        >
          <PromptForm
            onSubmit={async (value) => {
              await append({
                id,
                content: value,
                role: "user",
              });
            }}
            input={input}
            setInput={setInput}
            isLoading={isLoading}
            uiContext={uiContext}
            removeContext={removeContext}
          />
        </div>
      </div>
    </div>
  );
}
