import { FieldValues } from "react-hook-form/dist/types/fields";
import { FieldPath } from "react-hook-form/dist/types";

import React from "react";
import { cn } from "@/lib/utils";
import {
  FormSimpleNumber,
  FormSimpleNumberProps,
} from "@/components/form/FormSimpleNumber";
import { formatNumberFancy, parseNumber } from "@/util/format";

export const FormSimpleMoney = <
  T extends FieldValues = FieldValues,
  TName extends FieldPath<T> = FieldPath<T>,
>(
  props: FormSimpleNumberProps<T, TName> & { mode?: "cent" | "euro" },
) => {
  let useEuro = props.mode === "euro"; // defaults to cent #legacy code
  return (
    <FormSimpleNumber
      postfix={(value) => (
        <span className={cn("ml-3 pt-0.5", value < 0 ? "text-red-300" : "")}>
          {"€"}
        </span>
      )}
      valueDisplay={(value) =>
        useEuro ? formatNumberFancyFullEuro(value) : formatNumberFancy(value)
      }
      parseNumber={useEuro ? parseNumberFullEuro : parseNumber}
      {...props}
    />
  );
};

function formatNumberFancyFullEuro(value: number) {
  return Intl.NumberFormat("de-DE", {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  }).format(value);
}

function parseNumberFullEuro(value: string | number) {
  const strValue = value.toString();
  const v = Math.round(parseFloat(strValue.replace(/,/g, ".")) * 100) / 100;
  if (isNaN(v)) {
    return 0;
  }
  return v;
}
