import { UseFormReturn } from "react-hook-form";
import { z } from "zod";
import {
  Coins,
  HandCoins,
  HandHeart,
  Hourglass,
  Receipt,
  SquareMousePointer,
} from "lucide-react";
import React from "react";
import { EmploymentEmployer } from "@/feature/payroll/forms/questionnaire/steps/Employment";
import { Limitation } from "@/feature/payroll/forms/questionnaire/steps/Limit";
import { TaxesEmployer } from "@/feature/payroll/forms/questionnaire/steps/Taxes";
import { SocialSecurityEmployer } from "@/feature/payroll/forms/questionnaire/steps/SocialSecurity";
import { Payment } from "@/feature/payroll/forms/questionnaire/steps/Payment";
import { BenefitsEmployer } from "@/feature/payroll/forms/questionnaire/steps/Benefits";
import { questionnaireTranslation } from "@/feature/payroll/components/TranslationConstants";
import { Employee } from "@/feature/payroll/forms/questionnaire/schema/employee/EmployeeSchema";
import { FormSchemaEmployer } from "@/feature/payroll/forms/questionnaire/schema/employer/FormSchemaEmployer";
import { useEmployerFilter } from "@/feature/payroll/forms/questionnaire/hooks/UseFilter";
import { Step } from "@/models/payroll";

//Steps for Employer with optional filter that removes steps for vwl if field "no" is checked
export const StepsQuestionnaireEmployer = (
  form: UseFormReturn<z.infer<typeof FormSchemaEmployer>>,
  selectedEmployee: Employee,
): Step<typeof FormSchemaEmployer>[] => {
  // apply filters to steps based on employee data
  const { filter } = useEmployerFilter(selectedEmployee);

  // array of questionnaire steps
  return [
    {
      icon: <SquareMousePointer className="h-5 w-5" />,
      title: questionnaireTranslation("employmentCard.occupation"),
      component: (
        <EmploymentEmployer form={form} selectedEmployee={selectedEmployee} />
      ),
      requiredFields: ["employmentDetails.entryDate"],
    },
    {
      icon: <Hourglass className="h-5 w-5" />,
      title: questionnaireTranslation("limitCard.timeLimit"),
      component: <Limitation form={form} selectedEmployee={selectedEmployee} />,
      requiredFields: [],
    },
    {
      icon: <Receipt className="h-5 w-5" />,
      title: questionnaireTranslation("taxCard.taxes"),
      component: (
        <TaxesEmployer form={form} selectedEmployee={selectedEmployee} />
      ),
      requiredFields: [],
    },
    {
      icon: <HandHeart className="h-5 w-5" />,
      title: questionnaireTranslation("socialSecurityCard.socialSecurity"),
      component: (
        <SocialSecurityEmployer
          form={form}
          selectedEmployee={selectedEmployee}
        />
      ),
      requiredFields: [],
    },
    {
      icon: <HandCoins className="h-5 w-5" />,
      title: questionnaireTranslation("paymentCard.payment"),
      component: <Payment form={form} selectedEmployee={selectedEmployee} />,
      requiredFields: [],
    },
    {
      icon: <Coins className="h-5 w-5" />,
      title: questionnaireTranslation("benefitsCard.benefits"),
      component: (
        <BenefitsEmployer form={form} selectedEmployee={selectedEmployee} />
      ),
      requiredFields: [],
      filter: filter({ hasVwl: "yes" }),
    },
  ];
};
