import React from "react";
import { QuestionnaireEmployeeType } from "@/feature/payroll/forms/questionnaire/schema/employee/FormSchemaEmployee";
import {
  payrollTranslation,
  questionnaireTranslation,
} from "@/feature/payroll/components/TranslationConstants";
import { Underline } from "@/components/text/Underline";
import { Title } from "@/components/text/Title";
import { HorizontalFlex } from "@/components/layout/Flex";
import { QuestionnairePropsEmployee, SelectEnum } from "@/models/payroll";
import { Employee } from "@/feature/payroll/forms/questionnaire/schema/employee/EmployeeSchema";
import useHasInfo from "@/feature/payroll/forms/questionnaire/hooks/UseHasInfo";

export interface DataProps {
  form?: QuestionnairePropsEmployee["form"];
  selectedEmployee?: Employee;
}
interface TaxDataProps extends DataProps {
  taxInformation: QuestionnaireEmployeeType["taxInformation"];
}
export const TaxData: React.FC<TaxDataProps> = ({
  taxInformation,
  form,
  selectedEmployee,
}) => {
  const hasInfo = useHasInfo(selectedEmployee);
  const children = form?.watch("additionalInfos.hasChildren");
  const taxClass = form?.watch("taxInformation.taxClass");
  return (
    <section className="mb-8 font-light">
      <Underline>
        <Title level={4}>{questionnaireTranslation("taxCard.taxes")}</Title>
      </Underline>

      <HorizontalFlex className="grid grid-cols-2 gap-4 md:grid-cols-4">
        <span className="col-span-1 font-medium">
          {questionnaireTranslation("taxCard.taxId")}:
        </span>
        <span className="col-span-1">{taxInformation?.taxId || "-"}</span>
        <span className="col-span-1 font-medium">
          {questionnaireTranslation("taxCard.taxClass")}:
        </span>
        <span className="col-span-1">{taxInformation?.taxClass || "-"}</span>

        {(taxInformation.taxClass === "4" || taxClass === "4") && (
          <div className={"grid grid-cols-2 md:col-span-1 lg:col-span-2"}>
            <span className=" font-medium">
              {questionnaireTranslation("taxCard.factor")}:
            </span>
            <span>
              {taxInformation?.taxFactor || "-"}
            </span>
          </div>
        )}

        {(hasInfo?.hasChildren === SelectEnum.YES ||
          children === SelectEnum.YES) && (
          <div className={"grid grid-cols-2 md:col-span-1 lg:col-span-2"}>
            <span className="font-medium">
              {questionnaireTranslation("taxCard.childAllowance")}:
            </span>
            <span className="">
              {taxInformation?.childAllowance != null
                ? taxInformation.childAllowance
                : "-"}
            </span>
          </div>
        )}

        <span className="col-span-1 font-medium">
          {questionnaireTranslation("taxCard.identificationEmployer")}:
        </span>
        <span className="col-span-1">
          {taxInformation?.identificationEmployer
            ? questionnaireTranslation(
                `taxCard.${taxInformation?.identificationEmployer}`,
              )
            : "-"}
        </span>
      </HorizontalFlex>
    </section>
  );
};

export default TaxData;
