import React from "react";
import {
  payrollTranslation,
  questionnaireTranslation,
} from "@/feature/payroll/components/TranslationConstants";
import { QuestionnaireEmployeeType } from "@/feature/payroll/forms/questionnaire/schema/employee/FormSchemaEmployee";
import { Underline } from "@/components/text/Underline";
import { Title } from "@/components/text/Title";
import { HorizontalFlex } from "@/components/layout/Flex";
import { Employee } from "@/feature/payroll/forms/questionnaire/schema/employee/EmployeeSchema";
import useHasInfo from "@/feature/payroll/forms/questionnaire/hooks/UseHasInfo";
import { SelectEnum } from "@/models/payroll";
import { DataProps } from "@/feature/payroll/forms/questionnaire/components/employeeData/steps/TaxData";
interface EmploymentProps extends DataProps {
  employmentDetails: QuestionnaireEmployeeType["employmentDetails"];
}
export const EmploymentData: React.FC<EmploymentProps> = ({
  employmentDetails,
  form,
  selectedEmployee,
}) => {
  const construction = form?.watch("additionalInfos.isInConstruction");
  const additionalEmployment = form?.watch(
    "employmentDetails.additionalEmployment",
  );
  const hasInfo = useHasInfo(selectedEmployee);

  return (
    <section className="mb-8 font-light">
      <Underline>
        <Title level={4}>
          {questionnaireTranslation("employmentCard.occupation")}
        </Title>
      </Underline>

      <HorizontalFlex className="grid grid-cols-2 gap-4 md:grid-cols-4">
        <span className="col-span-1 font-medium">
          {questionnaireTranslation("employmentCard.jobTitle")}:
        </span>
        <span className="col-span-1">{employmentDetails?.jobTitle || "-"}</span>

        <span className="col-span-1 font-medium">
          {questionnaireTranslation("employmentCard.additionalEmployment")}:
        </span>
        <span className="col-span-1">
          {employmentDetails?.additionalEmployment
            ? payrollTranslation(
              `universal.${employmentDetails?.additionalEmployment}`,
            )
            : "-"}
        </span>

        {(employmentDetails?.additionalEmployment === SelectEnum.YES ||
          additionalEmployment === SelectEnum.YES) && (
          <div className={"grid grid-cols-2 md:col-span-1 lg:col-span-2"}>
            <span className="col-span-1 font-medium">
              {questionnaireTranslation("employmentCard.minorEmployment")}:
            </span>
            <span className="col-span-1">
              {employmentDetails?.minorEmployment
                ? payrollTranslation(
                  `universal.${employmentDetails?.minorEmployment}`,
                )
                : "-"}
            </span>
          </div>
        )}
        <span className="col-span-1 font-medium">
          {questionnaireTranslation("employmentCard.highestEduQualification")}:
        </span>
        <span className="col-span-1">
          {employmentDetails?.highestSchoolDegree &&
          employmentDetails.highestSchoolDegree.length > 0
            ? questionnaireTranslation(
              `employmentCard.eduValues.${employmentDetails.highestSchoolDegree}`,
            )
            : "-"}
        </span>

        <span className="col-span-1 font-medium">
          {questionnaireTranslation("employmentCard.highestProfEducation")}:
        </span>
        <span className="col-span-1">
          {employmentDetails.highestProfessionalEducation &&
          employmentDetails.highestProfessionalEducation?.length > 0
            ? questionnaireTranslation(
              `employmentCard.profValues.${employmentDetails?.highestProfessionalEducation}`,
            )
            : "-"}
        </span>

        {(hasInfo?.isInConstruction === SelectEnum.YES ||
          construction === SelectEnum.YES) && (
          <div className={"grid grid-cols-2 md:col-span-1 lg:col-span-2"}>
            <span className=" font-medium">
              {questionnaireTranslation(
                "employmentCard.constructionEmployment",
              )}
              :
            </span>
            <span>
              {employmentDetails?.constructionEmployment
                ? new Date(
                  employmentDetails?.constructionEmployment,
                ).toLocaleDateString("de-DE")
                : "-"}
            </span>
          </div>
        )}
      </HorizontalFlex>
    </section>
  );
};

export default EmploymentData;
