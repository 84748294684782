import { BiDocument } from "@/models/document";
import { DocumentPreview } from "@/feature/documents/components/Card/CardFooter/DocumentPreview";
import { BiTabs } from "@/components/layout/BiTabs";
import { BookingDisplay } from "@/feature/accounting/components/BookingDisplay";
import React from "react";
import { useLoggedIn } from "@/feature/auth/ProtectedRoutes";

export const DocumentCardFooter = ({ document }: { document: BiDocument }) => {
  const { activeCompany } = useLoggedIn();

  let tabs = [
    {
      label: "Vorschau",
      content: <DocumentPreview document={document} />,
    },
  ];

  if (
    document.bookings?.find(
      (b) => b.accountingModel == activeCompany.accounting?.taxSystem,
    )
  ) {
    tabs.push({
      label: "Buchungen",
      content: (
        <>
          <div className={"h-4"} />
          <BookingDisplay bookings={document.bookings} />
        </>
      ),
    });
  }

  return <BiTabs tabs={tabs} />;
};
