import React, { useEffect, useRef } from "react";
import {
  payrollTranslation,
  questionnaireTranslation,
} from "@/feature/payroll/components/TranslationConstants";
import { FormSimpleText } from "@/components/form/FormSimpleText";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { Form } from "@/components/ui/form";
import { Button } from "@/components/ui/button";
import {
  Employee,
  UpdateEmployeeSchema,
} from "@/feature/payroll/forms/questionnaire/schema/employee/EmployeeSchema";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  useGetEmployeeDataQuery,
  useUpdateEmployeesMutation,
} from "@/api/endpoints/payrollApi";
import { AnimatePresence } from "framer-motion";
import { VerticalFlex } from "@/components/layout/Flex";

export interface CreateEmployeeProps {
  onClose: () => void;
  selectedEmployee?: Employee;
}

export const UpdateEmployee: React.FC<CreateEmployeeProps> = ({
  onClose,
  selectedEmployee,
}) => {
  const firstNameRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (firstNameRef.current) {
      firstNameRef.current.focus();
    }
  }, []);

  // Get employee data from API
  const { data: employeeData } = useGetEmployeeDataQuery(
    { employeeId: selectedEmployee?.id! },
    { skip: !selectedEmployee?.id },
  );

  const [updateEmployees] = useUpdateEmployeesMutation();

  // Initialize the form with default values
  const form = useForm({
    resolver: zodResolver(UpdateEmployeeSchema),
    defaultValues: {
      firstName: employeeData?.firstName || "",
      lastName: employeeData?.lastName || "",
      email: employeeData?.email || "",
    },
  });

  // Update form values when employeeData changes
  useEffect(() => {
    if (employeeData) {
      form.reset({
        firstName: employeeData.firstName,
        lastName: employeeData.lastName,
        email: employeeData.email,
      });
    }
  }, [employeeData, form]);

  async function onSubmit(values: z.infer<typeof UpdateEmployeeSchema>) {
    try {
      if (selectedEmployee) {
        const result = await updateEmployees({
          employee: values,
          employeeId: selectedEmployee.id,
        }).unwrap();

        console.log("Erstellt:", result);
        onClose();
      } else {
        console.error("Kein Mitarbeiter angegeben.");
      }
    } catch (error) {
      console.error("Fehler beim Erstellen des Mitarbeiters:", error);
    }
  }

  return (
    <AnimatePresence mode="wait">
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <VerticalFlex>
            <FormSimpleText
              form={form}
              name="firstName"
              label={`${payrollTranslation("universal.firstName")}`}
              type="text"
              autoFocus={true}
            />

            <FormSimpleText
              form={form}
              name="lastName"
              label={`${payrollTranslation("universal.lastName")}`}
              type="text"
            />

            <FormSimpleText
              form={form}
              name="email"
              label={`${questionnaireTranslation("personalCard.email")}`}
              type="email"
            />

            <VerticalFlex
              className={"col-span-2 flex w-full items-center justify-center"}
            >
              <Button
                className={"m-4 mx-auto w-[50%]"}
                type={"submit"}
                variant={"secondary"}
              >
                {questionnaireTranslation(
                  "employeeOverviewCard.updateEmployee",
                )}
              </Button>
            </VerticalFlex>
          </VerticalFlex>
        </form>
      </Form>
    </AnimatePresence>
  );
};
