import { ReactNode } from "react";
import { cn } from "@/lib/utils";

export const Text = (props: {
  children: ReactNode;
  muted?: boolean;
  small?: boolean;
}) => {
  return (
    <span
      className={cn(
        "text-base",
        props.muted && "text-muted-foreground",
        props.small && "text-sm",
      )}
    >
      {props.children}
    </span>
  );
};
