import React from "react";
import { Button } from "@/components/ui/button";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { HorizontalFlex } from "@/components/layout/Flex";

interface NavigationButtonsProps {
  currentStep: number;
  totalSteps: number;
  onNext: () => void;
  onPrev: () => void;
}

export const NavigationButtons: React.FC<NavigationButtonsProps> = ({
  currentStep,
  totalSteps,
  onNext,
  onPrev,
}) => {
  return (
    <HorizontalFlex className="w-full flex justify-between">
      {currentStep > 0 && (
        <Button type="button" size={"icon"} onClick={onPrev} variant="secondary">
          <ChevronLeft />
        </Button>
      )}
      {currentStep < totalSteps - 1 && (
        <Button type="button" size={"icon"} variant={"secondary"} onClick={onNext}>
          <ChevronRight />
        </Button>
      )}
    </HorizontalFlex>
  );
};
