import React from "react";
import { useEmploymentType } from "@/feature/payroll/forms/questionnaire/hooks/UseEmploymentType";
import {
  payrollTranslation,
  questionnaireTranslation,
} from "@/feature/payroll/components/TranslationConstants";
import { FormSimpleMoney } from "@/components/form/FormSimpleMoney";
import { DateInputField } from "@/feature/payroll/components/formFields/DateInputField";
import { FormSimpleText } from "@/components/form/FormSimpleText";
import MonthDropdown from "@/feature/payroll/components/formFields/MonthDropdown";
import { useFieldArray } from "react-hook-form";
import { Button } from "@/components/ui/button";
import { Plus, X } from "lucide-react";
import { HorizontalFlex, VerticalFlex } from "@/components/layout/Flex";
import { Title } from "@/components/text/Title";
import { Underline } from "@/components/text/Underline";
import {
  EmploymentTypeEnum,
  QuestionnairePropsEmployer,
} from "@/models/payroll";

const YEARS = ["yearOne", "yearTwo", "yearThree"] as const;

export function Payment({
  form,
  selectedEmployee,
}: QuestionnairePropsEmployer) {
  // determine employment type
  const { employmentType } = useEmploymentType(selectedEmployee!);
  // manage dynamic fields for employee additionalPayments
  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: "payment.additionalPayments",
  });

  return (
    <VerticalFlex className="mb-2 w-full gap-4">
      {employmentType === EmploymentTypeEnum.TRAINEE ? (
        <VerticalFlex>
          <Underline>
            <Title level={4}>
              {questionnaireTranslation("paymentCard.traineePayment")}
            </Title>
          </Underline>
          {YEARS.map((year, yearIndex) => (
            <VerticalFlex key={yearIndex} className="mb-4">

              <Title level={5}>
                {questionnaireTranslation(`paymentCard.${year}`)}
              </Title>
              <HorizontalFlex className="grid grid-cols-2 gap-4">
                <HorizontalFlex>
                  <FormSimpleMoney
                    mode={"euro"}
                    form={form}
                    name={`payment.traineePayment.${year}.sum`}
                    label={questionnaireTranslation("paymentCard.sum")}
                  />
                </HorizontalFlex>
                <HorizontalFlex>
                  <DateInputField
                    control={form.control}
                    name={`payment.traineePayment.${year}.dateSum`}
                    labelKey={payrollTranslation("universal.validFrom")}
                  />
                </HorizontalFlex>
                <HorizontalFlex>
                  <FormSimpleMoney
                    mode={"euro"}
                    className={"min-w-1"}
                    form={form}
                    name={`payment.traineePayment.${year}.hourlyWage`}
                    label={questionnaireTranslation("paymentCard.hourlyWage")}
                  />
                </HorizontalFlex>
                <HorizontalFlex>
                  <DateInputField
                    control={form.control}
                    name={`payment.traineePayment.${year}.dateWage`}
                    labelKey={payrollTranslation("universal.validFrom")}
                  />
                </HorizontalFlex>
              </HorizontalFlex>
            </VerticalFlex>
          ))}
        </VerticalFlex>
      ) : (
        <VerticalFlex className="mb-2 grid grid-cols-2 gap-4">
          <HorizontalFlex className="col-span-2">
            <Underline>
              <Title level={4}>
                {questionnaireTranslation("paymentCard.wage")}
              </Title>
            </Underline>

          </HorizontalFlex>

          <HorizontalFlex className="col-span-2">
            <FormSimpleText
              form={form}
              name={"payment.description"}
              label={questionnaireTranslation("paymentCard.paymentDescription")}
            />
          </HorizontalFlex>

          <FormSimpleMoney
            mode={"euro"}
            form={form}
            name={"payment.sum"}
            label={questionnaireTranslation("paymentCard.sum")}
            itemClassName={"mb-2"}
          />
          <DateInputField
            control={form.control}
            name={"payment.dateSum"}
            labelKey={payrollTranslation("universal.validFrom")}
          />
          <FormSimpleMoney
            mode={"euro"}
            form={form}
            name={"payment.hourlyWage"}
            label={questionnaireTranslation("paymentCard.hourlyWage")}
            itemClassName={"mb-2"}
          />
          <DateInputField
            control={form.control}
            name={"payment.dateWage"}
            labelKey={payrollTranslation("universal.validFrom")}
          />
        </VerticalFlex>
      )}
      <Underline>
        <Title level={4}>
          {questionnaireTranslation("paymentCard.specialPayment")}
        </Title>
      </Underline>

      <HorizontalFlex className={"mb-2 grid grid-cols-2 gap-4"}>
        <FormSimpleMoney
          mode={"euro"}
          form={form}
          name={"payment.christmasMoney"}
          label={questionnaireTranslation("paymentCard.christmasMoney")}
          itemClassName={"mb-2"}
        />
        <MonthDropdown
          name={"payment.payoutMonthChristmas"}
          control={form.control}
          labelKey={questionnaireTranslation("paymentCard.payoutMonth")}
        />

        <FormSimpleMoney
          mode={"euro"}
          form={form}
          name={"payment.vacationMoney"}
          label={questionnaireTranslation("paymentCard.vacationMoney")}
          itemClassName={"mb-2"}
        />
        <MonthDropdown
          name={"payment.payoutMonthVacation"}
          control={form.control}
          labelKey={questionnaireTranslation("paymentCard.payoutMonth")}
        />
      </HorizontalFlex>
      <Underline>
        <Title level={4}>
          {questionnaireTranslation("paymentCard.additionalPayments")}
        </Title>
      </Underline>

      <HorizontalFlex className={"col-span-2"}>
        {fields.map((field, index) => (
          <HorizontalFlex
            key={field.id}
            className="mb-2 grid grid-cols-2 gap-4"
          >
            <FormSimpleText
              form={form}
              name={`payment.additionalPayments.${index}.additionalPaymentName`}
              label={questionnaireTranslation("paymentCard.paymentDescription")}
            />

            <FormSimpleMoney
              mode={"euro"}
              form={form}
              name={`payment.additionalPayments.${index}.additionalPaymentSum`}
              label={questionnaireTranslation("paymentCard.sum")}
              itemClassName={"mb-2"}
            />

            <HorizontalFlex className="col-span-2 flex w-full items-center justify-center sm:items-end">
              <Button
                type="button"
                variant="secondary"
                size={"icon"}
                onClick={() => remove(index)}
              >
                <X />
              </Button>
            </HorizontalFlex>
          </HorizontalFlex>
        ))}

        <Button
          type="button"
          size={"icon"}
          variant={"secondary"}
          className={"mt-3"}
          onClick={() =>
            append({
              additionalPaymentName: "",
              additionalPaymentSum: null,
            })
          }
        >
          <Plus />
        </Button>
      </HorizontalFlex>
    </VerticalFlex>
  );
}
