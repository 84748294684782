import React from "react";
import { Card } from "@/components/ui/card";
import { cn } from "@/lib/utils";
import { HorizontalFlex, VerticalFlex } from "@/components/layout/Flex";
import { Employee } from "@/feature/payroll/forms/questionnaire/schema/employee/EmployeeSchema";

import { payrollTranslation } from "@/feature/payroll/components/TranslationConstants";

export interface EmployeeDetailProps {
  selectedEmployee?: Employee;
  editMode?: boolean;
}

const EmployeeDetail: React.FC<EmployeeDetailProps> = ({
  selectedEmployee,
  editMode,
}) => {
  if (!selectedEmployee) return null;

  return (
    <HorizontalFlex
      className={cn(
        "grid w-full gap-2 text-sm",
        editMode ? "grid-cols-[1fr_2fr_1fr_2fr]" : "grid-cols-[1fr_2fr]",
      )}
    >
      <span>
        {payrollTranslation("universal.firstName")}:
      </span>
      <span>{selectedEmployee.firstName}</span>
      <span>
        {payrollTranslation("universal.lastName")}:
      </span>
      <span>{selectedEmployee.lastName}</span>
      <span>{payrollTranslation("universal.email")}:</span>
      <span>{selectedEmployee.email}</span>
      <span>
        {payrollTranslation("universal.personnelNumber")}:
      </span>
      <span>{selectedEmployee.personnelNumber}</span>
    </HorizontalFlex>
  );
};

export default EmployeeDetail;
