import React from "react";
import { questionnaireTranslation } from "@/feature/payroll/components/TranslationConstants";
import { DateInputField } from "@/feature/payroll/components/formFields/DateInputField";
import { useEmploymentType } from "@/feature/payroll/forms/questionnaire/hooks/UseEmploymentType";
import { HorizontalFlex } from "@/components/layout/Flex";
import {
  EmploymentTypeEnum,
  QuestionnairePropsEmployer,
} from "@/models/payroll";

export function HandleEmploymentDates({
  form,
  selectedEmployee,
}: QuestionnairePropsEmployer) {
  const { employmentType } = useEmploymentType(selectedEmployee!);

  return (
    <HorizontalFlex>
      {employmentType === EmploymentTypeEnum.TRAINEE ? (
        <HorizontalFlex className="grid grid-cols-2 gap-4 w-full">
          <DateInputField
            control={form.control}
            name={"employmentDetails.trainingEnd"}
            labelKey={questionnaireTranslation("employmentCard.trainingStart")}
            required={true}
          />

          <DateInputField
            control={form.control}
            name={"employmentDetails.trainingEnd"}
            labelKey={questionnaireTranslation("employmentCard.trainingEnd")}
          />
        </HorizontalFlex>
      ) : (
        <HorizontalFlex className={"grid grid-cols-2 gap-4 w-full"}>
          <DateInputField
            control={form.control}
            name={"employmentDetails.entryDate"}
            labelKey={questionnaireTranslation("employmentCard.entryDate")}
            required={true}
          />
          <DateInputField
            control={form.control}
            name={"employmentDetails.firstEntryDate"}
            labelKey={questionnaireTranslation("employmentCard.firstEntryDate")}
          />
        </HorizontalFlex>
      )}
    </HorizontalFlex>
  );
}
