import React, { useEffect, useState } from "react";
import {
  payrollTranslation,
  questionnaireTranslation,
} from "@/feature/payroll/components/TranslationConstants";
import RadioFormField from "@/feature/payroll/components/formFields/RadioFormField";
import SelectFormField from "@/feature/payroll/components/formFields/SelectFormField";
import { GenericComboBox } from "@/components/form/GenericComboBox";
import privateHealthInsurance from "@/feature/payroll/privateHealthInsurance.json";
import statutoryHealthInsurance from "@/feature/payroll/statutoryHealthInsurance.json";
import { useWatch } from "react-hook-form";
import { useParams } from "react-router";
import { useGetEmployeeQuestionnaireQuery } from "@/api/endpoints/payrollApi";
import { useEmploymentType } from "@/feature/payroll/forms/questionnaire/hooks/UseEmploymentType";
import { Title } from "@/components/text/Title";
import { Underline } from "@/components/text/Underline";
import { HorizontalFlex, VerticalFlex } from "@/components/layout/Flex";
import {
  EmploymentTypeEnum,
  QuestionnairePropsEmployee,
  QuestionnairePropsEmployer,
  SelectEnum,
} from "@/models/payroll";

// employee social security information
export function SocialSecurityEmployee({ form }: QuestionnairePropsEmployee) {
  const [employmentType, setEmploymentType] = useState<string>("");
  const hasChildren = form.watch("additionalInfos.hasChildren");
  //get employeeToken from url
  const { token } = useParams<{ token: string }>();

  // get employee data from api
  const { data: employeeData } = useGetEmployeeQuestionnaireQuery(
    { employeeToken: token || "" },
    { skip: !token },
  );

  // set employmentType
  useEffect(() => {
    if (employeeData) {
      setEmploymentType(employeeData?.sendQuestionnaireEvent?.employmentType);
    }
  }, [employeeData]);

  // get form control and set value
  const { control, setValue } = form;

  //watch the current health insurance
  const currentHealthInsurance = useWatch({
    control,
    name: "socialSecurity.currentHealthInsurance",
  });

  // prepare insurance options by combining statutory and private insurance data
  const healthInsuranceOptions = [
    ...(statutoryHealthInsurance.statutory || []).map((insurance) => ({
      label: insurance.label,
      value: insurance.value,
    })),
    ...(privateHealthInsurance.private || []).map((insurance) => ({
      label: insurance.label,
      value: insurance.value,
    })),
  ];

  // determine the type of insurance selected
  const checkInsuranceType = (selectedInsurance: string) => {
    const isPrivate = privateHealthInsurance.private.some(
      (insurance) => insurance.value === selectedInsurance,
    );
    const isStatutory = statutoryHealthInsurance.statutory.some(
      (insurance) => insurance.value === selectedInsurance,
    );

    if (isPrivate) {
      return "private";
    } else if (isStatutory) {
      return "statutory";
    } else {
      return "unknown";
    }
  };

  // get insurance type based on current selection
  const insuranceType = currentHealthInsurance
    ? checkInsuranceType(currentHealthInsurance)
    : "unknown";

  // set value of current health insurance when it changes
  useEffect(() => {
    if (currentHealthInsurance) {
      setValue("socialSecurity.currentHealthInsurance", currentHealthInsurance);
    }
  }, [currentHealthInsurance, setValue]);

  return (
    <VerticalFlex>
      <Underline>
        <Title level={4}>
          {questionnaireTranslation("socialSecurityCard.securityData")}
        </Title>
      </Underline>

      <HorizontalFlex className="grid gap-4 md:grid-cols-2 lg:grid-cols-4">
        <GenericComboBox
          className={"overflow-y-hidden"}
          form={form}
          name="socialSecurity.currentHealthInsurance"
          label={questionnaireTranslation(
            "socialSecurityCard.currentHealthInsurance",
          )}
          options={healthInsuranceOptions}
        />

        {insuranceType === "private" && (
          <GenericComboBox
            form={form}
            name="socialSecurity.statutoryHealthInsurance"
            label={questionnaireTranslation(
              "socialSecurityCard.statutoryHealthInsurance",
            )}
            options={(statutoryHealthInsurance.statutory || []).map(
              (insurance) => ({
                label: insurance.label,
                value: insurance.value,
              }),
            )}
          />
        )}

        {hasChildren === SelectEnum.YES && (
          <RadioFormField
            hoverInfoTitle={questionnaireTranslation(
              "socialSecurityCard.parentProperty",
            )}
            hoverInfoDescription={questionnaireTranslation(
              "socialSecurityCard.infoCard.infoParentProperty",
            )}
            name="socialSecurity.parentProperty"
            control={form.control}
            labelKey={questionnaireTranslation(
              "socialSecurityCard.parentProperty",
            )}
            options={[
              { label: payrollTranslation("universal.true"), value: "yes" },
              { label: payrollTranslation("universal.false"), value: "no" },
            ]}
          />
        )}

        {employmentType === EmploymentTypeEnum.MINIJOB && (
          <RadioFormField
            hoverInfoTitle={questionnaireTranslation(
              "socialSecurityCard.miniJobberSecurity.freePensionInsurance",
            )}
            hoverInfoDescription={questionnaireTranslation(
              "socialSecurityCard.infoCard.infoFreePensionInsurance",
            )}
            name={"socialSecurity.miniJobberSecurity.freePensionInsurance"}
            control={form.control}
            labelKey={questionnaireTranslation(
              "socialSecurityCard.miniJobberSecurity.freePensionInsurance",
            )}
            options={[
              { label: payrollTranslation("universal.true"), value: "yes" },
              {
                label: payrollTranslation("universal.false"),
                value: "no",
              },
            ]}
          />
        )}
      </HorizontalFlex>
    </VerticalFlex>
  );
}

// employer social security information
export function SocialSecurityEmployer({
  form,
  selectedEmployee,
}: QuestionnairePropsEmployer) {
  // Get employment type for the employer
  const { employmentType } = useEmploymentType(selectedEmployee!);

  return (
    <VerticalFlex>
      <Underline>
        <Title level={4}>
          {questionnaireTranslation("socialSecurityCard.securityData")}
        </Title>
      </Underline>

      <HorizontalFlex className="grid grid-cols-2 gap-4">
        <SelectFormField
          hoverInfoTitle={questionnaireTranslation(
            "socialSecurityCard.insuranceKey",
          )}
          hoverInfoDescription={questionnaireTranslation(
            "socialSecurityCard.infoCard.infoInsuranceKey",
          )}
          labelKey={questionnaireTranslation("socialSecurityCard.insuranceKey")}
          name={"socialSecurity.insuranceKey"}
          defaultValue={
            employmentType === EmploymentTypeEnum.TRAINEE
              ? "1111_trainee"
              : "1111_employee"
          }
          control={form.control}
          options={[
            {
              label: "Kurzfristige Beschäftigung",
              value: "0000_shortTermJob",
            },
            {
              label: "Studentisches Zwischenpraktikum",
              value: "0000_internship",
            },
            {
              label: "Versicherungspflichtiger Arbeitnehmer",
              value: "1111_employee",
            },
            { label: "Auszubildender", value: "1111_trainee" },
            {
              label:
                "Arbeitnehmer freiwillig gesetzlich versichert - Selbstzahler",
              value: "0111",
            },
            {
              label:
                "Arbeitnehmer freiwillig gesetzlich versichert - Firmenzahler",
              value: "9111",
            },
            { label: "Arbeitnehmer privat versichert", value: "0110" },
            { label: "Schüler", value: "1101" },
            { label: "Werkstudent", value: "0100" },
            {
              label: "SV-pflichtige mit berufsständischer Versorgung",
              value: "1011",
            },
            { label: "Geringfügig entlohnte Beschäftigung", value: "6500" },
            {
              label:
                "Geringfügig entlohnte Beschäftigung mit Rentenversicherungspflicht",
              value: "6100",
            },
            {
              label: "Vollrente vor Erreichen der Regelaltersgrenze",
              value: "3111",
            },
            {
              label: "Vollrente nach Vollendung der Regelaltersgrenze",
              value: "3321",
            },
            {
              label:
                "Vollrente nach Vollendung der Regelaltersgrenze ohne Versicherungsfreiheit in der Rentenversicherung",
              value: "3121",
            },
          ]}
        />

        <SelectFormField
          hoverInfoTitle={questionnaireTranslation("socialSecurityCard.status")}
          hoverInfoDescription={questionnaireTranslation(
            "socialSecurityCard.infoCard.infoDeuevStatus",
          )}
          control={form.control}
          defaultValue="0"
          name="socialSecurity.deuevStatus"
          labelKey={questionnaireTranslation("socialSecurityCard.status")}
          options={[
            {
              label: questionnaireTranslation(
                "socialSecurityCard.statusValues.0",
              ),
              value: "0",
            },
            {
              label: questionnaireTranslation(
                "socialSecurityCard.statusValues.1",
              ),
              value: "1",
            },
            {
              label: questionnaireTranslation(
                "socialSecurityCard.statusValues.2",
              ),
              value: "2",
            },
          ]}
        />
      </HorizontalFlex>
    </VerticalFlex>
  );
}
