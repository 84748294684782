import * as React from "react";
import { useEffect, useState } from "react";
import { useGetListEmployeesQuery } from "@/api/endpoints/payrollApi";
import { Employee } from "@/feature/payroll/forms/questionnaire/schema/employee/EmployeeSchema";
import {
  DataTable,
  DataTableColumn,
  Sort,
} from "@/components/display/DataTable";
import {
  DataTableMultiselect,
  SelectGroup,
} from "@/components/display/DataTableMultiselect";
import {
  DataTablePagination,
  DataTablePaginationElement,
} from "@/components/display/DataTablePagination";
import EmployeeCard from "@/feature/payroll/employeeOverview/employeeCard/EmployeeCard";
import { Filter, FilterEq, FilterInner, FilterParameters } from "@/api/types";
import { HorizontalFlex, VerticalFlex } from "@/components/layout/Flex";
import { cn } from "@/lib/utils";
import { Path } from "@/util/DeepProps";
import { StatusChange } from "@/feature/payroll/forms/questionnaire/components/StatusChange";
import { payrollTranslation } from "@/feature/payroll/components/TranslationConstants";

const EmployeeOverview: React.FC = () => {
  const [selectedEmployee, setSelectedEmployee] =
    React.useState<Employee | null>(null);
  const [sort, setSort] = useState<Sort<Path<Employee>>>(null);
  const [filter, setFilter] = useState<FilterInner<Employee>>({});
  const [selectedEmployeeId, setSelectedEmployeeId] = useState<
    string | undefined
  >();
  const [employees, setEmployees] = useState<Employee[]>();
  const [showDrawer, setShowDrawer] = useState<boolean>(false);

  const [filterParams, setFilterParams] =
    useState<FilterParameters<Employee>>();
  const paginationRef = React.createRef<DataTablePaginationElement<Employee>>();

  //filters Employees and sorts them by last name descending
  const filterQuery: Filter<Employee> = {
    sort: sort ? sort[0] : "lastName",
    direction: sort ? sort[1] : "asc",
    ...filterParams,
    ...filter,
  };

  //api request to get list of employees based on filterQuery
  const { data: employeeData } = useGetListEmployeesQuery(filterQuery);

  //mapping employeeData items to create new array of employee objects
  useEffect(() => {
    if (employeeData) {
      setEmployees(
        employeeData.items?.map((employee) => ({
          ...employee,
          id: employee.id || "",
        })) || [],
      );
    }
  }, [employeeData]);

  //defines the columns of the table
  const employeeColumns: DataTableColumn<Employee>[] = [
    {
      header: (
        <HorizontalFlex gap={4} align={"center"}>
          {payrollTranslation("universal.firstName")}
        </HorizontalFlex>
      ),
      sortKey: "firstName",
      cell: (item) => (
        <HorizontalFlex gap={4} align={"center"} className={"text-nowrap"}>
          {item.firstName}
        </HorizontalFlex>
      ),
    },
    {
      header: (
        <HorizontalFlex gap={4} align={"center"}>
          {payrollTranslation("universal.lastName")}
        </HorizontalFlex>
      ),
      sortKey: "lastName",
      cell: (item) => (
        <VerticalFlex gap={0}>
          <span className={"font-semibold"}>{item.lastName}</span>
        </VerticalFlex>
      ),
    },
    {
      header: (
        <HorizontalFlex gap={4} align={"center"}>
          {payrollTranslation("universal.email")}
        </HorizontalFlex>
      ),
      sortKey: "email",
      cell: (item) => (
        <HorizontalFlex justify={"between"}>
          <span>{item.email}</span>
        </HorizontalFlex>
      ),
    },
    {
      header: (
        <HorizontalFlex gap={4} align={"center"}>
          {payrollTranslation("universal.personnelNumber")}
        </HorizontalFlex>
      ),
      sortKey: "personnelNumber",
      cell: (item) => (
        <HorizontalFlex justify={"between"}>
          <span>{item.personnelNumber}</span>
        </HorizontalFlex>
      ),
    },
    {
      header: (
        <HorizontalFlex gap={4} align={"center"}>
          {payrollTranslation("statusDisplay.status")}
        </HorizontalFlex>
      ),
      cell: (item) => (
        <HorizontalFlex justify={"between"}>
          <span className={cn("status-indicator")}>
            <StatusChange events={item.events} />
          </span>
        </HorizontalFlex>
      ),
    },
  ];

  //defines filter categories
  const selectGroups: SelectGroup<Employee>[] = [
    {
      key: "firstName",
      label: "Vorname",
      values: (employees || []).map((e) => ({
        label: e.firstName!,
        value: e.firstName!,
        filter: FilterEq("firstName", e.firstName!),
      })),
    },
    {
      key: "lastName",
      label: "Nachname",
      values: (employees || []).map((e) => ({
        label: e.lastName!,
        value: e.lastName!,
        filter: FilterEq("lastName", e.lastName!),
      })),
    },
    {
      key: "email",
      label: "E-Mail",
      values: (employees || []).map((e) => ({
        label: e.email!,
        value: e.email!,
        filter: FilterEq("email", e.email!),
      })),
    },
    {
      key: "status",
      label: "Status",
      values: [
        {
          label: "Daten abrufbar",
          value: "status_filled",
          filter: FilterEq("status", "filled"),
        },
        {
          label: "Email geschickt",
          value: "status_email_sent",
          filter: FilterEq("status", "email_sent"),
        },
        {
          label: "Vollständig",
          value: "status_complete",
          filter: FilterEq("status", "complete"),
        },
        {
          label: "Offen",
          value: "status_open",
          filter: FilterEq("status", "open"),
        },
      ],
    },
  ];

  useEffect(() => {
    if (employeeData && selectedEmployeeId) {
      const updatedSelectedEmployee = employeeData.items?.find(
        (e) => e.id === selectedEmployeeId,
      );
      if (updatedSelectedEmployee) {
        setSelectedEmployee(updatedSelectedEmployee);
      } else {
        setSelectedEmployee(null);
      }
    }
  }, [employeeData, selectedEmployeeId]);

  const handleEmployeeSelect = (employee: Employee | null) => {
    setSelectedEmployee(employee);
    setSelectedEmployeeId(employee ? employee.id : undefined);
    setShowDrawer(!!employee);
  };

  const handleCloseCard = () => {
    handleEmployeeSelect(null);
  };

  return (
    <VerticalFlex>
      <VerticalFlex className="w-[65%]">
        {/* filter for data table */}
        <DataTableMultiselect<Employee>
          textSearchKeys={["firstName", "lastName", "email", "personnelNumber"]}
          selectGroups={selectGroups}
          placeholder={"Filtern"}
          onFilterChange={setFilter}
        />
      </VerticalFlex>

      <HorizontalFlex>
        <VerticalFlex
          className={`transition-all ${selectedEmployee ? "w-[65%]" : "w-full"}`}
        >
          {/* data table with employees */}
          <DataTable<Employee>
            onSortChange={setSort}
            columns={employeeColumns}
            onClick={(t) => {
              const employee =
                selectedEmployeeId === t.id
                  ? null
                  : employees?.find((e) => e.id === t.id);
              handleEmployeeSelect(employee!);
            }}
            data={employeeData?.items ?? []}
            selected={
              showDrawer && selectedEmployeeId ? [selectedEmployeeId] : []
            }
          />

          <VerticalFlex>
            {/* pagination for data table */}
            <DataTablePagination
              ref={paginationRef}
              onChange={setFilterParams}
              defaultPageSize={10}
              result={employeeData}
            />
          </VerticalFlex>
        </VerticalFlex>

        {/* shows card with employee information when employee is selected */}
        {selectedEmployee && (
          <VerticalFlex
            className={"duration-600 w-[35%] transition-all ease-in-out"}
          >
            <EmployeeCard
              selectedEmployee={selectedEmployee}
              onClose={handleCloseCard}
            />
          </VerticalFlex>
        )}
      </HorizontalFlex>
    </VerticalFlex>
  );
};

export default EmployeeOverview;
