import { z } from "zod";
import { errorRequired } from "@/feature/payroll/forms/questionnaire/schema/employee/FormSchemaEmployee";

export const FormSchemaEmployer = z.object({
  employmentDetails: z.object({
    entryDate: z.string().min(1, {
      message: errorRequired,
    }),
    firstEntryDate: z.string().optional().nullable(),
    trainingStart: z.string().optional().nullable(),
    trainingEnd: z.string().optional().nullable(),

    employmentCompany: z.string().optional().nullable(),

    jobActivity: z.string().optional().nullable(),
    probation: z.string().optional().nullable(),
    probationDuration: z.number().optional().nullable(),
    weeklyWorkHours: z.coerce.number().optional().nullable(),
    vacationEntitlement: z.coerce.number().optional().nullable(),
    contractForm: z.string().optional().nullable(),
    hourDistribution: z.object({
      monday: z.coerce.number().optional().nullable(),
      tuesday: z.coerce.number().optional().nullable(),
      wednesday: z.coerce.number().optional().nullable(),
      thursday: z.coerce.number().optional().nullable(),
      friday: z.coerce.number().optional().nullable(),
      saturday: z.coerce.number().optional().nullable(),
      sunday: z.coerce.number().optional().nullable(),
    }),
    costCenter: z.string().optional().nullable(),
    departmentNumber: z.string().optional().nullable(),
    peopleGroup: z.string().optional().nullable(),
  }),

  contractLimitation: z.object({
    limitType: z.string().optional().nullable(),
    contractEndDate: z.string().optional().nullable(),
    contractSignedDate: z.string().optional().nullable(),
  }),

  taxInformation: z.object({
    miniJobberTax: z.object({
      rollOff: z.string().optional().nullable(),
      generalize: z.string().optional().nullable(),
    }),
  }),

  payment: z.object({
    additionalPayments: z.array(
      z.object({
        additionalPaymentName: z.string().optional().nullable(),
        additionalPaymentSum: z.coerce
          .number()
          .optional()
          .nullable()
          .nullable(),
      }),
    ),
    christmasMoney: z.coerce.number().optional().nullable().nullable(),
    payoutMonthChristmas: z.string().optional().nullable().nullable(),
    vacationMoney: z.coerce.number().optional().nullable().nullable(),
    payoutMonthVacation: z.string().optional().nullable().nullable(),
    description: z.string().optional().nullable(),
    sum: z.coerce.number().optional().nullable().nullable(),
    dateSum: z.string().optional().nullable().nullable(),
    dateWage: z.string().optional().nullable().nullable(),
    hourlyWage: z.coerce.number().optional().nullable(),
    traineePayment: z.object({
      yearOne: z.object({
        sum: z.coerce.number().optional().nullable(),
        dateSum: z.string().optional().nullable(),
        hourlyWage: z.coerce.number().optional().nullable(),
        dateWage: z.string().optional().nullable(),
      }),
      yearTwo: z.object({
        sum: z.coerce.number().optional().nullable(),
        dateSum: z.string().optional().nullable(),
        hourlyWage: z.coerce.number().optional().nullable(),
        dateWage: z.string().optional().nullable(),
      }),
      yearThree: z.object({
        sum: z.coerce.number().optional().nullable(),
        dateSum: z.string().optional().nullable(),
        hourlyWage: z.coerce.number().optional().nullable(),
        dateWage: z.string().optional().nullable(),
      }),
    }),
  }),

  socialSecurity: z.object({
    insuranceKey: z.string().optional().nullable(),
    deuevStatus: z.string().optional().nullable(),
  }),

  vwlDetails: z.array(
    z.object({
      receptionThrough: z.string().optional().nullable(),
      sumBenefits: z.coerce.number().optional().nullable(),
      dateSince: z.string().optional().nullable(),
      contractNumber: z.string().optional().nullable(),
      iban: z.string().optional().nullable(),
      bic: z.string().optional().nullable(),
      employerShare: z.coerce.number().optional().nullable(),
    }),
  ),
});

export type QuestionnaireEmployer = z.infer<typeof FormSchemaEmployer>;
