import React, { useRef, useState } from "react";
import EmployerForm from "@/feature/payroll/forms/questionnaire/EmployerForm";
import EmployeeData from "@/feature/payroll/forms/questionnaire/components/employeeData/EmployeeData";
import { EmployeeEvent } from "@/feature/payroll/forms/questionnaire/schema/employee/EmployeeSchema";
import { useNavigate } from "react-router-dom";
import { Card } from "@/components/ui/card";
import { AnimatePresence, motion } from "framer-motion";
import CancelButton from "@/feature/payroll/components/CancelButton";
import EmployeeDetail from "@/feature/payroll/forms/questionnaire/components/EmployeeDetails";
import { Button } from "@/components/ui/button";
import { questionnaireTranslation } from "@/feature/payroll/components/TranslationConstants";
import PrintForm from "@/feature/payroll/forms/questionnaire/components/printForm/PrintForm";
import Spinner from "@/components/loading/spinner";
import { HorizontalFlex, VerticalFlex } from "@/components/layout/Flex";
import { useEmploymentType } from "@/feature/payroll/forms/questionnaire/hooks/UseEmploymentType";
import { Title } from "@/components/text/Title";
import { usePrint } from "@/feature/payroll/forms/questionnaire/hooks/UsePrintManagement";
import { UserTypeEnum } from "@/models/payroll";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import useGetCurrentEmployee from "@/feature/payroll/hooks/UseGetCurrentEmployee";

export const EditQuestionnaire: React.FC = () => {
  //get current employee
  const { employee } = useGetCurrentEmployee();
  const employeeFillQuestionnaireEvent = employee?.events?.find(
    (event) => event?.employeeFillQuestionnaireEvent,
  );
  // check if employer questionnaire is existing
  const employerCompleteQuestionnaireEvent = employee?.events?.find(
    (event: EmployeeEvent) => event?.employerCompleteQuestionnaireEvent,
  );
  const { employmentType } = useEmploymentType(employee);
  const [currentTitle, setCurrentTitle] = useState<string>("");
  const navigate = useNavigate();
  const printRef = useRef<HTMLDivElement>(null);

  // navigate back to the payroll page
  const handleClose = () => {
    navigate("/payroll");
  };

  // handling print functionality
  const printFn = usePrint(printRef);

  return (
    <AnimatePresence mode="wait">
      <motion.div
        key="edit-questionnaire"
        initial={{ opacity: 0, x: "100%" }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: "100%" }}
        transition={{ duration: 0.5, ease: "easeInOut" }}
      >
        {/* show print button when employee questionnaire is existing */}
        {employeeFillQuestionnaireEvent && (
          <HorizontalFlex className={"flex items-end justify-end"}>
            <HorizontalFlex>
              <div ref={printRef} className={"hidden"}>
                <PrintForm selectedEmployee={employee} />
              </div>
            </HorizontalFlex>

            <HorizontalFlex>
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger>
                    {/* Print button, disabled as long as employerComplete isn't done */}
                    <Button
                      disabled={!employerCompleteQuestionnaireEvent}
                      type={"button"}
                      onClick={() => printFn()}
                      className="relative"
                    >
                      {questionnaireTranslation("pdf.printPdf")}
                    </Button>
                  </TooltipTrigger>

                  {!employerCompleteQuestionnaireEvent ? (
                    <TooltipContent>
                      <p>{questionnaireTranslation("pdf.infoMessage")}</p>
                    </TooltipContent>
                  ) : null}
                </Tooltip>
              </TooltipProvider>
            </HorizontalFlex>
            <VerticalFlex className={"flex items-start justify-start"}>
              <CancelButton onCancelConfirm={handleClose} />
            </VerticalFlex>
          </HorizontalFlex>
        )}
        <Card className={"mt-4 px-8 py-4"}>
          {/* employee information */}
          <EmployeeDetail selectedEmployee={employee} editMode={true} />
        </Card>

        {employmentType ? (
          <HorizontalFlex className={"grid grid-cols-2"}>
            <Card className="mt-4 h-auto w-full pl-8 pr-8 pt-8">
              {/* employer questionnaire */}
              <EmployerForm
                userType={UserTypeEnum.EMPLOYER}
                selectedEmployee={employee}
                setCurrentTitle={setCurrentTitle}
              />
            </Card>
            <Card className="relative mt-4 h-auto w-full pl-8 pr-8 pt-8">
              {/* employee data corresponding to current employer form section */}
              <EmployeeData
                selectedEmployee={employee}
                currentTitle={currentTitle}
              />
            </Card>
          </HorizontalFlex>
        ) : (
          <Card className="mt-4 h-fit w-full p-8">
            <VerticalFlex
              className={
                "col-span-2 flex items-center justify-center space-y-4 text-center"
              }
            >
              <Title level={2}>
                {questionnaireTranslation("noQuestionnaire")}
              </Title>
              <Spinner size={50} />
            </VerticalFlex>
          </Card>
        )}
      </motion.div>
    </AnimatePresence>
  );
};
