import React, { useEffect, useState } from "react";
import { cn } from "@/lib/utils";
import { VerticalFlex } from "@/components/layout/Flex";
import {
  Employee,
  EmployeeEvent,
} from "@/feature/payroll/forms/questionnaire/schema/employee/EmployeeSchema";
import { useGetListEmployeesQuery } from "@/api/endpoints/payrollApi";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { ManagementSchema } from "@/feature/payroll/salaryManagement/ManagementSchema";
import { z } from "zod";
import { Form } from "@/components/ui/form";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormSimpleMoney } from "@/components/form/FormSimpleMoney";
import { FormSimpleNumber } from "@/components/form/FormSimpleNumber";

const SalaryManagement: React.FC = () => {
  const [employees, setEmployees] = useState<Employee[]>();
  const form = useForm<z.infer<typeof ManagementSchema>>({
    resolver: zodResolver(ManagementSchema),
    mode: "onChange",
    defaultValues: { employees: [] }, // Initialisiere `employees`
  });

  // API request to get list of employees
  const { data: employeeData } = useGetListEmployeesQuery({});

  // Helper function to get the latestEmployerCompleteEvent
  const getLatestEmployerCompleteEvent = (
    events: EmployeeEvent[] | undefined,
  ) => {
    return (
      events
        ?.filter((event) => event.employerCompleteQuestionnaireEvent)
        .sort(
          (a, b) =>
            new Date(b.createdAt || 0).getTime() -
            new Date(a.createdAt || 0).getTime(),
        )[0]?.employerCompleteQuestionnaireEvent || null
    );
  };

  // Mapping employeeData items to create a new array of employee objects
  useEffect(() => {
    if (employeeData) {
      const newEmployees =
        employeeData.items?.map((employee) => ({
          ...employee,
          id: employee.id || "",
        })) || [];

      setEmployees(newEmployees);

      // Set default form values
      const defaultFormValues = newEmployees.map((employee) => {
        const latestEvent = getLatestEmployerCompleteEvent(employee.events);
        return {
          firstName: employee.firstName,
          lastName: employee.lastName,
          hourlyWage: latestEvent?.payment?.hourlyWage,
          weeklyWorkHours: latestEvent?.employmentDetails?.weeklyWorkHours || 0,
          totalWorkedHours: 0,
          totalWage: 0
        };
      });

      form.reset({ employees: defaultFormValues });
    }
  }, [employeeData, form]);

  return (
    <VerticalFlex>
      <Form {...form}>
        <form>
          {employees && employees.length > 0 ? (
            <Table className="table-fixed">
              <TableCaption>Gehaltsmanagement</TableCaption>
              <TableHeader>
                <TableRow>
                  <TableHead>Nachname</TableHead>
                  <TableHead>Vorname</TableHead>
                  <TableHead>Personalnummer</TableHead>
                  <TableHead>Stundenlohn</TableHead>
                  <TableHead>Wöchentl. Arbeitszeit</TableHead>
                  <TableHead>Gesamte Stunden</TableHead>
                  <TableHead>Gesamter Lohn</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {employees.map((employee, index) => (
                  <TableRow key={employee.id}>
                    <TableCell className="font-medium">
                      {employee.lastName}
                    </TableCell>
                    <TableCell className="font-medium">
                      {employee.firstName}
                    </TableCell>
                    <TableCell className="font-medium">
                      {employee.personnelNumber}
                    </TableCell>
                    <TableCell>
                      <FormSimpleMoney
                        className={"w-[100px]"}
                        mode={"euro"}
                        form={form}
                        name={`employees.${index}.hourlyWage`}
                      />
                    </TableCell>
                    <TableCell>
                      <FormSimpleNumber
                        className={"w-[100px]"}
                        form={form}
                        name={`employees.${index}.weeklyWorkHours`}
                      />
                    </TableCell>
                    <TableCell>
                      <FormSimpleNumber
                        className={"w-[100px]"}
                        form={form}
                        name={`employees.${index}.totalWorkedHours`}
                      />
                    </TableCell>
                    <TableCell>
                      {" "}
                      <FormSimpleMoney
                        className={"w-[100px]"}
                        mode={"euro"}
                        form={form}
                        name={`employees.${index}.totalWage`}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <p>No employees found.</p>
          )}
        </form>
      </Form>
    </VerticalFlex>
  );
};

export default SalaryManagement;
