import { z } from "zod";
import { alertMessage } from "@/feature/payroll/components/TranslationConstants";

export const FormSchemaAbsence = z.object({
  generalDetails: z.object({
    personnelNumber: z
      .string()
      .min(1, {
        message: alertMessage,
      })
      .nullable(),
    companyName: z.string().optional().nullable(),
    companyAddress: z.string().optional().nullable(),
    firstName: z
      .string()
      .min(1, {
        message: alertMessage,
      })
      .nullable(),
    lastName: z
      .string()
      .min(1, {
        message: alertMessage,
      })
      .nullable(),
  }),

  sickness: z.object({
    absenceType: z.string().optional().nullable(),
    lastDay: z.string().optional().nullable(),
    reasonSickness: z.string().optional().nullable(),
    onInjuryThird: z.string().optional().nullable(),
    onFirstDay: z.string().optional().nullable(),
  }),

  childCare: z.object({
    typeChildCare: z.string().optional().nullable(),
    childFirstName: z.string().optional().nullable(),
    childLastName: z.string().optional().nullable(),
    childBirthDate: z.string().optional().nullable(),
    childOnFirstDay: z.string().optional().nullable(),
    childAccident: z.string().optional().nullable(),
  }),

  maternity: z.object({
    probableDueDate: z.string().optional().nullable(),
    realDueDate: z.string().optional().nullable(),
    typeBirth: z.string().optional().nullable(),
    childFirstName: z.string().optional().nullable(),
    paymentBefore: z.array(
      z.object({
        gross: z.string().optional().nullable(),
        net: z.string().optional().nullable(),
        monthYear: z.string().optional().nullable(),
      }),
    ),
    additionalEmployment: z.string().optional().nullable(),
    employmentBan: z.string().optional().nullable(),
    onEmploymentBan: z.string().optional().nullable(),
  }),

  additionalAbsence: z.object({
    additionAbsenceType: z.string().optional().nullable(),
  }),
});

export type AbsenceSchema = z.infer<typeof FormSchemaAbsence>