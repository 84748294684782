import { FC, useEffect, useState } from "react";
import { HorizontalFlex, VerticalFlex } from "@/components/layout/Flex";
import { Separator } from "@/components/ui/separator";
import { Text } from "@/components/text/Text";
import { cn } from "@/lib/utils";

export interface BiTabsProps {
  tabs: BiTabItem[];
  defaultValue?: string;
  forceTabs?: boolean;
  onChange?: (value: string) => void;
}

export interface BiTabItem {
  value?: string;
  label?: React.ReactNode;
  content?: React.ReactNode;
}

export const BiTabs: FC<BiTabsProps> = ({
  defaultValue,
  tabs,
  forceTabs,
  onChange,
}) => {
  const [value, setValue] = useState(defaultValue || tabs[0]?.value || "0");

  useEffect(() => {
    onChange?.(value);
  }, [value]);

  const activeTab = tabs.find((t, index) => (t.value || `${index}`) == value);

  return (
    <VerticalFlex className={"h-full w-full"} gap={1}>
      {(forceTabs || tabs.length > 1) && (
        <HorizontalFlex gap={0} className={"min-h-8 overflow-x-auto"}>
          {tabs.map((tab, index) => {
            return (
              <div
                className={cn(
                  "flex flex-1 cursor-pointer justify-center border-b-2 border-foreground pb-2 pl-2 pr-2 transition-all",
                  tab.value || `${index}` != value ? "opacity-50" : "",
                )}
                onClick={() => setValue(tab.value || `${index}`)}
                key={index}
              >
                <Text small>{tab.label}</Text>
              </div>
            );
          })}
        </HorizontalFlex>
      )}
      <Separator orientation={"horizontal"} />
      <div className={"flex-1"}>{activeTab?.content}</div>
    </VerticalFlex>
  );
};
