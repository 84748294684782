import React from "react";
import { Center } from "@/components/layout/Center";
import {
  payrollTranslation,
  questionnaireTranslation,
} from "@/feature/payroll/components/TranslationConstants";
import { VerticalFlex } from "@/components/layout/Flex";
import { Title } from "@/components/text/Title";

const CompletionPage = () => {
  return (
    <Center v h>
      <VerticalFlex>
        <Title>{payrollTranslation("universal.thankYou")}</Title>
        <Title level={3}>{questionnaireTranslation("closeTabSuccess")}</Title>
      </VerticalFlex>
    </Center>
  );
};

export default CompletionPage;
