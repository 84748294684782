import { useState } from "react";

// managing the step navigation
export const useStepManagement = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [totalSteps, setTotalSteps] = useState(0);

  // update current step
  const handleStepChange = (step: number) => {
    setCurrentStep(step);
  };

  // update total steps
  const handleTotalStepsChange = (steps: number) => {
    setTotalSteps(steps);
  };

  return {
    currentStep,
    totalSteps,
    handleStepChange,
    handleTotalStepsChange,
  };
};
