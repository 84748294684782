import React, { useEffect, useRef } from "react";
import {
  payrollTranslation,
  questionnaireTranslation,
} from "@/feature/payroll/components/TranslationConstants";
import { FormSimpleText } from "@/components/form/FormSimpleText";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { Form } from "@/components/ui/form";
import { Button } from "@/components/ui/button";
import { CreateEmployeeSchema } from "@/feature/payroll/forms/questionnaire/schema/employee/EmployeeSchema";
import { zodResolver } from "@hookform/resolvers/zod";
import { useCreateEmployeesMutation } from "@/api/endpoints/payrollApi";
import { AnimatePresence } from "framer-motion";
import { VerticalFlex } from "@/components/layout/Flex";

export interface CreateEmployeeProps {
  onClose: () => void;
}

export const CreateEmployee: React.FC<CreateEmployeeProps> = ({ onClose }) => {
  const firstNameRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (firstNameRef.current) {
      firstNameRef.current.focus();
    }
  }, []);

  // Create employee api call
  const [createEmployees] = useCreateEmployeesMutation();

  async function onSubmit(values: z.infer<typeof CreateEmployeeSchema>) {
    try {
      const result = await createEmployees(values).unwrap();
      console.log("Erstellt:", result);
      onClose();
    } catch (error) {
      console.error("Fehler beim Erstellen des Mitarbeiters:", error);
    }
  }

  const form = useForm({
    resolver: zodResolver(CreateEmployeeSchema),
  });

  return (
    <AnimatePresence mode="wait">
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <VerticalFlex>
            <FormSimpleText
              form={form}
              name="firstName"
              label={`${payrollTranslation("universal.firstName")}*`}
              type="text"
              autoFocus={true}
            />

            <FormSimpleText
              form={form}
              name="lastName"
              label={`${payrollTranslation("universal.lastName")}*`}
              type="text"
            />

            <FormSimpleText
              form={form}
              name="email"
              label={`${questionnaireTranslation("personalCard.email")}*`}
              type="email"
            />

            <FormSimpleText
              form={form}
              name="personnelNumber"
              label={`${payrollTranslation("universal.personnelNumber")}*`}
              type="text"
            />
            <VerticalFlex
              className={"col-span-2 flex w-full items-center justify-center"}
            >
              <Button
                className={"m-4 mx-auto w-[50%]"}
                type={"submit"}
                variant={"secondary"}
              >
                {questionnaireTranslation("employeeOverviewCard.saveEmployee")}
              </Button>
            </VerticalFlex>
          </VerticalFlex>
        </form>
      </Form>
    </AnimatePresence>
  );
};
