import { UseFormReturn } from "react-hook-form";
import { z } from "zod";
import {
  Baby,
  CircleEllipsis,
  Coins,
  HandHeart,
  History,
  Receipt,
  ShieldCheck,
  SquareMousePointer,
  User,
} from "lucide-react";
import React from "react";
import { PersonalInfo } from "@/feature/payroll/forms/questionnaire/steps/PersonalInfo";
import { Kids } from "@/feature/payroll/forms/questionnaire/steps/Kids";
import { EmploymentEmployee } from "@/feature/payroll/forms/questionnaire/steps/Employment";
import { TaxesEmployee } from "@/feature/payroll/forms/questionnaire/steps/Taxes";
import { SocialSecurityEmployee } from "@/feature/payroll/forms/questionnaire/steps/SocialSecurity";
import { BenefitsEmployee } from "@/feature/payroll/forms/questionnaire/steps/Benefits";
import { TaxableEmployment } from "@/feature/payroll/forms/questionnaire/steps/TaxableEmployment";
import { questionnaireTranslation } from "@/feature/payroll/components/TranslationConstants";

import { StartPageEmployee } from "@/feature/payroll/forms/questionnaire/steps/StartPageEmployee";
import { Employee } from "@/feature/payroll/forms/questionnaire/schema/employee/EmployeeSchema";
import { OverviewCard } from "@/feature/payroll/forms/questionnaire/steps/FormOverview";
import { FormSchemaEmployee } from "@/feature/payroll/forms/questionnaire/schema/employee/FormSchemaEmployee";
import { useEmployeeFilter } from "@/feature/payroll/forms/questionnaire/hooks/UseFilter";
import { EmploymentTypeEnum, Step } from "@/models/payroll";

//Steps for Employee with optional filter that removes steps for vwl, children and taxable employment if field "no" is checked
export const StepsQuestionnaireEmployee = (
  form: UseFormReturn<z.infer<typeof FormSchemaEmployee>>,
  employment: EmploymentTypeEnum,
  selectedEmployee: Employee,
): Step<typeof FormSchemaEmployee>[] => {
  // apply filters to steps based on employee data
  const { filter } = useEmployeeFilter();

  // array of questionnaire steps
  return [
    {
      icon: <CircleEllipsis className="h-5 w-5" />,
      title: questionnaireTranslation("preInformation"),
      component: <StartPageEmployee form={form} />,
      requiredFields: [],
    },
    {
      icon: <User className="h-5 w-5" />,
      title: questionnaireTranslation("personalCard.personalInfo"),
      component: (
        <PersonalInfo form={form} selectedEmployee={selectedEmployee} />
      ),
      requiredFields: [
        "personalDetails.birthDate",
        "personalDetails.iban",
        "personalDetails.address.street",
        "personalDetails.address.houseNumber",
        "personalDetails.address.city",
        "personalDetails.address.postalCode",
      ],
    },
    {
      icon: <Baby className="h-5 w-5" />,
      title: questionnaireTranslation("childCard.kids"),
      component: <Kids form={form} selectedEmployee={selectedEmployee} />,
      requiredFields: [],
      filter: filter({ hasChildren: "yes" }),
    },
    {
      icon: <SquareMousePointer className="h-5 w-5" />,
      title: questionnaireTranslation("employmentCard.occupation"),
      component: (
        <EmploymentEmployee form={form} selectedEmployee={selectedEmployee} />
      ),
      requiredFields: [],
    },
    {
      icon: <Receipt className="h-5 w-5" />,
      title: questionnaireTranslation("taxCard.taxes"),
      component: (
        <TaxesEmployee form={form} selectedEmployee={selectedEmployee} />
      ),
      requiredFields: [],
    },
    {
      icon: <HandHeart className="h-5 w-5" />,
      title: questionnaireTranslation("socialSecurityCard.socialSecurity"),
      component: (
        <SocialSecurityEmployee
          form={form}
          selectedEmployee={selectedEmployee}
        />
      ),
      requiredFields: [],
    },
    {
      icon: <Coins className="h-5 w-5" />,
      title: questionnaireTranslation("benefitsCard.benefits"),
      component: (
        <BenefitsEmployee form={form} selectedEmployee={selectedEmployee} />
      ),
      filter: filter({ hasVwl: "yes" }),
      requiredFields: [],
    },
    {
      icon: <History className="h-5 w-5" />,
      title: questionnaireTranslation("taxableCard.taxableEmployment"),
      component: (
        <TaxableEmployment
          form={form}
          employmentType={employment}
          selectedEmployee={selectedEmployee}
        />
      ),
      filter: filter({ hasTaxableEmployment: "yes" }),
      requiredFields: [],
    },
    {
      icon: <ShieldCheck className="h-5 w-5" />,
      title: "Überprüfen und fertigstellen",
      component: (
        <OverviewCard form={form} selectedEmployee={selectedEmployee} />
      ),
      requiredFields: [],
    },
  ];
};
