'use client';

import { Button } from '@/components/ui/button';
import { CheckIcon, CopyIcon } from '@radix-ui/react-icons';
import { useCopyToClipboard } from './hooks/useCopyToClipboard';
import { cn } from '@/lib/utils';

export function ChatMessageActions({ message, isUser, className, ...props }) {
  const { isCopied, copyToClipboard } = useCopyToClipboard({ timeout: 2000 });

  const onCopy = () => {
    if (isCopied) return;
    copyToClipboard(Array.isArray(message.content) ? message.content[0] : message.content);
  };

  return (
    <div
      className={cn(
        'flex text-neutral-400 items-center transition-opacity group-hover:opacity-100',
        isUser && 'md:opacity-0 mt-0 mb-0 -mr-2 justify-center', // Nur für den User
        className
      )}
      {...props}
    >
      <Button variant="ghost" size="icon" onClick={onCopy}>
        {isCopied ? <CheckIcon /> : <CopyIcon />}
        <span className="sr-only">Nachricht kopieren</span>
      </Button>
    </div>
  );
}
