import { useDispatch } from "react-redux";
import { X } from "lucide-react";

interface DocumentChipProps {
  invoiceNumber: string;
  onRemove?: () => void;
}

export function DocumentChip({ invoiceNumber, onRemove }: DocumentChipProps) {
  return (
    <div className="inline-flex items-center gap-2 rounded-lg border border-blue-500/30 bg-blue-600/20 px-3 py-1.5 text-sm text-blue-400">
      <span className="flex items-center gap-2">
        <span className="h-2 w-2 rounded-full bg-blue-500"></span>
        {invoiceNumber}
      </span>
      <button
        type="button"
        onClick={onRemove}
        className="text-blue-400/80 transition-colors hover:text-blue-300 focus:outline-none"
      >
        <X className="h-3.5 w-3.5" />
      </button>
    </div>
  );
}
