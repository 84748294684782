import {
  Employee,
  EmployeeEvent,
} from "@/feature/payroll/forms/questionnaire/schema/employee/EmployeeSchema";
import { useEffect, useState } from "react";
import { EmploymentTypeEnum } from "@/models/payroll";

// determine the employmentType of an employee
export const useEmploymentType = (
  selectedEmployee: Employee | null | undefined,
) => {
  const [employmentType, setEmploymentType] = useState<
    EmploymentTypeEnum | undefined
  >();

  // set employmentType based on employeeData
  useEffect(() => {
    // get sendQuestionnaireEvent from employee object
    const employeeData = selectedEmployee?.events?.find(
      (event: EmployeeEvent) => event?.sendQuestionnaireEvent,
    )?.sendQuestionnaireEvent;

    if (employeeData) {
      setEmploymentType(employeeData.employmentType);
    } else {
      console.error("Kein sendQuestionnaireEvent im Employee gefunden.");
    }
  }, [selectedEmployee]);

  if (employmentType === undefined) {
    console.error("Kein employmentType im sendQuestionnaireEvent gefunden.");
  }

  return { employmentType };
};
