import React, { useEffect, useState } from "react";
import {
  payrollTranslation,
  questionnaireTranslation,
} from "@/feature/payroll/components/TranslationConstants";
import RadioFormField from "@/feature/payroll/components/formFields/RadioFormField";
import { useGetEmployeeDataQuery } from "@/api/endpoints/payrollApi";
import { EmployeeEvent } from "@/feature/payroll/forms/questionnaire/schema/employee/EmployeeSchema";
import { HorizontalFlex } from "@/components/layout/Flex";
import { QuestionnairePropsEmployer, SelectEnum } from "@/models/payroll";

export function TaxesMiniJobber({
  form,
  selectedEmployee,
}: QuestionnairePropsEmployer) {
  const [freePension, setFreePension] = useState<string | null | undefined>("");

  // get employee data from api
  const { data } = useGetEmployeeDataQuery(
    { employeeId: selectedEmployee?.id || "" },
    { skip: !selectedEmployee?.id },
  );
  // process the fetched employee data
  useEffect(() => {
    if (data) {
      // extract the relevant employee data related to pension insurance
      const employeeData = data?.events?.find(
        (event: EmployeeEvent) => event?.employeeFillQuestionnaireEvent,
      )?.employeeFillQuestionnaireEvent;
      const miniJobberPensionInsurance =
        employeeData?.socialSecurity.miniJobberSecurity.freePensionInsurance;
      setFreePension(miniJobberPensionInsurance);
    }
  }, [data]);

  return (
    <HorizontalFlex className={"col-span-4 grid grid-cols-2 gap-2"}>
      <RadioFormField
        hoverInfoTitle={questionnaireTranslation("taxCard.generalize")}
        hoverInfoDescription={questionnaireTranslation(
          "taxCard.infoCard.infoGeneralize",
        )}
        control={form.control}
        name={"taxInformation.miniJobberTax.generalize"}
        labelKey={questionnaireTranslation("taxCard.generalize")}
        options={[
          { label: payrollTranslation("universal.yes"), value: "yes" },
          { label: payrollTranslation("universal.no"), value: "no" },
        ]}
      />

      {freePension === SelectEnum.NO && (
        <RadioFormField
          hoverInfoTitle={questionnaireTranslation("taxCard.rollOff")}
          hoverInfoDescription={questionnaireTranslation(
            "taxCard.infoCard.rollOff",
          )}
          control={form.control}
          name={"taxInformation.miniJobberTax.rollOf"}
          labelKey={questionnaireTranslation("taxCard.rollOff")}
          options={[
            { label: "2%", value: "2" },
            { label: "20%", value: "20" },
          ]}
        />
      )}
    </HorizontalFlex>
  );
}
