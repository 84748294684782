import { FC } from "react";
import { Booking } from "@/models/booking";
import { useTranslation } from "react-i18next";
import { formatMoney, formatSimpleDate } from "@/util/format";
import { simpleDateToDate } from "@/models/document";
import { BookCheck } from "lucide-react";
import { useLoggedIn } from "@/feature/auth/ProtectedRoutes";

export const BookingDisplay: FC<{ bookings?: Booking[] }> = (args) => {
  const { t } = useTranslation();
  const { activeCompany } = useLoggedIn();

  // filter for skr
  let bookings = (args.bookings ?? []).filter(
    (b) => b.accountingModel == activeCompany.accounting?.taxSystem,
  );

  if (!bookings || bookings.length === 0) return null;

  return (
    <div className="space-y-6">
      {bookings.map((booking, index) => (
        <div
          key={booking.id || index}
          className="flex flex-col space-y-4 rounded-lg border border-neutral-700 bg-transparent p-6 transition"
        >
          {/* Buchungsdatum */}
          <div className="text-sm text-neutral-400">
            <span className="text-neutral-500">
              {t("model.booking.bookingDate")}:
            </span>{" "}
            {formatSimpleDate(booking.bookingDate)}
          </div>

          {/* Kontonummer, Kategorie und Betrag */}
          <div className="flex items-start justify-between">
            {/* Linke Spalte: Kontonummer und Kategorie */}
            <div className="flex-1">
              <span className="text-sm text-neutral-500">
                {t("model.booking.account_many")}:{" "}
              </span>

              <div className="text-lg font-medium text-white">
                <span className="block">
                  {booking.account}/{booking.accountContra}
                </span>
              </div>
              {/* Kategorie direkt unter Kontonummer */}
              {booking.label && (
                <div className="mt-2 break-words text-sm text-neutral-400">
                  {booking.label}
                </div>
              )}
            </div>
            {/* Rechte Spalte: Betrag */}
            <div
              className={`flex items-center rounded px-4 py-2 text-sm font-thin ${
                parseFloat(booking.amount) < 0
                  ? "bg-muted text-red-500"
                  : "bg-muted text-green-500"
              }`}
            >
              <BookCheck className="mr-2 h-4 w-4" />
              {parseFloat(booking.amount) > 0 ? "S" : "H"}{" "}
              {formatMoney(Math.abs(+booking.amount * 100))}
            </div>
          </div>

          {/* Zusatzinfo und Leistungsdatum/-zeitraum */}
          <div className="grid grid-cols-2 gap-4 text-sm text-neutral-400">
            <div>
              <span className="block text-neutral-500">
                {t("model.booking.bookingFlags.self")}:
              </span>
              {t(`model.booking.bookingFlags.${booking.bookingFlags}`)}
            </div>
            <div>
              <BookingPeriode booking={booking} />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

const BookingPeriode: FC<{ booking: Booking }> = ({ booking }) => {
  const { t } = useTranslation();

  if (!booking.performanceFrom || !booking.performanceTo) {
    return null;
  }

  let from = simpleDateToDate(booking.performanceFrom).toLocaleDateString(
    "de-DE",
    {
      day: "numeric",
      month: "short",
      year: "numeric",
    },
  );

  let to = simpleDateToDate(booking.performanceTo).toLocaleDateString("de-DE", {
    day: "numeric",
    month: "short",
    year: "numeric",
  });

  if (from == to) {
    return (
      <>
        <span className="block text-neutral-500">
          {t("model.booking.performanceDate")}
        </span>
        <span>{from}</span>
      </>
    );
  } else {
    return (
      <>
        <span className="block text-neutral-500">
          {t("model.booking.performanceDate")}
        </span>
        <span>
          {from} - {to}
        </span>
      </>
    );
  }
};
