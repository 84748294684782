import React from "react";
import { useTranslation } from "react-i18next";
import { TimerIcon } from "@radix-ui/react-icons";
import NumberCard, {
  NumberCardProps,
} from "@/feature/dashboard/components/charts/number/NumberCard";
import { DateUnitType } from "@/feature/dashboard/components/charts/util/date";

export const RunwayNumberCard: React.FC<
  { unit: DateUnitType } & Partial<NumberCardProps>
> = ({ unit, ...props }) => {
  const { t } = useTranslation();

  const formatMonths = (totalAmount: number) => {
    if (isNaN(totalAmount)) return `\u2014`;
    if (totalAmount === 0) return `0 ${t("common.months.zero")}`;
    if (totalAmount === 1) return `1 ${t("common.months.one")}`;
    return `${(Math.round(totalAmount * 10) / 10).toFixed(1)} ${t("common.months.other")}`;
  };

  return (
    <NumberCard
      title={t("component.analytics.runway")}
      icon={<TimerIcon className="h-5 w-5 text-muted-foreground" />}
      tooltipContent={t("component.dashboard.kpi.runway")}
      subtext={"\u00A0"}
      formatter={formatMonths}
      {...props}
    />
  );
};
