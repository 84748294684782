export const defaultValuesTermination = {
  termination: {
    terminationOrAgreementDate: "",
    terminationDate: "",
    terminationBy: "",
    deliveryTermination: "",
    noticePeriod: "",
    noticePeriodReferenceDate: "",
    agreementExclusionDate: "",
    indefiniteExclusionOfTermination: "",
    terminationWithNotice: "",
    writtenTermination: "",
    operationalTermination: "",
    employeeFiledLawsuit: "",
    terminationForMisconduct: "",
    previousWarningDate: "",
  },

  additionalTerminationInfo: {
    additionalTerminationAgreements: "",
    socialSelection: "",
    socialSelectionExaminationBy: "",
    employerWouldHaveTerminated: "",
    irrevocablyPaidLeave: "",
    ordinaryTerminationAgainstCompensation: "",
    terminationForCauseConditionsMet: "",
  },

  paymentTermination: {
    paymentsOrEntitlementToBenefits: "",
    reasonForUncertainty: "",
  },
};
