import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { useEffect, useState } from "react";
import { Text } from "@/components/text/Text";

export interface BiSelectProps {
  placeholder?: string;
  options: BiSelectOptions[];
  value?: string;
  onChange?: (value?: string) => void;
}

export interface BiSelectOptions {
  label: React.ReactNode;
  description?: React.ReactNode;
  value: string;
}

export const BiSelect = ({
  placeholder,
  options,
  value,
  onChange,
}: BiSelectProps) => {
  const [internalValue, setInternalValue] = useState(value);
  useEffect(() => {
    setInternalValue(value);
  }, [value]);

  let selectedOption = options.find((option) => option.value === internalValue);

  return (
    <Select
      value={internalValue}
      onValueChange={(value) => {
        console.log(value);
        setInternalValue(value);
        onChange?.(value);
      }}
    >
      <SelectTrigger>
        {!selectedOption && placeholder && <Text muted>{placeholder}</Text>}
        {selectedOption && selectedOption.label}
      </SelectTrigger>
      <SelectContent>
        <SelectGroup
          className={
            "min-w-[var(--radix-select-trigger-width)] max-w-[var(--radix-select-trigger-width)]"
          }
        >
          {options.map((option, index) => (
            <SelectItem key={option.value} value={option.value}>
              {option.label}
              {option.description && (
                <Text muted small>
                  {" "}
                  <br />
                  {option.description}
                </Text>
              )}
            </SelectItem>
          ))}
        </SelectGroup>
      </SelectContent>
    </Select>
  );
};
