import {
  CaretDownIcon,
  CheckIcon,
  MagnifyingGlassIcon,
} from "@radix-ui/react-icons";
import { UseFormReturn } from "react-hook-form";

import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import HorizontalGradientLine from "@/components/layout/HorizontalGradientLine";
import { t } from "i18next";
import { FieldValues } from "react-hook-form/dist/types/fields";
import { FieldPath } from "react-hook-form/dist/types";
import { removeDuplicatesByKey } from "@/util/unique";
import * as React from "react";
import { FC, useMemo, useState } from "react";
import { VerticalFlex } from "@/components/layout/Flex";

export interface GenericComboBoxOption {
  group?: string;
  label: string;
  description?: string;
  shortcut?: string;
  disabled?: boolean;
  value: string;
}

export interface GenericComboBoxSearchItem {
  selected?: string;
  onSelect?: (string: string) => void;
  options: GenericComboBoxOption[];
  placeholder?: string;
}

export const GenericComboBox = <
  T extends FieldValues = FieldValues,
  TName extends FieldPath<T> = FieldPath<T>,
>({
  form,
  name,
  label,
  options,
  placeholder,
  className,
  width = "full",
  size = "lg",
  popoverContent = SearchComboBox,
}: {
  form: UseFormReturn<T>;
  name: TName;
  label?: string;
  options: GenericComboBoxOption[];
  placeholder?: string;
  className?: string;
  onSelect?: (selectedValue: string) => void;
  size?: "sm" | "md" | "lg";
  width?: "full" | "auto";
  popoverContent?: (args: GenericComboBoxSearchItem) => React.ReactNode;
}) => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <FormField
      control={form.control}
      name={name}
      render={({ field }) => (
        <FormItem className={className}>
          <div className="flex items-center">
            {label && <FormLabel className={"text-muted-foreground"}>{label}</FormLabel>}
          </div>

          <Popover open={open} onOpenChange={setOpen}>
            <PopoverTrigger asChild>
              <FormControl>
                <Button
                  variant="outline"
                  role="combobox"
                  className={cn(
                    `w-${width} justify-between bg-transparent`,
                    !field.value && "text-muted-foreground",
                  )}
                >
                  <span className={`w-18 truncate text-left`}>
                    {field.value
                      ? options.find((option) => option.value === field.value)
                          ?.label
                      : placeholder}
                  </span>
                  {size === "md" && (
                    <CaretDownIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                  )}
                </Button>
              </FormControl>
            </PopoverTrigger>
            <PopoverContent
              className={cn(
                "p-0",
                size === "md" && "w-64",
                size === "lg" && "w-[32rem]",
              )}
            >
              {popoverContent({
                selected: field.value,
                onSelect: (value: string) => {
                  form.setValue(name, value as any);
                  setOpen(false);
                },
                options: options,
                placeholder: placeholder,
              })}
            </PopoverContent>
          </Popover>
          <FormMessage />
        </FormItem>
      )}
    />
  );
};

export const CustomSearchBox = ({
  selected,
  options,
  onSelect,
  placeholder,
  onSearch,
}: GenericComboBoxSearchItem & {
  onSearch?: (text: string) => void;
}) => {
  return (
    <div className={"w-full"}>
      <div className="border-md flex items-center rounded-md border border-input px-3 focus-within:ring-1 focus-within:ring-ring">
        <MagnifyingGlassIcon className="mr-2 h-4 w-4 shrink-0 opacity-50" />
        <input
          placeholder={placeholder || t("common.search")}
          className="h-9 w-full bg-transparent px-3 py-1 text-sm transition-colors placeholder:text-muted-foreground focus-visible:outline-none"
          onChange={(e) => onSearch?.(e.target.value)}
        />
      </div>
      <VerticalFlex
        className={"mb-4 mt-4 max-h-[300px] overflow-y-auto overflow-x-hidden"}
      >
        {options.map((option, index) => {
          return (
            <div
              key={option.value}
              onClick={() => onSelect?.(option.value)}
              className={"mr-4 flex cursor-pointer border-t pt-2"}
            >
              <OptionDisplay
                key={option.value}
                selected={selected || ""}
                option={option}
              />
            </div>
          );
        })}
      </VerticalFlex>
    </div>
  );
};

const SearchComboBox = ({
  selected,
  options,
  onSelect,
  placeholder,
}: GenericComboBoxSearchItem) => {
  const groups = useMemo(() => {
    return removeDuplicatesByKey(
      options.map((o) => o.group || ""),
      (s) => s,
    );
  }, [options]);

  return (
    <Command>
      <CommandInput
        placeholder={placeholder || t("common.search")}
        className="h-9"
      />
      <CommandList>
        <CommandEmpty>{t("common.noDataResults")}</CommandEmpty>
        {groups.flatMap((g) => {
          const groupOptions = options.filter((o) => (o.group || "") === g);
          return (
            <CommandGroup
              key={g}
              heading={<span className={"text-lg text-foreground"}>{g}</span>}
            >
              {groupOptions.map((option, index) => (
                <GenericComboboxItem
                  key={option.value}
                  option={option}
                  index={index}
                  onSelect={onSelect}
                  selected={selected || ""}
                />
              ))}
            </CommandGroup>
          );
        })}
      </CommandList>
    </Command>
  );
};

const GenericComboboxItem = ({
  option,
  index,
  selected,
  onSelect,
}: {
  option: GenericComboBoxOption;
  index?: number;
  selected: string;
  onSelect?: (value: string) => void;
}) => {
  return (
    <CommandItem
      value={option.label}
      key={option.value}
      onSelect={() => {
        if (!option.disabled) onSelect?.(option.value);
      }}
      className={cn(
        (index || 0) % 2 === 0 && "bg-muted-foreground/5",
        "py-4",
        option.disabled ? "cursor-not-allowed opacity-50" : "cursor-pointer",
      )}
    >
      <OptionDisplay option={option} selected={selected} />
    </CommandItem>
  );
};

const OptionDisplay: FC<{
  option: GenericComboBoxOption;
  selected: string;
}> = ({ option, selected }) => {
  return (
    <>
      <HorizontalGradientLine />
      <span className={"ml-4 flex w-full flex-col text-left"}>
        {option.shortcut && (
          <span className={"ml-auto text-xs text-muted-foreground"}>
            {option.shortcut}
          </span>
        )}
        {option.label}
        {option.description && (
          <span className={"text-xs text-muted-foreground"}>
            {option.description}
          </span>
        )}
      </span>
      {!option.disabled && (
        <CheckIcon
          className={cn(
            "ml-auto h-6 w-6",
            selected === option.value ? "opacity-100" : "opacity-0",
          )}
        />
      )}
    </>
  );
};
