import { z } from "zod";

export const FormSchemaTermination = z.object({
  terminationInfo: z.object({
    terminationOrAgreementDate: z.string().optional().nullable(),
    terminationDate: z.string().optional().nullable(),
    terminationBy: z.string().optional().nullable(),
    deliveryTermination: z.string().optional().nullable(),
    noticePeriod: z.string().optional().nullable(),
    noticePeriodUnit: z.string().optional().nullable(),
    noticePeriodReferenceDate: z.string().optional().nullable(),
    agreementExclusionDate: z.string().optional().nullable(),
    indefiniteExclusionOfTermination: z.string().optional().nullable(),
    terminationWithNotice: z.string().optional().nullable(),
    writtenTermination: z.string().optional().nullable(),
    operationalTermination: z.string().optional().nullable(),
    employeeFiledLawsuit: z.string().optional().nullable(),
    terminationForMisconduct: z.string().optional().nullable(),
    previousWarningDate: z.string().optional().nullable(),
  }),

  additionalTerminationInfo: z.object({
    additionalTerminationAgreements: z.string().optional().nullable(),
    socialSelection: z.string().optional().nullable(),
    socialSelectionExaminationBy: z.string().optional().nullable(),
    employerWouldHaveTerminatedAt: z.string().optional().nullable(),
    employerWouldHaveTerminatedForThe: z.string().optional().nullable(),
    irrevocablyPaidLeave: z.string().optional().nullable(),
    ordinaryTerminationAgainstCompensation: z.string().optional().nullable(),
    terminationForCauseConditionsMet: z.string().optional().nullable(),
  }),

  paymentTermination: z.object({
    paymentsOrEntitlementToBenefits: z.string().optional().nullable(),
    reasonForUncertainty: z.string().optional().nullable(),
  }),
});


export type TerminationSchema = z.infer<typeof FormSchemaTermination>;