import React from "react";
import { questionnaireTranslation } from "@/feature/payroll/components/TranslationConstants";
import { DateInputField } from "@/feature/payroll/components/formFields/DateInputField";
import SelectFormField from "@/feature/payroll/components/formFields/SelectFormField";
import { QuestionnairePropsEmployer } from "@/models/payroll";
import { VerticalFlex } from "@/components/layout/Flex";

export function Limitation({ form }: QuestionnairePropsEmployer) {
  // get the value for limitType from form
  const limitType = form.watch("contractLimitation.limitType");

  return (
    <VerticalFlex className="mb-2 grid grid-cols-[auto_1fr_1fr] gap-4">
      <SelectFormField
        name={"contractLimitation.limitType"}
        control={form.control}
        labelKey={questionnaireTranslation("limitCard.limitType")}
        options={[
          {
            label: questionnaireTranslation("limitCard.limitValues.limited"),
            value: "limited",
          },
          {
            label: questionnaireTranslation("limitCard.limitValues.unlimited"),
            value: "unlimited",
          },
          {
            label: questionnaireTranslation(
              "limitCard.limitValues.purposeLimit",
            ),
            value: "purposeLimit",
          },
        ]}
      />

      {limitType === "limited" || limitType === "purposeLimit" ? (
        <>
          <DateInputField
            control={form.control}
            name={"contractLimitation.contractEndDate"}
            labelKey={questionnaireTranslation("limitCard.dateOfLimit")}
          />
          <DateInputField
            control={form.control}
            name={"contractLimitation.contractSignedDate"}
            labelKey={questionnaireTranslation("limitCard.dateSetContract")}
          />
        </>
      ) : null}
    </VerticalFlex>
  );
}
