import React from "react";
import { HoverInfo } from "@/feature/settings/UserSettings/form/info/hoverInfo";
import {
  payrollTranslation,
  questionnaireTranslation,
} from "@/feature/payroll/components/TranslationConstants";
import RadioFormField from "@/feature/payroll/components/formFields/RadioFormField";
import { HorizontalFlex, VerticalFlex } from "@/components/layout/Flex";
import { QuestionnairePropsEmployee } from "@/models/payroll";

export function StartPageEmployee({ form }: QuestionnairePropsEmployee) {
  return (
    <VerticalFlex>
      <HorizontalFlex className={"grid gap-4 md:grid-cols-2 lg:grid-cols-4"}>
        <RadioFormField
          name={"additionalInfos.hasChildren"}
          labelKey={questionnaireTranslation("childCard.hasChildren")}
          control={form.control}
          options={[
            { label: payrollTranslation("universal.yes"), value: "yes" },
            { label: payrollTranslation("universal.no"), value: "no" },
          ]}
        />
        <HorizontalFlex className={"flex space-x-2"}>
          <HorizontalFlex className={"flex-grow"}>
            <HoverInfo
              title={questionnaireTranslation("taxableCard.taxableEmployment")}
              description={questionnaireTranslation(
                "benefitsCard.infoCard.infoBenefits",
              )}
              align={"start"}
            />
          </HorizontalFlex>
          <RadioFormField
            name={"additionalInfos.hasVwl"}
            labelKey={questionnaireTranslation("benefitsCard.hasBenefits")}
            control={form.control}
            options={[
              { label: payrollTranslation("universal.yes"), value: "yes" },
              { label: payrollTranslation("universal.no"), value: "no" },
            ]}
          />
        </HorizontalFlex>
        <HorizontalFlex className={"flex space-x-2"}>
          <HorizontalFlex className={"flex-grow"}>
            <HoverInfo
              title={questionnaireTranslation("taxableCard.taxableEmployment")}
              description={questionnaireTranslation(
                "taxableCard.infoCard.infoTaxable",
              )}
              align={"start"}
            />
          </HorizontalFlex>
          <RadioFormField
            name={"additionalInfos.hasTaxableEmployment"}
            labelKey={questionnaireTranslation("taxableCard.hasTaxable")}
            control={form.control}
            options={[
              { label: payrollTranslation("universal.yes"), value: "yes" },
              { label: payrollTranslation("universal.no"), value: "no" },
            ]}
          />
        </HorizontalFlex>

        <RadioFormField
          name={"additionalInfos.isInConstruction"}
          labelKey={questionnaireTranslation("personalCard.isInConstruction")}
          control={form.control}
          options={[
            { label: payrollTranslation("universal.yes"), value: "yes" },
            { label: payrollTranslation("universal.no"), value: "no" },
          ]}
        />
      </HorizontalFlex>
    </VerticalFlex>
  );
}
