import React, { useContext } from "react";
import { Button } from "@/components/ui/button";
import {
  payrollTranslation,
  questionnaireTranslation,
} from "@/feature/payroll/components/TranslationConstants";
import { UseFormReturn } from "react-hook-form";
import { z } from "zod";
import { HorizontalFlex, VerticalFlex } from "@/components/layout/Flex";
import { Title } from "@/components/text/Title";
import EmployeeOverview from "@/feature/payroll/employeeOverview/EmployeeOverview";
import { ModalContext } from "@/components/modal/ModalProvider";
import { CreateEmployee } from "@/feature/payroll/employeeOverview/employeeCard/components/CreateEmployee";
import { Link } from "react-router-dom";

export interface PayrollProps<T extends z.ZodTypeAny> {
  form: UseFormReturn<z.infer<T>>;
}

function Payroll() {
  const { showModal } = useContext(ModalContext);

  const openCreateEmployeeModal = () => {
    showModal({
      title: questionnaireTranslation("employeeOverviewCard.createEmployee"),
      className: "w-screen",
      content: ({ close }) => <CreateEmployee onClose={close} />,
    });
  };
  return (
    <VerticalFlex>
      <HorizontalFlex className="w-full items-center justify-between py-4">
        <Title className={"mb-4"}>{payrollTranslation("payroll")}</Title>
        <HorizontalFlex>
          <Button className="w-auto" onClick={openCreateEmployeeModal}>
            {questionnaireTranslation("employeeOverviewCard.createEmployee")}
          </Button>
          {/*<Button>
            <Link to={"/payroll/salaryManagement"}>
              Lohnabrechnung erstellen
            </Link>
          </Button>*/}
        </HorizontalFlex>
      </HorizontalFlex>
      <EmployeeOverview />
    </VerticalFlex>
  );
}

export default Payroll;
