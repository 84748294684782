import { useEffect, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { payrollTranslation } from "@/feature/payroll/components/TranslationConstants";

export const usePrint = (printRef: React.RefObject<HTMLDivElement>) => {
  const [pageStyles, setPageStyles] = useState("");

  // Fetch the print CSS styles when the component loads
  useEffect(() => {
    fetch("/payroll-print.css")
      .then((res) => res.text())
      .then(setPageStyles);
  }, []);

  // React-to-print hook setup for print functionality
  return useReactToPrint({
    contentRef: printRef,
    documentTitle:
      "personalDetails.lastName_" + payrollTranslation("questionnaire"),
    ignoreGlobalStyles: true,
    pageStyle: pageStyles,
  });
};
