import { ReactNode } from "react";

interface TitleProps {
  children: ReactNode;
  level?: 1 | 2 | 3 | 4 | 5;
  className?: string;
}

export const Title = ({ children, level = 1, className="" }: TitleProps) => {
  let titleClass = "text-2xl font-medium";

  if (level === 2) {
    titleClass = "text-xl font-medium";
  } else if (level === 3) {
    titleClass = "text-lg font-medium";
  }else if (level === 4) {
    titleClass = "text-base font-medium";
  }
  else if (level === 5) {
    titleClass = "text-sm font-medium";
  }

  const combinedClass = `${titleClass} ${className}`;

  return <span className={combinedClass}>{children}</span>;
};
