import { useEffect, useState } from "react";
import { useGetEmployeeDataQuery } from "@/api/endpoints/payrollApi";
import {
  Employee,
  EmployeeEvent,
} from "@/feature/payroll/forms/questionnaire/schema/employee/EmployeeSchema";
import { SelectEnum } from "@/models/payroll";

export type AdditionalInfo = {
  hasChildren: SelectEnum;
  hasVwl: SelectEnum;
  hasTaxableEmployment: SelectEnum;
  isInConstruction: SelectEnum;
};

// determine if additional information exists for employee
const useHasInfo = (selectedEmployee?: Employee): AdditionalInfo | null => {
  const [hasInfo, setHasInfo] = useState<AdditionalInfo | null>(null);
  //get employee data from api
  const { data } = useGetEmployeeDataQuery(
    { employeeId: selectedEmployee?.id! },
    { skip: !selectedEmployee?.id },
  );

  // update additional info when data is fetched or employee changes
  useEffect(() => {
    if (selectedEmployee?.id || data) {
      // find employeeFillQuestionnaireEvent
      const employeeData = data?.events?.find(
        (event: EmployeeEvent) => event?.employeeFillQuestionnaireEvent,
      )?.employeeFillQuestionnaireEvent;

      if (employeeData) {
        setHasInfo(employeeData.additionalInfos);
      }
    }
  }, [selectedEmployee, data]);

  return hasInfo;
};

export default useHasInfo;
