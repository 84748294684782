import { FieldValues } from "react-hook-form/dist/types/fields";
import { FieldPath, UseFormReturn } from "react-hook-form";
import { Employee } from "@/feature/payroll/forms/questionnaire/schema/employee/EmployeeSchema";
import { z } from "zod";
import { FormSchemaEmployer } from "@/feature/payroll/forms/questionnaire/schema/employer/FormSchemaEmployer";
import { FormSchemaEmployee } from "@/feature/payroll/forms/questionnaire/schema/employee/FormSchemaEmployee";
import { ReactNode } from "react";
import { ManagementSchema } from "@/feature/payroll/salaryManagement/ManagementSchema";

interface QuestionnaireProps<T extends FieldValues> {
  form: UseFormReturn<T>;
  userType?: UserTypeEnum;
  construction?: true | false;
  employmentType?: string;
  selectedEmployee?: Employee;
}

export type QuestionnairePropsAny = QuestionnaireProps<
  z.infer<typeof FormSchemaEmployer> | z.infer<typeof FormSchemaEmployee>
>;

export type QuestionnairePropsEmployer = QuestionnaireProps<
  z.infer<typeof FormSchemaEmployer>
>;

export type QuestionnairePropsEmployee = QuestionnaireProps<
  z.infer<typeof FormSchemaEmployee>
>;

export interface Step<T extends z.ZodTypeAny> {
  title: string;
  icon: ReactNode;
  component: ReactNode;
  requiredFields: FieldPath<z.infer<T>>[];
  filter?: (f: z.infer<T>) => boolean;
}

export interface MultiStepCardProps<T extends z.ZodTypeAny> {
  form: UseFormReturn<z.infer<T>>;
  steps: Step<T>[];
  setCurrentTitle?: (title: string) => void;
  onStepChange: (step: number) => void;
  onTotalStepsChange?: (totalSteps: number) => void;
}

export interface SalaryManagementProps{
  form: UseFormReturn<z.infer<typeof ManagementSchema>>;
}

export enum UserTypeEnum {
  EMPLOYER = "employer",
  EMPLOYEE = "employee",
}

export enum SelectEnum {
  YES = "yes",
  NO = "no",
}

export enum EmploymentTypeEnum {
  MINIJOB = "miniJob",
  EMPLOYEE = "normalEmployee",
  TRAINEE = "trainee",
}

