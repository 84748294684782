import React from "react";
import {
  countriesTranslation,
  payrollTranslation,
  questionnaireTranslation,
} from "@/feature/payroll/components/TranslationConstants";
import { QuestionnaireEmployeeType } from "@/feature/payroll/forms/questionnaire/schema/employee/FormSchemaEmployee";
import { Title } from "@/components/text/Title";
import { Underline } from "@/components/text/Underline";
import { HorizontalFlex } from "@/components/layout/Flex";
import useHasInfo from "@/feature/payroll/forms/questionnaire/hooks/UseHasInfo";
import { Employee } from "@/feature/payroll/forms/questionnaire/schema/employee/EmployeeSchema";
import { SelectEnum } from "@/models/payroll";
import { DataProps } from "@/feature/payroll/forms/questionnaire/components/employeeData/steps/TaxData";
interface PersonalDataProps extends DataProps {
  personalDetails: QuestionnaireEmployeeType["personalDetails"];
}
export const PersonalData: React.FC<PersonalDataProps> = ({
  personalDetails,
  form,
  selectedEmployee,
}) => {
  const hasInfo = useHasInfo(selectedEmployee);
  const construction = form?.watch("additionalInfos.isInConstruction");
  const insuranceNumber = form?.watch("personalDetails.insuranceNumber");

  const insuranceNumberCombined =
    personalDetails?.insuranceNumber || insuranceNumber;

  return (
    <section className="mb-8 font-light">
      <Underline>
        <Title level={4}>
          {questionnaireTranslation("personalCard.personalInfo")}
        </Title>
      </Underline>

      <HorizontalFlex className="grid grid-cols-2 gap-4 md:grid-cols-4">
        <span className="col-span-1 font-medium">
          {payrollTranslation("universal.birthName")}:
        </span>
        <span className="col-span-1">{personalDetails?.birthName}</span>

        <span className="col-span-1 font-medium">
          {payrollTranslation("universal.birthDate")}:
        </span>
        <span className="col-span-1">
          {" "}
          {personalDetails?.birthDate
            ? new Date(personalDetails?.birthDate).toLocaleDateString("de-DE")
            : "-"}
        </span>

        <span className="col-span-1 font-medium">
          {questionnaireTranslation("personalCard.phone")}:
        </span>
        <span className="col-span-1">{personalDetails?.phone || "-"}</span>

        <span className="col-span-1 font-medium">
          {questionnaireTranslation("personalCard.address.street")}:
        </span>
        <span className="col-span-1">
          {personalDetails?.address?.street || "-"}
        </span>

        <span className="col-span-1 font-medium">
          {questionnaireTranslation("personalCard.address.houseNumber")}:
        </span>
        <span className="col-span-1">
          {personalDetails?.address?.houseNumber || "-"}
        </span>

        <span className="col-span-1 font-medium">
          {questionnaireTranslation("personalCard.address.city")}:
        </span>
        <span className="col-span-1">
          {personalDetails?.address?.city || "-"}
        </span>

        <span className="col-span-1 font-medium">
          {questionnaireTranslation("personalCard.address.postalCode")}:
        </span>
        <span className="col-span-1">
          {personalDetails?.address?.postalCode || "-"}
        </span>

        <span className="col-span-1 font-medium">
          {questionnaireTranslation("personalCard.address.country")}:
        </span>
        <span className="col-span-1">
          {personalDetails?.address?.country
            ? countriesTranslation(`${personalDetails?.address?.country}`)
            : "-"}
        </span>

        <span className="col-span-1 font-medium">
          {questionnaireTranslation("personalCard.address.addressAddition")}:
        </span>
        <span className="col-span-1">
          {personalDetails?.address?.addressAdditional || "-"}
        </span>

        {!insuranceNumberCombined && (
          <div className={"grid grid-cols-2 md:col-span-1 lg:col-span-2 gap-4"}>
            <span className="font-medium">
              {questionnaireTranslation("personalCard.birthPlace")}:
            </span>
            <span className="">{personalDetails?.birthPlace || "-"}</span>


          </div>
        )}
        {!insuranceNumberCombined && (
          <div className={"grid grid-cols-2 md:col-span-1 lg:col-span-2 gap-4"}>


            <span className="font-medium">
              {questionnaireTranslation("personalCard.birthCountry")}:
            </span>
            <span className="">{personalDetails?.birthCountry || "-"}</span>
          </div>
        )}
        <span className="col-span-1 font-medium">
          {questionnaireTranslation("personalCard.insuranceNumber")}:
        </span>
        <span className="col-span-1">
          {personalDetails?.insuranceNumber || "-"}
        </span>

        <span className="col-span-1 font-medium">
          {questionnaireTranslation("personalCard.severelyDisabled")}:
        </span>
        <span className="col-span-1">
          {personalDetails?.disabled
            ? questionnaireTranslation(
                `personalCard.${personalDetails?.disabled}`,
              )
            : "-"}
        </span>

        <span className="col-span-1 font-medium">
          {questionnaireTranslation("personalCard.gender")}:
        </span>
        <span className="col-span-1">
          {personalDetails?.gender
            ? questionnaireTranslation(
                `personalCard.${personalDetails?.gender}`,
              )
            : "-"}
        </span>

        <span className="col-span-1 font-medium">
          {questionnaireTranslation("personalCard.nationality")}:
        </span>
        <span className="col-span-1">
          {personalDetails?.nationality
            ? countriesTranslation(`${personalDetails?.nationality}`)
            : "-"}
        </span>

        {(hasInfo?.isInConstruction === SelectEnum.YES ||
          construction === SelectEnum.YES) && (
          <div className={"grid grid-cols-2 md:col-span-1 lg:col-span-2"}>
            <span className="font-medium">
              {questionnaireTranslation("personalCard.employeeNumber")}
            </span>
            <span className="">
              {personalDetails?.employeeNumberSocialFund || "-"}
            </span>
          </div>
        )}

        <span className="col-span-1 font-medium">
          {payrollTranslation("universal.iban")}:
        </span>
        <span className="col-span-1">{personalDetails?.iban || "-"}</span>

        <span className="col-span-1 font-medium">
          {payrollTranslation("universal.bic")}:
        </span>
        <span className="col-span-1">{personalDetails?.bic || "-"}</span>
      </HorizontalFlex>
    </section>
  );
};

export default PersonalData;
