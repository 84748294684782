import * as React from "react";
import { cva, type VariantProps } from "class-variance-authority";

import { cn } from "src/lib/utils";

// Definiere Badge-Varianten mit Styling-Optionen
const badgeVariants = cva(
  "inline-flex items-center gap-x-1.5 rounded-md px-2.5 py-0.5 text-xs font-medium ring-1 ring-inset ring-gray-800 transition-colors",
  {
    variants: {
      variant: {
        default: "text-gray-200",
        red: "text-white",
        yellow: "text-white",
        green: "text-white",
        blue: "text-white",
        indigo: "text-white",
        purple: "text-white",
        pink: "text-white",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  }
);

// BadgeProps erweitern, um zusätzliche Attribute zu unterstützen
export interface BadgeProps
  extends React.HTMLAttributes<HTMLSpanElement>,
    VariantProps<typeof badgeVariants> {}

// Badge-Komponente definieren
function Badge({ className, variant, children, ...props }: BadgeProps) {
  const dotColor = {
    default: "fill-white",
    red: "fill-red-400",
    yellow: "fill-yellow-400",
    green: "fill-green-400",
    blue: "fill-blue-400",
    indigo: "fill-indigo-400",
    purple: "fill-purple-400",
    pink: "fill-pink-400",
  };

  return (
    <span className={cn(badgeVariants({ variant }), className)} {...props}>
      <svg
        className={cn("h-2 w-2", dotColor[variant || "default"])}
        viewBox="0 0 6 6"
        aria-hidden="true"
      >
        <circle cx="3" cy="3" r="3" />
      </svg>
      {children}
    </span>
  );
}

export { Badge, badgeVariants };
