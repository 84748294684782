import { z } from "zod";
import { alertMessage } from "@/feature/payroll/components/TranslationConstants";

export const FormSchemaChanges = z.object({
  generalDetails: z.object({
    personnelNumber: z.string().min(1, {
      message: alertMessage,
    }),
    companyName: z.string().optional(),
    companyAddress: z.string().optional(),
    firstName: z.string().min(1, {
      message: alertMessage,
    }),
    lastName: z.string().min(1, {
      message: alertMessage,
    }),
  }),

  changeWeeklyHours: z.object({
    weeklyOld: z.string().optional(),
    weeklyNew: z.string().optional(),
    reason: z.string().optional(),
    averageHours: z.string().optional(),
    validFrom: z.date().optional(),
    additionCareTime: z.object({
      reducedWage: z.string().optional(),
      reductionStart: z.date().optional(),
      reductionEnd: z.date().optional(),
    }),
  }),

  changeLimit: z.object({
    writtenConclusion: z.string().optional(),
    limitUntil: z.date().optional(),
    limitFrom: z.date().optional(),
    lastExtension: z.date().optional(),
    limitPerspective: z.string().optional(),
  }),

  trainingEnd: z.object({
    endOld: z.date().optional(),
    endNew: z.date().optional(),
  }),
});


export type ChangesSchema = z.infer<typeof FormSchemaChanges>;