import { reduxApi } from "../reduxApi";
import { Filter, FilterResult } from "@/api/types";
import {
  CreateEmployee,
  Employee, UpdateEmployee
} from "@/feature/payroll/forms/questionnaire/schema/employee/EmployeeSchema";
import { z } from "zod";
import { FormSchemaEmployee } from "@/feature/payroll/forms/questionnaire/schema/employee/FormSchemaEmployee";
import { FormSchemaEmployer } from "@/feature/payroll/forms/questionnaire/schema/employer/FormSchemaEmployer";
import { EmploymentTypeEnum } from "@/models/payroll";


const payrollApi = reduxApi.injectEndpoints({
  endpoints: (builder) => ({
    //Erstellen des Mitarbeiters
    createEmployees: builder.mutation<CreateEmployee, CreateEmployee>({
      query: (body) => ({
        url: `payroll/employees/create`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Employee"],
    }),
    //Updaten des Mitarbeiters
    updateEmployees: builder.mutation<UpdateEmployee, {employee: UpdateEmployee, employeeId: string}>({
      query: (data) => ({
        url: `payroll/employees/${data.employeeId}/update`,
        method: "POST",
        body: data.employee,
      }),
      invalidatesTags: ["Employee"],
    }),
    //Abrufen aller MA (z.B. zur Auflistung)
    getListEmployees: builder.query<FilterResult<Employee>, Filter<Employee>>({
      query: (body) => ({
        url: `payroll/employees/filter`,
        method: "POST",
        body,
      }),
      providesTags: ["Employee"],
    }),

    // AG speichert Questionnaire
    completeQuestionnaire: builder.mutation<
      z.infer<typeof FormSchemaEmployer>,
      { questionnaire: z.infer<typeof FormSchemaEmployer>; employeeId: string }
    >({
      query: (data) => ({
        url: `payroll/employees/${data.employeeId}/personalquestionnaire/complete`,
        method: "POST",
        body: data.questionnaire,
      }),
      invalidatesTags: ["Employee"],
    }),

    getCompleteQuestionnaire: builder.query<
      z.infer<typeof FormSchemaEmployer>,
      { employeeId: string }
    >({
      query: (data) => ({
        url: `payroll/employees/${data.employeeId}/personalquestionnaire/complete`,
        method: "GET",
      }),
      providesTags: ["Employee"],
    }),

    // Sendet Link zum Fragebogen an MA
    sendQuestionnaireLink: builder.mutation<
      Employee,
      { employee: Employee; employmentType: string }
    >({
      query: (data) => ({
        url: `payroll/employees/${data.employee.id}/personalquestionnaire/send`,
        method: "POST",
        body: {
          email: data.employee.email,
          employmentType: data.employmentType,
        },
      }),
      invalidatesTags: ["Employee"],
    }),

    //Questionnaire Daten vom MA empfangen (z.B. zur Ansicht für AG oder PDF)
    getEmployeeData: builder.query<Employee,
      { employeeId: string }
    >({
      query: (data) => ({
        url: `payroll/employees/${data.employeeId}`,
        method: "GET",
      }),
      providesTags: ["Employee"],
    }),



    //Empfängt MA Questionnaire-Daten von AG über Validierungstoken
    //MA hat keinen Account, deswegen muss der Fragebogen so angefordert werden, um ausgefüllt werden zu können
    getEmployeeQuestionnaire: builder.query<
      {
        employee: Employee;
        sendQuestionnaireEvent: {
          email: string;
          employmentType: EmploymentTypeEnum;
        };
      },
      { employeeToken: string }
    >({
      query: (data) => ({
        url: `payroll/${data.employeeToken}`,
        method: "GET",
      }),
      providesTags: ["Employee"],
    }),

    saveEmployeeQuestionnaire: builder.mutation<
      z.infer<typeof FormSchemaEmployee>,
      {
        employeeForm: z.infer<typeof FormSchemaEmployee>;
        employeeToken: string;
      }
    >({
      query: (data) => {
        return {
          url: `payroll/${data.employeeToken}/personalquestionnaire/employeefill`,
          method: "POST",
          body: data.employeeForm,
        };
      },
      invalidatesTags: ["Employee"],
    }),
  }),
});

export const {
  useCreateEmployeesMutation,
  useUpdateEmployeesMutation,
  useGetListEmployeesQuery,
  useCompleteQuestionnaireMutation,
  useSendQuestionnaireLinkMutation,
  useGetEmployeeDataQuery,
  useGetEmployeeQuestionnaireQuery,
  useSaveEmployeeQuestionnaireMutation,
} = payrollApi;
