import React from "react";
import HorizontalGradientLine from "@/components/layout/HorizontalGradientLine";
import { QuestionnaireEmployeeType } from "@/feature/payroll/forms/questionnaire/schema/employee/FormSchemaEmployee";
import {
  payrollTranslation,
  questionnaireTranslation,
} from "@/feature/payroll/components/TranslationConstants";
import { Underline } from "@/components/text/Underline";
import { Title } from "@/components/text/Title";
import { HorizontalFlex } from "@/components/layout/Flex";

type PreviousType =
  QuestionnaireEmployeeType["taxableEmployment"]["previousEmployment"][number];

const TaxableData: React.FC<{
  taxableEmployment: QuestionnaireEmployeeType["taxableEmployment"];
}> = ({ taxableEmployment }) => {
  return (
    <section className="mb-8">
      <Underline>
        <Title level={4}>
          {questionnaireTranslation("taxableCard.taxableEmployment")}
        </Title>
      </Underline>

      <HorizontalFlex className="grid grid-cols-4 gap-4">
        {Array.isArray(taxableEmployment?.previousEmployment) &&
        taxableEmployment.previousEmployment.length > 0
          ? taxableEmployment.previousEmployment.map(
              (employment: PreviousType, index: number) => (
                <React.Fragment key={index}>
                  <HorizontalFlex
                    className={"col-span-4 mb-4 grid grid-cols-4 gap-4"}
                  >
                    <span className="font-medium">
                      {questionnaireTranslation("taxableCard.fromUntil")}:
                    </span>
                    {employment.dateFrom
                      ? new Date(employment.dateFrom).toLocaleDateString(
                          "de-DE",
                        )
                      : undefined}{" "}
                    -{" "}
                    {employment.dateUntil
                      ? new Date(employment.dateUntil).toLocaleDateString(
                          "de-DE",
                        )
                      : undefined}
                    <span className="font-medium">
                      {questionnaireTranslation("taxableCard.typeOccupation")}:
                    </span>
                    <span>{employment.typeOccupation || "-"}</span>
                    <span className="font-medium">
                      {questionnaireTranslation("taxableCard.daysOccupation")}
                    </span>
                    <span>{employment.daysOccupation || "-"}</span>
                    <HorizontalGradientLine className={"col-span-4"} />
                  </HorizontalFlex>
                </React.Fragment>
              ),
            )
          : ""}

        <span className="col-span-2 font-medium">
          {questionnaireTranslation("taxableCard.moneyLimit")}:
        </span>
        <span>
          {taxableEmployment?.miniJobberLimit?.moneyLimit
            ? payrollTranslation(
                `universal.${taxableEmployment?.miniJobberLimit?.moneyLimit}`,
              )
            : "-"}
        </span>
      </HorizontalFlex>
    </section>
  );
};

export default TaxableData;
