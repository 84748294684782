import React from "react";
import { questionnaireTranslation } from "@/feature/payroll/components/TranslationConstants";
import { getField } from "@/feature/payroll/forms/questionnaire/components/printForm/functions/GetFieldFunction";
import { FormData } from "@/feature/payroll/forms/questionnaire/components/printForm/components/PrintGeneralDetails";
import { useEmploymentType } from "@/feature/payroll/forms/questionnaire/hooks/UseEmploymentType";
import { Employee } from "@/feature/payroll/forms/questionnaire/schema/employee/EmployeeSchema";
import { EmploymentTypeEnum } from "@/models/payroll";

export const PrintTaxes: React.FC<{
  formData: FormData;
  selectedEmployee?: Employee;
}> = ({ formData, selectedEmployee }) => {
  const { employmentType } = useEmploymentType(selectedEmployee!);
  return (
    <div className={"page-break"} style={{ paddingTop: "10px" }}>
      <h2 style={{ borderBottom: "2px solid gray" }}>
        {questionnaireTranslation("taxCard.taxes")}
      </h2>
      <div className="grid-container">
        <span className="title-fill">
          {questionnaireTranslation("taxCard.taxId")}
        </span>
        <span>{getField("taxInformation.taxId", formData)}</span>
        <span className="title-fill">
          {questionnaireTranslation("taxCard.taxClass")}
        </span>

        <span>{getField("taxInformation.taxClass", formData)}</span>
        <span className="title-fill">
          {questionnaireTranslation("taxCard.factor")}
        </span>
        <span>{getField("taxInformation.taxFactor", formData)}</span>
        <span className="title-fill">
          {questionnaireTranslation("taxCard.childAllowance")}
        </span>
        <span>
          {" "}
          {getField("taxInformation.childAllowance", formData)}
        </span>
        <span className="title-fill">
          {questionnaireTranslation("taxCard.identificationEmployer")}
        </span>
        <span>
          {getField("taxInformation.identificationEmployer", formData).length >
            0 && getField("taxInformation.identificationEmployer", formData)}
        </span>
        {employmentType === EmploymentTypeEnum.MINIJOB && (
          <>
            <span className="title-fill">
              {questionnaireTranslation("taxCard.rollOff")}
            </span>
            <span>
              {getField("taxInformation.miniJobberTax.rollOff", formData)}
            </span>
            <span className="title-fill">
              {questionnaireTranslation("taxCard.generalize")}
            </span>
            <span>
              {getField("taxInformation.miniJobberTax.generalize", formData)}
            </span>
          </>
        )}
      </div>
    </div>
  );
};
