import React from "react";
import { useParams } from "react-router";
import { invoiceApi } from "@/api/endpoints/invoiceApi";
import Spinner from "@/components/loading/spinner";
import { InvoiceNotFound } from "@/components/error/NotFound";
import { EditInvoiceForm } from "@/feature/invoices/edit/form/EditInvoiceForm";
import {
  InvoiceLayout,
  useInvoicePreview,
} from "@/feature/invoices/edit/form/InvoiceLayout";

const EditInvoice = () => {
  const { id } = useParams();
  const { preview, setPreview } = useInvoicePreview();

  const { data, isLoading, isSuccess } = invoiceApi.useGetInvoiceQuery(
    id as string,
  );

  if (isLoading) {
    return <Spinner />;
  }

  if (!isSuccess) {
    return <InvoiceNotFound />;
  }

  return (
    <InvoiceLayout preview={preview}>
      <EditInvoiceForm
        setPreview={setPreview}
        invoiceDraft={data}
        key={"company-form"}
      />
    </InvoiceLayout>
  );
};

export default EditInvoice;
