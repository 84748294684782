import * as React from "react";
import TextareaAutosize from "react-textarea-autosize";
import { Button } from "@/components/ui/button";
import { ArrowUpIcon } from "lucide-react";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { useEnterSubmit } from "./hooks/useEnterSubmit";
import { DocumentChip } from "../DocumentChip";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { BiDocument } from "@/models/document";

interface PromptFormProps {
  onSubmit: (inputValue: string) => Promise<void>;
  input: string;
  setInput: React.Dispatch<React.SetStateAction<string>>;
  isLoading: boolean;
  uiContext?: BiDocument;
  removeContext?: () => void;
}

export function PromptForm({
  onSubmit,
  input,
  setInput,
  isLoading,
  uiContext,
  removeContext,
}: PromptFormProps) {
  const { formRef, onKeyDown } = useEnterSubmit();
  const textareaRef = React.useRef<HTMLTextAreaElement>(null);

  React.useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.focus();
    }
  }, []);

  return (
    <form
      ref={formRef}
      onSubmit={async (e) => {
        e.preventDefault();
        if (!input?.trim()) {
          return;
        }
        const currentInput = input;
        setInput("");

        // Chat-Logik ausführen
        await onSubmit(currentInput);
      }}
    >
      <div className="relative flex max-h-60 w-full grow flex-col overflow-hidden rounded-md border bg-secondary px-4">
        {uiContext?.invoiceNumber && (
          <div className="pb-1 pt-3">
            <DocumentChip
              invoiceNumber={uiContext.invoiceNumber}
              onRemove={removeContext}
            />
          </div>
        )}

        <TextareaAutosize
          ref={textareaRef}
          tabIndex={0}
          minRows={1}
          onKeyDown={onKeyDown}
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder="Sende eine Nachricht an die Metrics AI"
          spellCheck={false}
          className="min-h-[60px] w-full resize-none bg-secondary px-0 py-[1.3rem] pe-12 focus-within:outline-none sm:text-sm"
        />

        <div className="absolute right-2 top-4">
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                type="submit"
                size="icon"
                disabled={isLoading || input === ""}
              >
                <ArrowUpIcon />
                <span className="sr-only">Nachricht senden</span>
              </Button>
            </TooltipTrigger>
            <TooltipContent>Nachricht senden</TooltipContent>
          </Tooltip>
        </div>
      </div>

      <div className="mt-1 text-center text-xs text-neutral-600">
        Der Assistent kann Fehler machen. Überprüfe wichtige Informationen.
      </div>
    </form>
  );
}
