import { BookingError, BookingErrorLevel } from "@/models/booking";
import { FC } from "react";
import { useFeatureFlag } from "@/util/useFeatureFlag";
import { FeatureFlags } from "@/models/user";
import { HorizontalFlex, VerticalFlex } from "@/components/layout/Flex";
import { useTranslation } from "react-i18next";
import { BookMinus, BookX } from "lucide-react";
import { useLoggedIn } from "@/feature/auth/ProtectedRoutes";

export const BookingErrorDisplay: FC<{
  errors?: BookingError[];
}> = (args) => {
  const { t, i18n } = useTranslation();
  const { activeCompany } = useLoggedIn();

  // filter for skr
  let errors = (args.errors ?? []).filter(
    (b) => b.accountingModel == activeCompany.accounting?.taxSystem,
  );

  if (!errors || errors.length === 0) return null;

  return (
    <VerticalFlex>
      <div />
      {errors.map((err, index) => (
        <HorizontalFlex key={index}>
          {err.level == BookingErrorLevel.Error && (
            <BookX className={"text-red-500"} />
          )}
          {err.level == BookingErrorLevel.Warn && (
            <BookMinus className={"text-yellow-600"} />
          )}
          {err.level == BookingErrorLevel.Info && (
            <BookMinus className={"text-white-500"} />
          )}
          <span className={"text-sm text-muted-foreground"}>
            {i18n.exists("model.bookingError.code." + err.code)
              ? (t(
                  "model.bookingError.code." + err.code,
                  err.extra || {},
                ) as string)
              : err.code}
          </span>
        </HorizontalFlex>
      ))}
      <div />
    </VerticalFlex>
  );
};
