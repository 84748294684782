// Definiere die benutzerdefinierte Hook
import { useParams } from "react-router";
import { useGetEmployeeDataQuery } from "@/api/endpoints/payrollApi";

export const useGetCurrentEmployee = () => {
  //get current employee id from url
  const { id } = useParams<{ id: string }>();
  // get data from employee with current id
  const { data: employee } = useGetEmployeeDataQuery(
    { employeeId: id || "" },
    { skip: !id },
  );

  return {
    employee,
  };
};

export default useGetCurrentEmployee;
