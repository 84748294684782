import React from "react";
import {
  payrollTranslation,
  questionnaireTranslation,
} from "@/feature/payroll/components/TranslationConstants";
import { getField } from "@/feature/payroll/forms/questionnaire/components/printForm/functions/GetFieldFunction";
import { formatDate } from "@/feature/payroll/forms/questionnaire/components/printForm/functions/FormateDateFunction";
import { FormData } from "@/feature/payroll/forms/questionnaire/components/printForm/components/PrintGeneralDetails";
import { useEmploymentType } from "@/feature/payroll/forms/questionnaire/hooks/UseEmploymentType";
import { Employee } from "@/feature/payroll/forms/questionnaire/schema/employee/EmployeeSchema";
import { EmploymentTypeEnum } from "@/models/payroll";

export const PrintPayment: React.FC<{
  formData: FormData;
  selectedEmployee?: Employee;
}> = ({ formData, selectedEmployee }) => {
  const { employmentType } = useEmploymentType(selectedEmployee!);
  const YEARS = ["yearOne", "yearTwo", "yearThree"] as const;
  const additionalPayments = Array.isArray(
    getField("payment.additionalEmployments", formData),
  )
    ? getField("payment.additionalEmployments", formData)
    : [];
  return (
    <div className={"page-break"}>
      <div style={{ paddingTop: "10px" }}>
        {employmentType === EmploymentTypeEnum.TRAINEE ? (
          <div>
            <h2 style={{ borderBottom: "2px solid gray" }}>
              {questionnaireTranslation("paymentCard.traineePayment")}
            </h2>

            <div className={"grid-container-five"}>
              <div></div>
              <span className="title-fill">
                {questionnaireTranslation("paymentCard.sum")}
              </span>
              <span className="title-fill">
                {payrollTranslation("universal.validFrom")}
              </span>
              <span className="title-fill">
                {questionnaireTranslation("paymentCard.hourlyWage")}
              </span>
              <span className="title-fill">
                {payrollTranslation("universal.validFrom")}
              </span>
            </div>

            {YEARS.map((year) => (
              <div
                key={year}
                className={"grid-container-five"}
                style={{ marginBottom: "3px" }}
              >
                <span className="title-fill">
                  {questionnaireTranslation(`paymentCard.${year}`)}
                </span>
                <span>
                  {getField(`payment.traineePayment.${year}.sum`, formData) &&
                    getField(`payment.traineePayment.${year}.sum`, formData) +
                      " €"}
                </span>
                <span>
                  {formatDate(
                    getField(
                      `payment.traineePayment.${year}.dateSum`,
                      formData,
                    ),
                  ) || ""}
                </span>
                <span>
                  {getField(
                    `payment.traineePayment.${year}.hourlyWage`,
                    formData,
                  ) && (
                    getField(
                      `payment.traineePayment.${year}.hourlyWage`,
                      formData,
                    ) + " €")}
                </span>
                <span>
                  {formatDate(
                    getField(
                      `payment.traineePayment.${year}.dateWage`,
                      formData,
                    ),
                  ) || ""}
                </span>
              </div>
            ))}
          </div>
        ) : (
          <div>
            <h2 style={{ borderBottom: "2px solid gray" }}>
              {questionnaireTranslation("paymentCard.payment")}
            </h2>
            <div className="grid-container">
              <span className="title-fill">
                {questionnaireTranslation("paymentCard.paymentDescription")}
              </span>
              <span style={{ gridColumn: "span 3" }}>
                {getField("payment.description", formData)}
              </span>

              <span className="title-fill">
                {questionnaireTranslation("paymentCard.sum")}
              </span>
              <span>
                {getField("payment.sum", formData).length > 0
                  && (getField("payment.sum", formData) + " €")}
              </span>

              <span className="title-fill">
                {payrollTranslation("universal.validFrom")}
              </span>
              <span>{getField("payment.dateSum", formData)}</span>

              <span className="title-fill">
                {questionnaireTranslation("paymentCard.hourlyWage")}
              </span>
              <span>
                {getField("payment.hourlyWage", formData).length > 0
                  && (getField("payment.hourlyWage", formData) + " €")}
              </span>

              <span className="title-fill">
                {payrollTranslation("universal.validFrom")}
              </span>
              <span>{getField("payment.dateWage", formData)}</span>
            </div>
          </div>
        )}

        <div className="grid-container" style={{ marginTop: "3px" }}>
          <span className="title-fill">
            {questionnaireTranslation("paymentCard.christmasMoney")}
          </span>
          <span>
            {getField("payment.christmasMoney", formData).length > 0
              ? (getField("payment.christmasMoney", formData) + " €")
              : ""}
          </span>
          <span className="title-fill">
            {questionnaireTranslation("paymentCard.payoutMonth")}
          </span>
          <span>{getField("payment.payoutMonthChristmas", formData)}</span>
          <span className="title-fill">
            {questionnaireTranslation("paymentCard.vacationMoney")}
          </span>
          <span>
            {getField("payment.vacationMoney", formData).length > 0
              ? (getField("payment.vacationMoney", formData) + " €")
              : ""}
          </span>
          <span className="title-fill">
            {questionnaireTranslation("paymentCard.payoutMonth")}
          </span>
          <span>{getField("payment.payoutMonthVacation", formData)}</span>

          {additionalPayments.length > 1 &&
            additionalPayments.map(
              (
                payment: {
                  additionalPaymentName: string;
                  additionalPaymentSum: null;
                },
                index: number,
              ) => (
                <div
                  className="grid-container"
                  key={index}
                  style={{ marginBottom: "4px" }}
                >
                  <span className="title-fill">
                    {questionnaireTranslation(
                      "paymentCard.additionalPaymentName",
                    )}
                  </span>
                  <span>{payment.additionalPaymentName}</span>
                  <span className="title-fill">
                    {questionnaireTranslation(
                      "paymentCard.additionalPaymentSum",
                    )}
                  </span>
                  <span>{payment?.additionalPaymentSum + " €"}</span>
                </div>
              ),
            )}
        </div>
      </div>
    </div>
  );
};
