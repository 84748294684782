import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";

import React from "react";
import {
  payrollTranslation,
  questionnaireTranslation,
} from "@/feature/payroll/components/TranslationConstants";
import { HorizontalFlex } from "@/components/layout/Flex";

interface DialogOnSubmitProps {
  open: boolean;
  onClose: () => void;
}

export function DialogOnSubmit({ open, onClose }: DialogOnSubmitProps) {
  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>{payrollTranslation("universal.done")}</DialogTitle>
          <DialogDescription>
            {questionnaireTranslation("dialogSave")}
          </DialogDescription>
        </DialogHeader>
        <HorizontalFlex className="grid gap-4 py-4"></HorizontalFlex>
        <DialogFooter>
          <DialogClose onClick={onClose}>
            <Button variant="secondary" type="button">
              {payrollTranslation("universal.close")}
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
