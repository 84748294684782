import React from "react";
import { questionnaireTranslation } from "@/feature/payroll/components/TranslationConstants";
import { getField } from "@/feature/payroll/forms/questionnaire/components/printForm/functions/GetFieldFunction";
import { FormData } from "@/feature/payroll/forms/questionnaire/components/printForm/components/PrintGeneralDetails";

export const PrintLimit: React.FC<{
  formData: FormData;
}> = ({ formData }) => {
  return (
    <div className={"page-break"} style={{ paddingTop: "10px" }}>
      <h2 style={{ borderBottom: "2px solid gray" }}>
        {questionnaireTranslation("limitCard.timeLimit")}
      </h2>
      <div className="grid-container">
        <span className="title-fill">
          {questionnaireTranslation("limitCard.limitType")}
        </span>
        <span>
          {" "}
          {getField("contractLimitation.limitType", formData).length > 0 &&
            getField("contractLimitation.limitType", formData)}
        </span>
        <span className="title-fill">
          {questionnaireTranslation("limitCard.dateOfLimit")}
        </span>
        <span>{getField("contractLimitation.contractEndDate", formData)}</span>
        <span className="title-fill">
          {questionnaireTranslation("limitCard.dateSetContract")}
        </span>
        <span>
          {getField("contractLimitation.contractSignedDate", formData)}
        </span>
      </div>
    </div>
  );
};
