import React from "react";
import { PrintBenefits } from "@/feature/payroll/forms/questionnaire/components/printForm/components/PrintBenefits";
import { PrintTaxable } from "@/feature/payroll/forms/questionnaire/components/printForm/components/PrintTaxable";
import { PrintSocialSec } from "@/feature/payroll/forms/questionnaire/components/printForm/components/PrintSocialSec";
import { PrintTaxes } from "@/feature/payroll/forms/questionnaire/components/printForm/components/PrintTaxes";
import { PrintLimit } from "@/feature/payroll/forms/questionnaire/components/printForm/components/PrintLimit";
import { PrintKids } from "@/feature/payroll/forms/questionnaire/components/printForm/components/PrintKids";
import { PrintEmployment } from "@/feature/payroll/forms/questionnaire/components/printForm/components/PrintEmployment";
import { PrintPersonalDetails } from "@/feature/payroll/forms/questionnaire/components/printForm/components/PrintPersonalDetails";
import { PrintPayment } from "@/feature/payroll/forms/questionnaire/components/printForm/components/PrintPayment";
import { Employee } from "@/feature/payroll/forms/questionnaire/schema/employee/EmployeeSchema";

import {
  payrollTranslation,
  questionnaireTranslation,
} from "@/feature/payroll/components/TranslationConstants";
import useEmployeeData from "@/feature/payroll/forms/questionnaire/hooks/UseEmployeeData";
import useHasInfo from "@/feature/payroll/forms/questionnaire/hooks/UseHasInfo";
import { PrintCompany } from "@/feature/payroll/forms/questionnaire/components/printForm/components/PrintCompany";
import { SelectEnum } from "@/models/payroll";

const PrintForm: React.FC<{ selectedEmployee?: Employee }> = ({
  selectedEmployee,
}) => {
  // check if employee has vwl, taxableInformation or children
  const hasInfo = useHasInfo(selectedEmployee);
  // get value to get form data and employment type label for employee
  const { formData, employmentTypeLabel } = useEmployeeData(selectedEmployee);

  const currentDateQuestionnaire = new Date().toLocaleDateString("de-DE", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });

  return (
    <div style={{ margin: "12px", padding: "12px" }} className="page-break">
      <div
        className="grid-container-sign"
        style={{ borderBottom: "4px solid gray" }}
      >
        <h1 className="flex" style={{ whiteSpace: "nowrap" }}>
          {payrollTranslation("questionnaire")}: {employmentTypeLabel}
        </h1>
        <p style={{ justifySelf: "end", fontSize: "medium" }}>
          {currentDateQuestionnaire}
        </p>
      </div>

      <PrintCompany />

      <div
        className="grid-container-heading"
        style={{ marginTop: "10px", marginBottom: "30px" }}
      >
        <span className="heading-span">
          {payrollTranslation("universal.employeeName")}
        </span>
        <span className="title-fill heading-span">
          {selectedEmployee?.firstName && selectedEmployee?.lastName
            ? `${selectedEmployee?.firstName} ${selectedEmployee?.lastName}`
            : ""}
        </span>
        <span className="heading-span" style={{ marginRight: "4px" }}>
          {payrollTranslation("universal.email")}
        </span>
        <span className="title-fill heading-span">
          {selectedEmployee?.email}
        </span>
        <span className="heading-span" style={{ marginRight: "4px" }}>
          {payrollTranslation("universal.personnelNumber")}
        </span>
        <span className="title-fill heading-span">
          {selectedEmployee?.personnelNumber}
        </span>
      </div>

      <PrintPersonalDetails
        formData={formData}
        selectedEmployee={selectedEmployee}
      />
      <PrintEmployment formData={formData} />
      {hasInfo?.hasChildren.valueOf() === SelectEnum.YES ? (
        <PrintKids formData={formData} />
      ) : null}
      <PrintLimit formData={formData} />
      <PrintPayment formData={formData} selectedEmployee={selectedEmployee} />
      <PrintTaxes formData={formData} selectedEmployee={selectedEmployee} />
      <PrintSocialSec formData={formData} selectedEmployee={selectedEmployee} />
      {hasInfo?.hasVwl.valueOf() === SelectEnum.YES ? (
        <PrintBenefits formData={formData} />
      ) : null}
      {hasInfo?.hasTaxableEmployment.valueOf() === SelectEnum.YES ? (
        <PrintTaxable formData={formData} selectedEmployee={selectedEmployee} />
      ) : null}
      <div style={{ paddingTop: "75px" }}>
        {currentDateQuestionnaire}
        <div
          className="grid-container-three"
          style={{ marginTop: "2px", display: "grid", gap: "15px" }}
        >
          <div style={{ borderTop: "1.5px solid gray" }}>
            <h3>{questionnaireTranslation("pdf.datePlace")}</h3>
          </div>

          <div style={{ borderTop: "1.5px solid gray" }}>
            <h3>{questionnaireTranslation("pdf.employee")}</h3>
          </div>

          <div
            style={{
              borderTop: "1.5px solid gray",
            }}
          >
            <h3>{questionnaireTranslation("pdf.employer")}</h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrintForm;
