import { reduxApi } from "@/api/reduxApi";
import { Company, CompanyUpdateData } from "@/models/company";

export const companyApi = reduxApi.injectEndpoints({
  endpoints: (builder) => ({
    getCompanyData: builder.query<CompanyUpdateData, CompanyUpdateData>({
      query: (body) => ({
        url: "company/filter",
        method: "POST",
        body: body,
      }),
      providesTags: ["User", "Invoice"],
    }),

    updateCompany: builder.mutation<Company, CompanyUpdateData>({
      query: (body) => ({
        url: "company/",
        method: "PUT",
        body: body,
      }),
      invalidatesTags: ["User", "Invoice"],
    }),
    updateCompanyLogo: builder.mutation<Company, { logo: File }>({
      query: ({ logo }) => ({
        url: `company/logo`,
        method: "PUT",
        body: logo,
      }),
      invalidatesTags: ["User", "Invoice"],
    }),
  }),
});

export const { useGetCompanyDataQuery } = companyApi;
