import React, { useContext, useRef } from "react";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  CalendarCog,
  CalendarMinus,
  CalendarOff,
  ChevronsUpDown,
  Download,
  FilePenLine,
  FilePlus,
  Replace,
} from "lucide-react";
import { payrollTranslation, questionnaireTranslation } from "@/feature/payroll/components/TranslationConstants";
import { Link } from "react-router-dom";
import { HorizontalFlex, VerticalFlex } from "@/components/layout/Flex";
import { Employee } from "@/feature/payroll/forms/questionnaire/schema/employee/EmployeeSchema";
import { Card, CardContent, CardHeader } from "@/components/ui/card";
import { Title } from "@/components/text/Title";
import { usePrint } from "@/feature/payroll/forms/questionnaire/hooks/UsePrintManagement";
import PrintForm from "@/feature/payroll/forms/questionnaire/components/printForm/PrintForm";
import EmploymentTypeOptions
  from "@/feature/payroll/employeeOverview/employeeCard/components/cardContent/EmploymentOptions";
import { ModalContext } from "@/components/modal/ModalProvider";

interface MainButtonsProps {
  setNavigationPath: (title: string) => void; //sets the path for handleNavigate in EmployeeCard
  showCreateQuestionnaire: (title: string) => void; //shows the createQuestionnaire state
  selectedEmployee?: Employee;
}

type MenuCard = {
  title: string;
  icon: JSX.Element;
  id: string;
  onClickEvent: () => void;
};

export function MainButtons({
  setNavigationPath,
  selectedEmployee,
}: MainButtonsProps) {
  const { showModal } = useContext(ModalContext);


  const openSendLinkModal = () => {
    showModal({
      title: questionnaireTranslation("createQuestionnaire"),
      description: questionnaireTranslation("selectEmploymentType"),
      className: "w-screen",
      content: ({ close }) => (
        <EmploymentTypeOptions
          selectedEmployee={selectedEmployee}
          onClose={close}
        />
      ),
    });
  };

  const printRef = useRef<HTMLDivElement>(null);
  const printFn = usePrint(printRef);

  const questionnaire: MenuCard[] = [
    {
      id: "questionnaire",
      title: payrollTranslation("dropdownMenu.createQuestionnaire"),
      icon: <FilePlus className="h-4 w-4" />,
      onClickEvent: openSendLinkModal,
    },
    {
      id: "editQuestionnaire",
      title: payrollTranslation("dropdownMenu.editQuestionnaire"),
      icon: <FilePenLine className="h-4 w-4" />,
      onClickEvent: () => setNavigationPath("editQuestionnaire"),
    },
    {
      id: "download",
      title: "Download",
      icon: <Download className="h-4 w-4" />,
      onClickEvent: () => printFn(),
    },
  ];

  const otherDocuments: MenuCard[] = [
    {
      id: "changes",
      title: payrollTranslation("changes"),
      icon: <CalendarCog className="h-4 w-4" />,
      onClickEvent: () => setNavigationPath("changes"),
    },
    {
      id: "absence",
      title: payrollTranslation("absence"),
      icon: <CalendarMinus className="h-4 w-4" />,
      onClickEvent: () => setNavigationPath("absence"),
    },
    {
      id: "termination",
      title: payrollTranslation("termination"),
      icon: <CalendarOff className="h-4 w-4" />,
      onClickEvent: () => setNavigationPath("termination"),
    },
  ];

  return (
    <VerticalFlex className={"w-full"}>
      <Card className={"p-4"}>
        <Title level={4}>{payrollTranslation("questionnaire")}</Title>
        <HorizontalFlex className={"grid grid-cols-3"}>
          {questionnaire.map((item) => (
            <VerticalFlex>
              <div ref={printRef} className={"hidden"}>
                <PrintForm selectedEmployee={selectedEmployee} />
              </div>
              <Button
                key={item.id}
                variant="ghost"
                className="h-fit items-start justify-start text-left text-muted-foreground"
                onClick={item.onClickEvent}
              >
                {item.id === "editQuestionnaire" ? (
                  <Link
                    to={`/payroll/${selectedEmployee?.id}/editQuestionnaire`}
                  >
                    <div className="flex w-full items-start justify-center">
                      <span className={"mr-1"}>{item.icon}</span>
                      <span className="text-sm">{item.title}</span>
                    </div>
                  </Link>
                ) : (
                  <div className="flex w-full items-start justify-center">
                    <span className={"mr-1"}>{item.icon}</span>
                    <span className="text-sm">{item.title}</span>
                  </div>
                )}
              </Button>
            </VerticalFlex>
          ))}
        </HorizontalFlex>
      </Card>

      <Card className={"p-4"}>
        <Title level={4}>{payrollTranslation("additionalDocuments")}</Title>
        <HorizontalFlex className={"grid grid-cols-3 gap-2"}>
          {otherDocuments.map((item) => (
            <Button
              key={item.id}
              variant="ghost"
              className="h-fit items-start justify-start text-left text-muted-foreground"
              onClick={item.onClickEvent}
            >
              <Link to={`/payroll/${selectedEmployee?.id}/${item.id}`}>
                <div className="flex w-full items-start justify-center">
                  <span className={"mr-1"}>{item.icon}</span>
                  <span className="whitespace-normal break-words text-sm">
                    {item.title}
                  </span>
                </div>
              </Link>
            </Button>
          ))}
        </HorizontalFlex>
      </Card>
    </VerticalFlex>
  );
}

export default MainButtons;
