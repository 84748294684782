import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { errorToastMiddleware } from "@/api/error";
import { reduxApi } from "@/api/reduxApi";
import formReducer from "src/feature/payroll/apiSlice/formSelectionSlice";

export const store = configureStore({
  reducer: {
    [reduxApi.reducerPath]: reduxApi.reducer,
    questionnaire: formReducer,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware()
      .concat(reduxApi.middleware)
      .concat(errorToastMiddleware);
  },
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
