export const defaultValuesChanges = {


  generalDetails: {
    personnelNumber:"",
    companyName:"",
    companyAddress: "",
    firstName:"",
    lastName:"",
  },

  changeWeeklyHours: {
    weeklyOld: "",
    weeklyNew: "",
    reason: "",
    averageHours: "",
    validFrom: undefined,
    additionCareTime: {
      reducedWage: "",
      reductionStart: undefined,
      reductionEnd: undefined,
    }
  },

  changeLimit: {
    writtenConclusion: "",
    limitUntil: undefined,
    limitFrom: undefined,
    lastExtension: undefined,
    limitPerspective:"",
  },

  trainingEnd: {
    endOld: undefined,
    endNew: undefined,
  },
}
