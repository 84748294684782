"use client";

import * as React from "react";
import { Sparkles, X } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import { cn } from "@/lib/utils";
import { motion } from "framer-motion";
import Draggable from "react-draggable";
import { ErrorBoundary } from "@/components/error/ErrorBoundary";

interface ChatOverlayProps {
  children: React.ReactNode;
  className?: string;
}

export function ChatOverlay({ children, className }: ChatOverlayProps) {
  const [isOpen, setIsOpen] = React.useState(false);
  const nodeRef = React.useRef(null);

  return (
    <>
      {/* Chat Bubble Button */}
      <Button
        onClick={() => setIsOpen(!isOpen)}
        size="icon"
        className={cn(
          "fixed bottom-4 right-4 z-[60] h-16 w-16 rounded-full border-2 border-blue-500 bg-background text-white shadow-lg hover:bg-neutral-900",
          className,
        )}
      >
        <Sparkles className="h-6 w-6" />
        <span className="sr-only">{isOpen ? "Close Chat" : "Open Chat"}</span>
      </Button>

      {/* Overlay */}
      {isOpen && (
        <div className="pointer-events-none fixed inset-0 z-50">
          <Draggable
            nodeRef={nodeRef}
            handle=".drag-handle"
            defaultPosition={{ x: 0, y: 0 }}
            bounds="parent"
          >
            <div
              ref={nodeRef}
              className="pointer-events-auto absolute bottom-24 right-12"
            >
              <motion.div
                initial={{ opacity: 0, y: 50, scale: 0.95 }}
                animate={{ opacity: 1, y: 0, scale: 1 }}
                exit={{ opacity: 0, y: 50, scale: 0.95 }}
                transition={{ duration: 0.3, ease: "easeOut" }}
                className="z-[60] h-[800px] w-[750px]"
              >
                <Card className="h-full border border-black bg-background p-4 shadow-2xl">
                  <div className="drag-handle mb-4 flex cursor-move items-center justify-between">
                    <h2 className="select-none text-lg font-semibold">
                      Metrics AI Assistant
                    </h2>
                    <Button
                      size="icon"
                      variant="ghost"
                      onClick={() => setIsOpen(false)}
                      aria-label="Close"
                    >
                      <X className="h-6 w-6" />
                    </Button>
                  </div>

                  <div className="h-[calc(100%-60px)] overflow-hidden">
                    {children}
                  </div>
                </Card>
              </motion.div>
            </div>
          </Draggable>
        </div>
      )}
    </>
  );
}
