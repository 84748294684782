import { useChat } from "ai/react";
import { cn } from "@/lib/utils";
import { ChatList } from "./ChatList";
import { ChatPanel } from "./ChatPanel";
import { EmptyScreen } from "./EmptyScreen";
import { ChatScrollAnchor } from "./ChatScrollAnchor";
import { useEffect, useRef, useState } from "react";
import { GLOBAL_API_TOKEN } from "@/api/reduxApi";
import { VerticalFlex } from "@/components/layout/Flex";

// FIXME REWRITE ME
export function Chat({
  id,
  initialMessages,
  className,
  isChatOverlay,
  uiContext, // BiDocument | undefined
}) {
  const [uiContextIsUsed, setUiContextIsUsed] = useState(true);
  useEffect(() => {
    setUiContextIsUsed(true);
  }, [uiContext?.id]);

  const api = process.env.REACT_APP_API_URL + "chatai/chat";
  const { messages, append, reload, stop, isLoading, input, setInput } =
    useChat({
      api,
      initialMessages,
      id,
      headers: {
        Authorization: `Bearer ${GLOBAL_API_TOKEN}`,
      },
      body: {
        id: "useCompanyIdInsteadOfUserId",
        uiContext: JSON.stringify(uiContextIsUsed ? uiContext : undefined), // FIXME: json field in ?
      },
      onResponse(response) {
        if (response.status === 401) {
          console.error(response.statusText);
        }
      },
      onFinish(message) {
        console.log("Message received from server:", message);
      },
      onError(error) {
        console.error("Error in chat:", error);
      },
    });

  useEffect(() => {
    console.log("Updated messages:", messages);
    scrollToBottom();
  }, [messages]);

  const containerRef = useRef(null);

  const scrollToBottom = () => {
    const container = containerRef.current;
    if (container) {
      container.scrollTop = container.scrollHeight;
    }
  };

  return (
    <div className={"relative h-full justify-center"}>
      <VerticalFlex
        ref={containerRef}
        align={"center"}
        style={{
          overflowY: "scroll",
          scrollbarWidth: "thin", // Firefox
          msOverflowStyle: "none", // IE und alte Edge-Versionen
        }}
        className={cn(
          `${!messages.length && "mt-0"} chat-container scrollbar-hidden h-[calc(100vh-300px)] overflow-y-auto pr-4`,
          className,
        )}
      >
        {messages.length ? (
          <>
            <ChatList messages={messages} />
            <ChatScrollAnchor trackVisibility={isLoading} />
          </>
        ) : (
          <EmptyScreen setInput={setInput} />
        )}
      </VerticalFlex>

      <ChatPanel
        id={id}
        isLoading={isLoading}
        stop={stop}
        append={append}
        reload={reload}
        messages={messages}
        input={input}
        setInput={setInput}
        isChatOverlay={isChatOverlay}
        uiContext={uiContextIsUsed ? uiContext : undefined}
        removeContext={() => setUiContextIsUsed(false)}
      />
    </div>
  );
}
