import { FC } from "react";
import { Badge } from "@/components/ui/badge";
import { useTranslation } from "react-i18next";
import { BiDocument } from "@/models/document";
import { BankTransaction } from "@/models/bankTransaction";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { BookX } from "lucide-react";
import { BookingErrorLevel } from "@/models/booking";
import { cn } from "@/lib/utils";
import { useLoggedIn } from "@/feature/auth/ProtectedRoutes";

export const BookingBadge: FC<{
  document?: BiDocument;
  transaction?: BankTransaction;
}> = ({ document, transaction }) => {
  const { t } = useTranslation();
  const { activeCompany } = useLoggedIn();

  const item = document || transaction;
  if (!item) {
    return null;
  }

  // filter for skr
  let bookingErrors = (item.bookingErrors ?? []).filter(
    (b) => b.accountingModel == activeCompany.accounting?.taxSystem,
  );

  const isAutomatic = item.match?.autoMatch;

  // suppress warnings on open transactions.
  let isOpenTransaction = !item.match && transaction;

  let bookingErrorIsErrorLevel = bookingErrors?.filter(
    (b) => b.level == BookingErrorLevel.Error,
  ).length;

  let bookingErrorWarningLevel = bookingErrors?.filter(
    (b) => b.level == BookingErrorLevel.Warn,
  ).length;

  let bookingErrorWarningLevelInfo = bookingErrors?.filter(
    (b) => b.level == BookingErrorLevel.Info,
  ).length;

  return (
    <div className="flex items-center space-x-2">
      {/* Fehler-Badge mit fester Breite */}
      <div className="flex w-[90px] justify-center">
        {!isOpenTransaction && bookingErrors?.length ? (
          <Badge
            className={cn(
              "text-neutral font-thin",
              !bookingErrorWarningLevel &&
                !bookingErrorIsErrorLevel &&
                "opacity-50", //
            )}
            variant={bookingErrorIsErrorLevel ? "red" : "default"}
          >
            <span className={"inline-block w-16 text-center"}>
              {bookingErrorIsErrorLevel
                ? t("common.error", { count: bookingErrorIsErrorLevel })
                : bookingErrorWarningLevel
                  ? t("common.warning", { count: bookingErrorWarningLevel })
                  : t("common.hint", { count: bookingErrorWarningLevelInfo })}
            </span>
          </Badge>
        ) : null}
      </div>

      {/* Status-Badge mit fester Breite */}
      <div className="flex w-[100px] justify-center">
        {!item.match ? (
          <Badge className="text-center">
            {t("model.document.status_open")}
          </Badge>
        ) : item.match.amountOpen === 0 ? (
          <Badge variant="green">{t("model.document.status_booked")}</Badge>
        ) : (
          <Badge variant="yellow">{t("model.document.status_partial")}</Badge>
        )}
      </div>

      {/* Automatische Zuordnung Tooltip mit fixer Position */}
      <div className="flex w-[20px] justify-center">
        {isAutomatic && item.match ? (
          <Tooltip>
            <TooltipTrigger asChild>
              <span className="h-2 w-2 rounded-full bg-muted-foreground shadow-sm shadow-accent"></span>
            </TooltipTrigger>
            <TooltipContent>
              <p>Das wurde automatisch gematcht. Bitte überprüfen.</p>
            </TooltipContent>
          </Tooltip>
        ) : null}
      </div>
    </div>
  );
};
