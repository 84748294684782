import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { Form } from "@/components/ui/form";
import { Button } from "@/components/ui/button";
import { useCompleteQuestionnaireMutation } from "@/api/endpoints/payrollApi";
import { Employee } from "@/feature/payroll/forms/questionnaire/schema/employee/EmployeeSchema";
import { useStepManagement } from "@/feature/payroll/forms/questionnaire/hooks/UseStepManagement";
import { DialogOnSubmit } from "@/feature/payroll/forms/questionnaire/functions/DialogOnSave";

import { FormSchemaEmployer } from "@/feature/payroll/forms/questionnaire/schema/employer/FormSchemaEmployer";
import { defaultValuesEmployer } from "@/feature/payroll/forms/questionnaire/schema/employer/defaultValuesEmployer";
import { HorizontalFlex, VerticalFlex } from "@/components/layout/Flex";
import { StepsQuestionnaireEmployer } from "@/feature/payroll/forms/questionnaire/steps/totalSteps/EmployerSteps";
import { UserTypeEnum } from "@/models/payroll";
import MultiStepCard from "@/feature/payroll/MultiStepGeneral";
import { payrollTranslation } from "@/feature/payroll/components/TranslationConstants";

const EmployerForm: React.FC<{
  userType?: UserTypeEnum;
  selectedEmployee?: Employee;
  setCurrentTitle?: (title: string) => void;
}> = ({ setCurrentTitle, selectedEmployee }) => {
  const form = useForm<z.infer<typeof FormSchemaEmployer>>({
    resolver: zodResolver(FormSchemaEmployer),
    defaultValues: defaultValuesEmployer,
  });
  // manage form steps
  const { currentStep, totalSteps, handleStepChange, handleTotalStepsChange } =
    useStepManagement();

  // save questionnaire
  const [completeQuestionnaire] = useCompleteQuestionnaireMutation();
  const [openDialog, setOpenDialog] = useState(false);

  async function onSubmit(value: z.infer<typeof FormSchemaEmployer>) {
    try {
      const response = await completeQuestionnaire({
        questionnaire: value,
        employeeId: selectedEmployee!.id!,
      }).unwrap();
      console.log("Successfully submitted:", response);

      setOpenDialog(true);
    } catch (error) {
      console.error(
        "Error while submitting the employee questionnaire:",
        error,
      );
    }
  }

  return (
    <VerticalFlex>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <VerticalFlex>
            <DialogOnSubmit
              open={openDialog}
              onClose={() => setOpenDialog(false)}
            />
            {/* steps for employer*/}
            <MultiStepCard<typeof FormSchemaEmployer>
              form={form}
              steps={StepsQuestionnaireEmployer(form, selectedEmployee!)}
              setCurrentTitle={setCurrentTitle}
              onStepChange={handleStepChange}
              onTotalStepsChange={handleTotalStepsChange}
            />
          </VerticalFlex>

          {currentStep === totalSteps - 1 && (
            <HorizontalFlex className={"mb-8 flex justify-center"}>
              <Button type={"submit"}>
                {payrollTranslation("universal.save")}
              </Button>
            </HorizontalFlex>
          )}
        </form>
      </Form>
    </VerticalFlex>
  );
};

export default EmployerForm;
