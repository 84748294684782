import React from "react";
import { useFieldArray } from "react-hook-form";
import { Button } from "@/components/ui/button";
import {
  payrollTranslation,
  questionnaireTranslation,
} from "@/feature/payroll/components/TranslationConstants";
import { FormSimpleText } from "@/components/form/FormSimpleText";
import { Plus, X } from "lucide-react";
import { DateInputField } from "@/feature/payroll/components/formFields/DateInputField";
import { Title } from "@/components/text/Title";
import { Underline } from "@/components/text/Underline";
import { HorizontalFlex, VerticalFlex } from "@/components/layout/Flex";
import { QuestionnairePropsEmployee } from "@/models/payroll";

export function Kids({ form }: QuestionnairePropsEmployee) {
  // manage dynamic fields for employee children
  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: "children",
  });

  return (
    <VerticalFlex>
      <Underline>
        <Title level={4}>
          {questionnaireTranslation("childCard.kidsDataTitle")}
        </Title>
      </Underline>
      <VerticalFlex>
        {fields.map((field, index) => (
          <HorizontalFlex key={field.id} className="flex gap-4">
            <HorizontalFlex className="grid flex-grow gap-4 md:grid-cols-2 lg:grid-cols-3">
              <FormSimpleText
                form={form}
                name={`children.${index}.firstName`}
                label={payrollTranslation("universal.childFirstName")}
              />

              <FormSimpleText
                form={form}
                name={`children.${index}.lastName`}
                label={payrollTranslation("universal.childLastName")}
              />
              <DateInputField
                control={form.control}
                name={`children.${index}.birthDate`}
                labelKey={payrollTranslation("universal.birthDate")}
              />
            </HorizontalFlex>
            <VerticalFlex className="flex items-end justify-end">
              <Button
                type="button"
                variant="secondary"
                size="icon"
                onClick={() => remove(index)}
              >
                <X />
              </Button>
            </VerticalFlex>
          </HorizontalFlex>
        ))}

        <HorizontalFlex className={"flex items-center justify-center"}>
          <Button
            type="button"
            variant={"secondary"}
            size={"icon"}
            onClick={() =>
              append({ firstName: "", lastName: "", birthDate: "" })
            }
          >
            <Plus />
          </Button>
        </HorizontalFlex>
      </VerticalFlex>
    </VerticalFlex>
  );
}
