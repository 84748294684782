import React from "react";
import { t } from "i18next";
import { FormSimpleNumber } from "@/components/form/FormSimpleNumber";
import { FieldPath } from "react-hook-form/dist/types";
import { z } from "zod";
import { FormSchemaEmployer } from "@/feature/payroll/forms/questionnaire/schema/employer/FormSchemaEmployer";
import { HorizontalFlex, VerticalFlex } from "@/components/layout/Flex";
import { QuestionnairePropsEmployer } from "@/models/payroll";
import { Title } from "@/components/text/Title";

const questionnaireTranslation = (key: string) =>
  t(`component.app.payroll.questionnaireForm.${key}`);
const DAYS = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];

export function HourDistribution({ form }: QuestionnairePropsEmployer) {
  return (
    <VerticalFlex>
      <Title level={5} className={"text-muted-foreground"}>
        {questionnaireTranslation("employmentCard.hourDistribution")}
      </Title>
      <HorizontalFlex className="grid w-full grid-cols-7 gap-4">
        {DAYS.map((day) => (
          <HorizontalFlex key={day}>
            <FormSimpleNumber
              itemClassName={"min-w-0"}
              className="col-span-1 min-w-0"
              form={form}
              name={
                `employmentDetails.hourDistribution.${day}` as FieldPath<
                  z.infer<typeof FormSchemaEmployer>
                >
              }
              placeholder={questionnaireTranslation(
                `employmentCard.distributionValues.${day}` as const,
              )}
            />
          </HorizontalFlex>
        ))}
      </HorizontalFlex>
    </VerticalFlex>
  );
}
