export const defaultValuesQuestionnaire = {
  additionalInfos: {
    hasVwl: "",
    hasTaxableEmployment: "",
    hasChildren: "",
    isInConstruction: "",
  },

  personalDetails: {
    birthName: "",
    birthDate: "",
    phone: "",
    address: {
      street: "",
      houseNumber: "",
      city: "",
      postalCode: "",
      country: "",
      addressAdditional: "",
    },
    insuranceNumber: "",
    birthPlace: "",
    birthCountry: "",
    gender: "",
    nationality: "",
    disabled: "",
    employeeNumberSocialFund: "",
    iban: "",
    bic: "",
  },

  children: [
    {
      lastName: "",
      firstName: "",
      birthDate: "",
    },
  ],

  employmentDetails: {
    highestSchoolDegree: "",
    highestProfessionalEducation: "",
    jobTitle: "",
    constructionEmployment: "",
    minorEmployment: "",
    additionalEmployment: "",
    trainingStart: "",
    trainingEnd: "",
    entryDate: "",
    firstEntryDate: "",
    employmentCompany: "",

    jobActivity: "",
    probation: "",
    probationDuration: null,
    weeklyWorkHours: null,
    vacationEntitlement: null,
    contractForm: "",
    hourDistribution: {
      monday: null,
      tuesday: null,
      wednesday: null,
      thursday: null,
      friday: null,
      saturday: null,
      sunday: null,
    },
    costCenter: "",
    departmentNumber: "",
  },

  contractLimitation: {
    limitType: "",
    contractEndDate: "",
    contractSignedDate: "",
  },

  payment: {
    additionalPayments: [
      {
        additionalPaymentName: "",
        additionalPaymentSum: 0,
      },
    ],
    christmasMoney: 0,
    payoutMonthChristmas: "",
    vacationMoney: 0,
    payoutMonthVacation: "",
    description: "",
    sum: 0,
    dateSum: "",
    hourlyWage: 0,
    dateWage: "",
    traineePayment: {
      yearOne: {
        sum: 0,
        dateSum: "",
        hourlyWage:0,
        dateWage: "",
      },
      yearTwo: {
        sum: 0,
        dateSum: "",
        hourlyWage: 0,
        dateWage: "",
      },
      yearThree: {
        sum: 0,
        dateSum: "",
        hourlyWage:  0,
        dateWage: "",
      },
    },
  },

  taxInformation: {
    miniJobberTax: {
      rollOff: "",
      generalize: "",
    },
    taxId: "",
    taxClass: "",
    taxFactor: null,
    childAllowance:  0,
  },

  vwlDetails: [
      {
        receptionThrough: "",
        sumBenefits:  0,
        dateSince: "",
        contractNumber: "",
        iban: "",
        bic: "",
        employerShare:  0,
      },

  ],

  taxableEmployment: {
    previousEmployment: [
      {
        dateFrom: "",
        dateUntil: "",
        typeOccupation: "",
        daysOccupation: null,
      },
    ],
    miniJobberLimit: {
      moneyLimit: "",
    },
  },

  socialSecurity: {
    statutoryHealthInsurance: "",
    currentHealthInsurance: "",
    parentProperty: "",
    miniJobberSecurity: {
      freePensionInsurance: "",
    },
    insuranceKey: "",
    deuevStatus: "",
  },
};
