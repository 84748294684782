import React, { useEffect } from "react";
import { questionnaireTranslation } from "@/feature/payroll/components/TranslationConstants";
import { DateInputField } from "@/feature/payroll/components/formFields/DateInputField";
import { FormSimpleMoney } from "@/components/form/FormSimpleMoney";
import { FormSimpleText } from "@/components/form/FormSimpleText";
import { useFieldArray } from "react-hook-form";
import { Button } from "@/components/ui/button";
import { Plus, X } from "lucide-react";
import { useGetEmployeeDataQuery } from "@/api/endpoints/payrollApi";
import { EmployeeEvent } from "@/feature/payroll/forms/questionnaire/schema/employee/EmployeeSchema";
import { formatIBAN } from "@/util/format";
import { isValidIBAN } from "ibantools";
import { Title } from "@/components/text/Title";
import { HorizontalFlex, VerticalFlex } from "@/components/layout/Flex";
import { Underline } from "@/components/text/Underline";
import {
  QuestionnairePropsEmployee,
  QuestionnairePropsEmployer,
} from "@/models/payroll";

export function BenefitsEmployee({ form }: QuestionnairePropsEmployee) {
  // manage dynamic fields for employee benefits
  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: "vwlDetails",
  });

  // handle IBAN input formatting
  const handleIbanChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ) => {
    const rawValue = e.target.value;
    const formattedValue = formatIBAN(rawValue);
    form.setValue(`vwlDetails.${index}.iban`, formattedValue);
  };

  // validate IBAN values when fields change
  useEffect(() => {
    fields.forEach((_, index) => {
      const iban = form.getValues(`vwlDetails.${index}.iban`);
      if (iban) {
        const ibanWithoutSpaces = iban.replace(/\s+/g, "");
        if (!isValidIBAN(ibanWithoutSpaces)) {
          console.error(`Ungültige IBAN für Index ${index}`);
        }
      }
    });
  }, [fields, form]);

  return (
    <VerticalFlex>
      <Underline>
        <Title level={4}>
          {questionnaireTranslation("benefitsCard.benefitsContractData")}
        </Title>
      </Underline>

      <VerticalFlex>
        {fields.map((field, index) => (
          <HorizontalFlex key={field.id} className="gap-4">
            <HorizontalFlex className="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
              <FormSimpleText
                hoverInfoTitle={questionnaireTranslation(
                  "benefitsCard.receptionThrough",
                )}
                hoverInfoDescription={questionnaireTranslation(
                  "benefitsCard.infoCard.infoInstitution",
                )}
                form={form}
                name={`vwlDetails.${index}.receptionThrough`}
                label={questionnaireTranslation(
                  "benefitsCard.receptionThrough",
                )}
              />
              <FormSimpleMoney
                mode={"euro"}
                form={form}
                name={`vwlDetails.${index}.sumBenefits`}
                label={questionnaireTranslation("benefitsCard.sumBenefits")}
              />
              <DateInputField
                control={form.control}
                name={`vwlDetails.${index}.dateSince`}
                labelKey={questionnaireTranslation("benefitsCard.since")}
              />
              <FormSimpleText
                form={form}
                name={`vwlDetails.${index}.contractNumber`}
                label={questionnaireTranslation("benefitsCard.contractNr")}
              />
              <FormSimpleText
                form={form}
                name={`vwlDetails.${index}.iban`}
                label={questionnaireTranslation("benefitsCard.benefitsIban")}
                placeholder={"DE00 0000 0000 0000 0000 00"}
                onChange={(e) => handleIbanChange(e, index)}
              />
              <FormSimpleText
                form={form}
                name={`vwlDetails.${index}.bic`}
                placeholder={"WPGGATTLXXX"}
                label={questionnaireTranslation("benefitsCard.benefitsBic")}
              />
            </HorizontalFlex>

            <VerticalFlex className="mb-0.5 mt-4 flex items-center justify-center">
              <Button
                type="button"
                variant="secondary"
                size="icon"
                onClick={() => remove(index)}
              >
                <X />
              </Button>
            </VerticalFlex>
          </HorizontalFlex>
        ))}
        <HorizontalFlex className={"flex items-center justify-center"}>
          <Button
            type="button"
            variant="secondary"
            size="icon"
            onClick={() =>
              append({
                receptionThrough: "",
                sumBenefits: null,
                dateSince: "",
                contractNumber: null,
                iban: "",
                bic: "",
              })
            }
          >
            <Plus />
          </Button>
        </HorizontalFlex>
      </VerticalFlex>
    </VerticalFlex>
  );
}

export function BenefitsEmployer({
  form,
  selectedEmployee,
}: QuestionnairePropsEmployer) {
  const { fields } = useFieldArray({
    control: form.control,
    name: "vwlDetails",
  });

  const { data: employeeData } = useGetEmployeeDataQuery(
    { employeeId: selectedEmployee?.id || "" },
    { skip: !selectedEmployee?.id },
  );

  useEffect(() => {
    if (employeeData) {
      const employeeFillData = employeeData?.events?.find(
        (event: EmployeeEvent) => event?.employeeFillQuestionnaireEvent,
      )?.employeeFillQuestionnaireEvent;

      if (employeeFillData?.vwlDetails?.length) {
        form.setValue(
          "vwlDetails",
          employeeFillData.vwlDetails.map((detail: any) => ({
            ...detail,
            employerShare: null,
          })),
        );
      }
    }
  }, [employeeData, form]);

  return (
    <VerticalFlex>
      <Underline>
        <Title level={4}>
          {questionnaireTranslation("benefitsCard.benefitsContractData")}
        </Title>
      </Underline>

      <VerticalFlex>
        {fields.map((field, index) => (
          <HorizontalFlex key={field.id} className="mb-4 grid gap-4 md:grid-cols-2 lg:grid-cols-3">

              <FormSimpleText
                disabled={true}
                form={form}
                name={`vwlDetails.${index}.receptionThrough`}
                label={questionnaireTranslation(
                  "benefitsCard.receptionThrough",
                )}
              />
              <FormSimpleMoney
                mode={"euro"}
                disabled={true}
                form={form}
                name={`vwlDetails.${index}.sumBenefits`}
                label={questionnaireTranslation("benefitsCard.sumBenefits")}
              />
              <DateInputField
                disabled={true}
                control={form.control}
                name={`vwlDetails.${index}.dateSince`}
                labelKey={questionnaireTranslation("benefitsCard.since")}
              />
              <FormSimpleText
                disabled={true}
                form={form}
                name={`vwlDetails.${index}.contractNumber`}
                label={questionnaireTranslation("benefitsCard.contractNr")}
              />
              <FormSimpleText
                disabled={true}
                form={form}
                name={`vwlDetails.${index}.iban`}
                label={questionnaireTranslation("benefitsCard.benefitsIban")}
              />
              <FormSimpleText
                disabled={true}
                form={form}
                name={`vwlDetails.${index}.bic`}
                label={questionnaireTranslation("benefitsCard.benefitsBic")}
              />
              <FormSimpleMoney
                mode={"euro"}
                hoverInfoTitle={questionnaireTranslation(
                  "benefitsCard.employerShare",
                )}
                hoverInfoDescription={questionnaireTranslation(
                  "benefitsCard.infoCard.infoEmployerShare",
                )}
                form={form}
                name={`vwlDetails.${index}.employerShare`}
                label={questionnaireTranslation("benefitsCard.employerShare")}
                labelClassName="text-white"
                itemClassName="mb-2"
              />
          </HorizontalFlex>
        ))}
      </VerticalFlex>
    </VerticalFlex>
  );
}
