import React from "react";
import {
  payrollTranslation,
  questionnaireTranslation,
} from "@/feature/payroll/components/TranslationConstants";
import { QuestionnaireEmployeeType } from "@/feature/payroll/forms/questionnaire/schema/employee/FormSchemaEmployee";
import { Title } from "@/components/text/Title";
import { Underline } from "@/components/text/Underline";
import { HorizontalFlex } from "@/components/layout/Flex";
import useHasInfo from "@/feature/payroll/forms/questionnaire/hooks/UseHasInfo";
import { QuestionnairePropsEmployee, SelectEnum } from "@/models/payroll";
import { Employee } from "@/feature/payroll/forms/questionnaire/schema/employee/EmployeeSchema";
import { DataProps } from "@/feature/payroll/forms/questionnaire/components/employeeData/steps/TaxData";

interface SocialSecurityDataProps extends DataProps {
  socialSecurity: QuestionnaireEmployeeType["socialSecurity"];
}
export const SocialSecurityData: React.FC<SocialSecurityDataProps> = ({
  socialSecurity,
  form,
  selectedEmployee,
}) => {
  const hasInfo = useHasInfo(selectedEmployee);
  const children = form?.watch("additionalInfos.hasChildren");
  const statutoryHealthInsurance = form?.watch(
    "socialSecurity.statutoryHealthInsurance",
  );

  const statutoryHealthInsuranceCombined =
    socialSecurity?.statutoryHealthInsurance || statutoryHealthInsurance;

  return (
    <section className="mb-8 font-light">
      <Underline>
        <Title level={4}>
          {questionnaireTranslation("socialSecurityCard.socialSecurity")}
        </Title>
      </Underline>

      <HorizontalFlex className="grid grid-cols-2 gap-4 md:grid-cols-4">
        {statutoryHealthInsuranceCombined && statutoryHealthInsuranceCombined.length > 0 && (
          <div>
            <span className="col-span-1 font-medium">
              {questionnaireTranslation(
                "socialSecurityCard.statutoryHealthInsurance",
              )}
              :
            </span>
            <span className="col-span-1">
              {socialSecurity?.statutoryHealthInsurance &&
              socialSecurity?.statutoryHealthInsurance?.length > 0
                ? questionnaireTranslation(
                    `socialSecurityCard.insuranceNames.${socialSecurity?.statutoryHealthInsurance}`,
                  )
                : "-"}
            </span>
          </div>
        )}

        <span className="col-span-1 font-medium">
          {" "}
          {questionnaireTranslation(
            "socialSecurityCard.currentHealthInsurance",
          )}
          :
        </span>
        <span className="col-span-1">
          {socialSecurity?.currentHealthInsurance &&
          socialSecurity?.currentHealthInsurance?.length > 0
            ? questionnaireTranslation(
                `socialSecurityCard.insuranceNames.${socialSecurity?.currentHealthInsurance}`,
              )
            : "-"}
        </span>

        {(hasInfo?.hasChildren === SelectEnum.YES ||
          children === SelectEnum.YES) && (
          <div className={"grid grid-cols-2 md:col-span-1 lg:col-span-2"}>
            <span className=" font-medium">
              {questionnaireTranslation("socialSecurityCard.parentProperty")}:
            </span>
            <span className="">
              {socialSecurity?.parentProperty &&
              socialSecurity?.parentProperty?.length > 0
                ? payrollTranslation(
                  `universal.${socialSecurity?.parentProperty}`,
                )
                : "-"}
            </span>
          </div>
        )}
      </HorizontalFlex>
    </section>
  );
};
