import { reduxApi } from "@/api/reduxApi";
import { Category } from "@/models/category";
import { BiDocument, BiDocumentKind } from "@/models/document";

export const categoryApi = reduxApi.injectEndpoints({
  endpoints: (builder) => ({
    // get all categories
    getCategories: builder.query<Category[], void>({
      query: () => ({
        url: "category/",
        method: "GET",
      }),
    }),

    // search category
    searchCategories: builder.query<
      {
        results?: { score: number; category: Category }[];
      },
      {
        document: BiDocument;
        source?: string;
        search: string;
        kind?: BiDocumentKind;
      }
    >({
      query: (args) => ({
        url: `category/document/${args.document.id}/search`,
        method: "POST",
        body: {
          source: args.source,
          search: args.search,
          kind: args.kind,
        },
      }),
    }),
  }),
});
