import React from "react";
import { questionnaireTranslation } from "@/feature/payroll/components/TranslationConstants";
import { getField } from "@/feature/payroll/forms/questionnaire/components/printForm/functions/GetFieldFunction";
import { FormData } from "@/feature/payroll/forms/questionnaire/components/printForm/components/PrintGeneralDetails";
import { useEmploymentType } from "@/feature/payroll/forms/questionnaire/hooks/UseEmploymentType";
import { Employee } from "@/feature/payroll/forms/questionnaire/schema/employee/EmployeeSchema";
import { EmploymentTypeEnum } from "@/models/payroll";

export const PrintTaxable: React.FC<{
  formData: FormData;
  selectedEmployee?: Employee;
}> = ({ formData, selectedEmployee }) => {
  const taxableEmployment = getField("taxableEmployment", formData);
  const previousEmployment = Array.isArray(
    getField("taxableEmployment.previousEmployment", formData),
  )
    ? getField("taxableEmployment.previousEmployment", formData)
    : [];

  const { employmentType } = useEmploymentType(selectedEmployee!);

  console.log(previousEmployment);
  return (
    <div className={"page-break"} style={{ paddingTop: "10px" }}>
      {previousEmployment.length > 0 && (
        <div>
          <h2 style={{ borderBottom: "2px solid gray" }}>
            {questionnaireTranslation("taxableCard.taxableEmployment")}
          </h2>

          <div className="grid-container-three" style={{ marginBottom: "3px" }}>
            <span className="title-fill">
              {questionnaireTranslation("taxableCard.fromUntil")}
            </span>
            <span className="title-fill">
              {questionnaireTranslation("taxableCard.daysOccupation")}
            </span>
            <span className="title-fill">
              {questionnaireTranslation("taxableCard.typeOccupation")}
            </span>
          </div>
          {previousEmployment.map(
            (
              tax: {
                dateFrom?: string;
                dateUntil?: string;
                daysOccupation?: number;
                typeOccupation?: string;
              },
              index: number,
            ) => (
              <div
                className="grid-container-three"
                key={index}
                style={{ marginBottom: "3px" }}
              >
                <span className="w-1/4 p-1 align-top">
                  {`${tax.dateFrom} - ${tax.dateUntil}`}
                </span>
                <span className="w-1/4 p-1 align-top">
                  {tax.daysOccupation}
                </span>
                <span>{tax.typeOccupation}</span>
              </div>
            ),
          )}
          {employmentType === EmploymentTypeEnum.MINIJOB && (
            <div style={{ marginBottom: "3px" }}>
              <span style={{ border: "none" }}>
                {taxableEmployment?.miniJobberLimit?.moneyLimit}
              </span>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
