import { HorizontalFlex, VerticalFlex } from "@/components/layout/Flex";
import { FormLabel, useFormField } from "@/components/ui/form";
import { Button } from "@/components/ui/button";
import { MinusIcon, PlusIcon } from "lucide-react";
import { FormSimpleText } from "@/components/form/FormSimpleText";
import { EditCategorySelection } from "@/feature/documents/components/Card/CardBody/EditForm/EditCategorySelection";
import { FormSimpleNumber } from "@/components/form/FormSimpleNumber";
import { FormSimpleMoney } from "@/components/form/FormSimpleMoney";
import React, { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { UseFormReturn, useWatch } from "react-hook-form";
import { z } from "zod";
import { biDocumentSchema } from "@/models/EditDocumentModel";
import { BiDocument, DocumentPositionAdditionalInfo } from "@/models/document";
import { BiSelect } from "@/components/input/Select";
import { categoryApi } from "@/api/endpoints/categoryApi";

export const EditInvoicePosition: React.FC<{
  index: number;
  onRemove: () => void;
  onAdd: () => void;
  form: UseFormReturn<z.infer<typeof biDocumentSchema>>;
  document: BiDocument;
}> = ({ index, onRemove, onAdd, form, document }) => {
  const { t } = useTranslation();
  const tax = useWatch({ name: `invoicePositions.${index}.vatPercentage` });
  const showAdditionalInfo = !parseFloat(tax);

  const { data: categories } = categoryApi.useGetCategoriesQuery();

  const onCategoryChanged = (key: string) => {
    let cat = categories?.find((e) => e.key === key);
    let positionDefaults = cat?.attributes?.ui?.positionDefaults;
    if (positionDefaults) {
      for (let [objectKey, objectValue] of Object.entries(positionDefaults)) {
        form.setValue(
          `invoicePositions.${index}.${objectKey}` as any,
          objectValue,
        );
      }
    }
  };

  useEffect(() => {
    if (!showAdditionalInfo) {
      // reset field if not seen
      form.setValue(`invoicePositions.${index}.additionalInfo`, {});
    }
  }, [showAdditionalInfo]);

  return (
    <VerticalFlex
      className={"rounded bg-muted/25 p-4 shadow"}
      key={"invoicePos" + index}
    >
      <FormLabel>
        <HorizontalFlex gap={8} align={"center"} justify={"between"}>
          <span>
            {t("model.document.invoicePositions.self_one")} #{index + 1}
          </span>
          {index > 0 && (
            <Button variant={"ghost"} onClick={onRemove} type={"button"}>
              <MinusIcon size={16} />
            </Button>
          )}
          {index == 0 && (
            <Button type={"button"} variant={"ghost"} onClick={onAdd}>
              <PlusIcon size={16} />
            </Button>
          )}
        </HorizontalFlex>
      </FormLabel>
      <HorizontalFlex>
        <FormSimpleText
          placeholder={t("model.document.invoicePositions.label")}
          itemClassName={"flex-1"}
          form={form}
          name={`invoicePositions.${index}.label`}
        />
      </HorizontalFlex>
      <HorizontalFlex>
        <EditCategorySelection
          className={"flex-1"}
          form={form}
          document={document}
          name={`invoicePositions.${index}.category`}
          labelName={`invoicePositions.${index}.label`}
          edit
          onSelectHook={onCategoryChanged}
        />
      </HorizontalFlex>
      <HorizontalFlex gap={8}>
        <FormSimpleNumber
          itemClassName={"flex-1"}
          form={form}
          name={`invoicePositions.${index}.vatPercentage`}
          prefix={() => (
            <span className={"pt-0.5 text-muted-foreground"}>
              {t("common.taxAmount")}
            </span>
          )}
          postfix={() => <span className={"ml-3 pt-0.5"}>{"%"}</span>}
        />
        <FormSimpleMoney
          prefix={() => (
            <span className={"pt-0.5 text-muted-foreground"}>
              {t("common.grossAmount")}
            </span>
          )}
          itemClassName={"flex-1"}
          form={form}
          name={`invoicePositions.${index}.total`}
          className={"font-semibold"}
        />
      </HorizontalFlex>
      {showAdditionalInfo && (
        <HorizontalFlex>
          <AdditionalInfoSelect
            name={`invoicePositions.${index}.additionalInfo`}
            document={document}
            form={form}
          />
        </HorizontalFlex>
      )}
    </VerticalFlex>
  );
};

const AdditionalInfoSelect: FC<{
  form: UseFormReturn<z.infer<typeof biDocumentSchema>>;
  document: BiDocument;
  name: string;
}> = ({ form, document, name }) => {
  const { t } = useTranslation();

  let addInfo: DocumentPositionAdditionalInfo | null = useWatch({
    name: name,
  });
  console.log("xx", addInfo);
  let keys = ["reverseCharge", "freeOfVat"];
  let selected = keys.find((k) => ((addInfo as any) || {})[k]);

  return (
    <BiSelect
      placeholder={t("model.document.invoicePositions.additionalInfo.self")}
      onChange={(v) => {
        form.setValue(name as any, v ? { [v]: true } : {});
      }}
      value={selected}
      options={keys.map((k) => ({
        label: t(`model.document.invoicePositions.additionalInfo.${k}`),
        description: t(
          `model.document.invoicePositions.additionalInfo.${k}_description`,
        ),
        value: k,
      }))}
    />
  );
};
