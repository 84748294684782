import SideNavbar, { SidebarItem } from "./components/SideNavbar";
import HeaderNavbar from "./components/HeaderNavbar";
import { Outlet, useLocation } from "react-router-dom";
import { useErrorToastMiddleware } from "@/api/error";
import { ErrorBoundary } from "@/components/error/ErrorBoundary";
import { HorizontalFlex, VerticalFlex } from "@/components/layout/Flex";
import { Toaster } from "@/components/ui/toaster";
import { UploadContextProvider } from "../upload/UploadContextProvider";
import { ModalProvider } from "@/components/modal/ModalProvider";
import React from "react";
import MetricsAI from "@/feature/metricsai/MetricsAiContent";
import { ChatOverlay } from "@/feature/metricsai/ChatOverlay";
import { MetricsAiContextProvider } from "@/feature/metricsai/MetricsAiContext";

type DefaultLayoutProps = {
  sidebar: SidebarItem[];
};

const DefaultLayout = (props: DefaultLayoutProps) => {
  useErrorToastMiddleware();
  const location = useLocation();

  return (
    <UploadContextProvider>
      <MetricsAiContextProvider>
        <HorizontalFlex className={"flex-1 gap-x-0"}>
          <SideNavbar sidebar={props.sidebar} />
          <VerticalFlex className={"flex-1"} gap={2}>
            <HeaderNavbar />
            <VerticalFlex
              className={"flex-1 p-8 transition-all min-[1900px]:px-16"}
            >
              <ErrorBoundary key={`error-boundary-${location.pathname}`}>
                <ModalProvider>
                  <Outlet />
                </ModalProvider>
                <Toaster />
              </ErrorBoundary>
              <ErrorBoundary errorDisplay={<div />}>
                <ChatOverlay>
                  <MetricsAI isChatOverlay={true} />
                </ChatOverlay>
              </ErrorBoundary>
            </VerticalFlex>
          </VerticalFlex>
        </HorizontalFlex>
      </MetricsAiContextProvider>
    </UploadContextProvider>
  );
};

export default DefaultLayout;
