import { EmployeeEvent } from "@/feature/payroll/forms/questionnaire/schema/employee/EmployeeSchema";
import { useEffect, useState } from "react";
import { Circle } from "lucide-react";
import { payrollTranslation } from "@/feature/payroll/components/TranslationConstants";
import { HorizontalFlex } from "@/components/layout/Flex";

enum StatusEnum {
  OPEN = "open",
  SENT = "sent",
  AVAILABLE = "available",
  COMPLETE = "complete",
}

const statusMapping = {
  [StatusEnum.OPEN]: {
    color: "#ff2216",
    label: payrollTranslation("statusDisplay.open"),
  },
  [StatusEnum.SENT]: {
    color: "#fdab00",
    label: payrollTranslation("statusDisplay.sent"),
  },
  [StatusEnum.AVAILABLE]: {
    color: "#00cefd",
    label: payrollTranslation("statusDisplay.available"),
  },
  [StatusEnum.COMPLETE]: {
    color: "#2fc300",
    label: payrollTranslation("statusDisplay.complete"),
  },
};

export const StatusChange = ({ events }: { events?: EmployeeEvent[] }) => {
  const [employeeStatus, setEmployeeStatus] = useState<StatusEnum>(
    StatusEnum.OPEN,
  );

  // update employee status based on the events
  useEffect(() => {
    const employeeFillQuestionnaireEvent = events?.find(
      (event) => event?.employeeFillQuestionnaireEvent,
    );
    const sendQuestionnaireEvent = events?.find(
      (event) => event?.sendQuestionnaireEvent,
    );
    const employerCompleteQuestionnaireEvent = events?.find(
      (event) => event?.employerCompleteQuestionnaireEvent,
    );

    if (employerCompleteQuestionnaireEvent) {
      setEmployeeStatus(StatusEnum.COMPLETE);
    } else if (employeeFillQuestionnaireEvent) {
      setEmployeeStatus(StatusEnum.AVAILABLE);
    } else if (sendQuestionnaireEvent) {
      setEmployeeStatus(StatusEnum.SENT);
    } else {
      setEmployeeStatus(StatusEnum.OPEN);
    }
  }, [events]);

  // get color and label from the status mapping according to the current status
  const { color, label } = statusMapping[employeeStatus];

  return (
    <HorizontalFlex className="flex items-center gap-2">
      <span>{label}</span>
      <Circle
        color={color}
        fill={color}
        size={16}
        style={{ opacity: 0.8 }}
        className="flex justify-self-end"
      />
    </HorizontalFlex>
  );
};
