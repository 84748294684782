import React, { useMemo, useState } from "react";
import { Employee } from "@/feature/payroll/forms/questionnaire/schema/employee/EmployeeSchema";
import { Card } from "@/components/ui/card";
import { payrollTranslation } from "@/feature/payroll/components/TranslationConstants";
import EventName from "@/feature/payroll/forms/questionnaire/components/printForm/functions/getEventName";
import { HorizontalFlex, VerticalFlex } from "@/components/layout/Flex";
import { Title } from "@/components/text/Title";
import {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "@/components/ui/pagination";
import { Button } from "@/components/ui/button"; // Importiere die ShadCN Pagination-Komponenten

interface TimelineProps {
  selectedEmployee?: Employee;
}

const Timeline = ({ selectedEmployee }: TimelineProps) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [isNewestFirst, setIsNewestFirst] = useState(false);
  const eventsPerPage = 5;

  // Sort Events by newest / oldest
  const sortedEvents = useMemo(() => {
    const events = [...(selectedEmployee?.events || [])];
    events.sort((a, b) => {
      const aTime = new Date(a.createdAt!).getTime();
      const bTime = new Date(b.createdAt!).getTime();

      if (isNewestFirst) {
        return aTime - bTime;
      } else {
        return bTime - aTime;
      }
    });
    return events;
  }, [selectedEmployee?.events, isNewestFirst]);

  // Calculate results for current page
  const indexOfLastEvent = currentPage * eventsPerPage;
  const indexOfFirstEvent = indexOfLastEvent - eventsPerPage;
  const currentEvents = sortedEvents.slice(indexOfFirstEvent, indexOfLastEvent);

  // Calculate total amount of all pages
  const totalPages = Math.ceil(sortedEvents.length / eventsPerPage);

  // change page
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  // change sorting order
  const toggleSortOrder = () => {
    setIsNewestFirst((prev) => !prev);
  };

  return (
    <VerticalFlex className={"m-4 w-full"}>
      <HorizontalFlex className={"flex justify-between"}>
        <Title level={3}>
          {payrollTranslation("timelineComponent.timeline")}
        </Title>
        <Button
          className={"h-4 p-3 text-muted-foreground"}
          onClick={toggleSortOrder}
          variant={"secondary"}
          type={"button"}
        >
          {isNewestFirst
            ? payrollTranslation("timelineComponent.latestFirst")
            : payrollTranslation("timelineComponent.oldestFirst")}
        </Button>
      </HorizontalFlex>

      <VerticalFlex className="relative">
        <ul className="relative">
          <VerticalFlex className="absolute h-full w-px bg-gray-400" />
          {currentEvents.map((event) => (
            <VerticalFlex key={event.id} className="relative mb-8 pl-8">
              <VerticalFlex
                className={`absolute left-0 top-6 h-4 w-4 -translate-x-1/2 rounded-full border border-white bg-[#1b1b1b] shadow-md`}
              />
              <Card className="gap-4 border p-2 transition-shadow hover:shadow-xl">
                <HorizontalFlex className="mb-1 flex w-full items-center justify-between text-sm font-semibold opacity-50">
                  <VerticalFlex>
                    {event.createdAt &&
                      new Date(event.createdAt).toLocaleDateString("de-DE", {
                        weekday: "short",
                        day: "2-digit",
                        month: "2-digit",
                        year: "2-digit",
                      })}
                  </VerticalFlex>
                  <VerticalFlex>
                    {event.createdAt &&
                      new Date(event.createdAt).toLocaleTimeString("de-DE", {
                        hour: "2-digit",
                        minute: "2-digit",
                      })}
                  </VerticalFlex>
                </HorizontalFlex>
                <EventName event={event} />
              </Card>
            </VerticalFlex>
          ))}
        </ul>

        <Pagination>
          <PaginationPrevious
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          />
          <PaginationContent>
            {[...Array(totalPages)].map((_, index) => {
              const page = index + 1;
              return (
                <PaginationItem key={page}>
                  <PaginationLink
                    isActive={page === currentPage}
                    onClick={() => handlePageChange(page)}
                  >
                    {page}
                  </PaginationLink>
                </PaginationItem>
              );
            })}
            {totalPages > 5 && currentPage < totalPages - 1 && (
              <PaginationItem>
                <PaginationEllipsis />
              </PaginationItem>
            )}
          </PaginationContent>
          <PaginationNext
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          />
        </Pagination>
      </VerticalFlex>
    </VerticalFlex>
  );
};

export default Timeline;
