import { z } from "zod";
import { payrollTranslation } from "@/feature/payroll/components/TranslationConstants";
import { SelectEnum } from "@/models/payroll";
import { translation } from "@/i18n/translation";

export const errorRequired = payrollTranslation("universal.errorRequired");
export const errorTaxId = payrollTranslation("universal.errorTaxId");
export const errorIban = payrollTranslation("universal.errorIban");
export const errorBic = payrollTranslation("universal.errorBic");
export const errorInsuranceNumber = payrollTranslation(
  "universal.errorInsuranceNumber",
);

export const FormSchemaEmployee = z.object({
  additionalInfos: z.object({
    hasVwl: z.nativeEnum(SelectEnum),
    hasTaxableEmployment:  z.nativeEnum(SelectEnum),
    hasChildren:  z.nativeEnum(SelectEnum),
    isInConstruction:  z.nativeEnum(SelectEnum),
  }),

  personalDetails: z.object({
    birthName: z.string().optional().nullable(),
    birthDate: z.string(),
    phone: z.string().optional(),
    address: z.object({
      street: z.string().min(1, {
        message: errorRequired,
      }),
      houseNumber: z.string().min(1, {
        message: errorRequired,
      }),
      city: z.string().min(1, {
        message: errorRequired,
      }),
      postalCode: z.string().min(1, {
        message: errorRequired,
      }),
      country: z.string().optional().nullable(),
      addressAdditional: z.string().optional().nullable(),
    }),
    insuranceNumber: z
      .string()
      .optional()
      .nullable()
      .refine((value) => !value || value.length === 12, {
        message: errorInsuranceNumber,
      }),
    birthPlace: z.string().optional().nullable(),
    birthCountry: z.string().optional().nullable(),
    gender: z.string().optional().nullable(),
    nationality: z.string().optional().nullable(),
    employeeNumberSocialFund: z.string().optional().nullable(),
    iban: z
      .string()
      .regex(
        /^$|^[a-zA-Z]{2}[0-9]{2}\s?[a-zA-Z0-9]{4}\s?[0-9]{4}\s?[0-9]{3}([a-zA-Z0-9]\s?[a-zA-Z0-9]{0,4}\s?[a-zA-Z0-9]{0,4}\s?[a-zA-Z0-9]{0,4}\s?[a-zA-Z0-9]{0,3})?$/gm,
        { message: translation.t("zod:errors.invalidIban") },
      )
      .optional(),

    bic: z
      .string()
      .optional()
      .nullable()
      .refine((value) => !value || (value.length >= 8 && value.length <= 11), {
        message: errorBic,
      }),

    disabled: z.string().optional().nullable(),
  }),

  children: z.array(
    z.object({
      lastName: z.string().optional().nullable(),
      firstName: z.string().optional().nullable(),
      birthDate: z.string().optional().nullable(),
    }),
  ),

  employmentDetails: z.object({
    jobTitle: z.string().optional().nullable(),
    highestSchoolDegree: z.string().optional().nullable(),
    highestProfessionalEducation: z.string().optional().nullable(),
    constructionEmployment: z.string().optional().nullable(),
    minorEmployment: z.string().optional().nullable(),
    additionalEmployment: z.string().optional().nullable(),
  }),

  taxInformation: z.object({
    identificationEmployer: z.string().optional().nullable(),
    taxId: z
      .string()
      .optional()
      .nullable()
      .refine((value) => !value || value.length === 11, {
        message: errorTaxId,
      }),
    taxClass: z.string().optional().nullable(),
    taxFactor: z.number().optional().nullable(),
    childAllowance: z.number().optional().nullable(),
  }),

  socialSecurity: z.object({
    statutoryHealthInsurance: z.string().optional().nullable(),
    currentHealthInsurance: z.string().optional().nullable(),
    parentProperty: z.string().optional().nullable(),
    miniJobberSecurity: z.object({
      freePensionInsurance: z.string().optional().nullable(),
    }),
  }),

  vwlDetails: z.array(
    z.object({
      receptionThrough: z.string().optional().nullable(),
      sumBenefits: z.number().optional().nullable(),
      dateSince: z.string().optional().nullable(),
      contractNumber: z.string().optional().nullable(),
      iban: z
        .string()
        .regex(
          /^$|^[a-zA-Z]{2}[0-9]{2}\s?[a-zA-Z0-9]{4}\s?[0-9]{4}\s?[0-9]{3}([a-zA-Z0-9]\s?[a-zA-Z0-9]{0,4}\s?[a-zA-Z0-9]{0,4}\s?[a-zA-Z0-9]{0,4}\s?[a-zA-Z0-9]{0,3})?$/gm,
          { message: translation.t("zod:errors.invalidIban") },
        )
        .optional(),

      bic: z
        .string()
        .optional()
        .nullable()
        .refine(
          (value) => !value || (value.length >= 8 && value.length <= 11),
          { message: errorBic },
        ),
    }),
  ),

  taxableEmployment: z.object({
    previousEmployment: z.array(
      z.object({
        dateFrom: z.string().optional().nullable(),
        dateUntil: z.string().optional().nullable(),
        typeOccupation: z.string().optional().nullable(),
        daysOccupation: z.number().optional().nullable(),
      }),
    ),
    miniJobberLimit: z.object({
      moneyLimit: z.string().optional().nullable(),
    }),
  }),
});

export type QuestionnaireEmployeeType = z.infer<typeof FormSchemaEmployee>;
