import React, { useState } from "react";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Button } from "@/components/ui/button";
import {
  payrollTranslation,
  questionnaireTranslation,
} from "@/feature/payroll/components/TranslationConstants";
import { VerticalFlex } from "@/components/layout/Flex";
import {
  EmailSchema,
  Employee,
} from "@/feature/payroll/forms/questionnaire/schema/employee/EmployeeSchema";
import { useSendQuestionnaireLinkMutation } from "@/api/endpoints/payrollApi";
import { z } from "zod";
import { FormSchemaEmployee } from "@/feature/payroll/forms/questionnaire/schema/employee/FormSchemaEmployee";

interface EmploymentTypeOptionsProps {
  onClose: () => void;
  selectedEmployee?: Employee;
}

const EmploymentTypeOptions: React.FC<EmploymentTypeOptionsProps> = ({
  onClose,
  selectedEmployee,
}) => {
  const [employmentType, setEmploymentType] = useState<string>("");

  const [sendQuestionnaireLink] = useSendQuestionnaireLinkMutation();

  // send the link to the employees email address
  const handleSendLink = async () => {
    if (selectedEmployee) {
      try {
        const result = await sendQuestionnaireLink({
          employee: {
            ...selectedEmployee,
          },
          employmentType: employmentType,
        }).unwrap();
        onClose();
        console.log("Erstellt:", result);
      } catch (error) {
        console.error("Fehler beim Senden des Links:", error);
      }
    }
  };
  // update the employmentType based on selected value
  const handleEmploymentTypeChange = (value: string) => {
    setEmploymentType(value);
  };
  return (
    <VerticalFlex className="grid grid-cols-2 gap-4 pt-4">
      <Select onValueChange={handleEmploymentTypeChange} value={employmentType}>
        <SelectTrigger>
          <SelectValue
            placeholder={payrollTranslation("universal.choseOption")}
          />
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            <SelectItem value="normalEmployee">
              {questionnaireTranslation("normalEmployee")}
            </SelectItem>
            <SelectItem value="trainee">
              {questionnaireTranslation("trainee")}
            </SelectItem>
            <SelectItem value="miniJob">
              {questionnaireTranslation("miniJob")}
            </SelectItem>
          </SelectGroup>
        </SelectContent>
      </Select>

      <Button type="button" onClick={handleSendLink}>
        {questionnaireTranslation("sendQuestionnaire")}
      </Button>
    </VerticalFlex>
  );
};

export default EmploymentTypeOptions;
