import React, { useEffect, useState } from "react";
import { BiDocument } from "@/models/document";

export interface MetricsAiContext {
  activeDocuments: BiDocument[];
}

type MetricsAiContextType = {
  contexts: MetricsAiContext[];
  registerContext: (ctx: MetricsAiContext) => () => void;
};

export const MetricsAiContext = React.createContext<MetricsAiContextType>({
  contexts: [],
  registerContext: (ctx: MetricsAiContext) => {
    throw new Error("missing provider");
  },
});

export const MetricsAiContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  let [contexts, setContexts] = useState<MetricsAiContext[]>([]);

  let registerContext = (ctx: MetricsAiContext) => {
    setContexts((c) => [...c, ctx]);
    return () => {
      setContexts((c) => c.filter((v) => v !== ctx));
    };
  };

  return (
    <MetricsAiContext.Provider
      value={{
        registerContext,
        contexts,
      }}
    >
      {children}
    </MetricsAiContext.Provider>
  );
};

export const useProvideMetricsAiContext = (ctx?: MetricsAiContext) => {
  const p = React.useContext(MetricsAiContext);

  useEffect(() => {
    if (ctx) {
      // return cleanup function
      return p.registerContext(ctx);
    }
  }, [JSON.stringify(ctx)]);
};

export const useGetMetricsAiContext = (ctx?: MetricsAiContext) => {
  const p = React.useContext(MetricsAiContext);

  // consolidate
  return {
    activeDocuments: p.contexts.flatMap((i) => i.activeDocuments),
  };
};
