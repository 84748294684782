import { BankTransaction } from "@/models/bankTransaction";
import { useGetGoCardlessBankInstitutionQuery } from "@/api/endpoints/bankApi";
import { Card } from "@/components/ui/card";
import React from "react";
import {
  FlexOne,
  HorizontalFlex,
  VerticalFlex,
} from "@/components/layout/Flex";
import { Button } from "@/components/ui/button";
import { X } from "lucide-react";
import { formatIBAN, formatMoney, formatSimpleDate } from "@/util/format";
import { Skeleton } from "@/components/ui/skeleton";
import { TransactionDocumentTabs } from "@/feature/transactions/components/TransactionDocumentTabs";
import { Separator } from "@/components/ui/separator";
import { BookingBadge } from "@/feature/documents/components/BookingBadge";
import { TransactionDocumentEmptyCard } from "@/feature/transactions/components/TransactionDocumentEmptyCard";
import { BookingErrorDisplay } from "@/feature/accounting/components/BookingErrorDisplay";
import { BookingDisplay } from "@/feature/accounting/components/BookingDisplay";
import { Text } from "@/components/text/Text";
import { useTranslation } from "react-i18next";

interface TransactionCardProps {
  transaction: BankTransaction;
  onClose: () => void;
}

export const TransactionCard: React.FC<TransactionCardProps> = ({
  transaction,
  onClose,
}) => {
  const { t } = useTranslation();

  const { data: institution, isLoading } = useGetGoCardlessBankInstitutionQuery(
    {
      id: transaction?.bankAccount?.goCardlessInstitutionId || "",
    },
    {
      skip: !transaction,
    },
  );

  return (
    <VerticalFlex className={"sticky top-24 max-h-[calc(100vh-250px)] w-full"}>
      <Card className="flex h-full w-full max-w-xl flex-col divide-y border-none shadow shadow-background">
        <VerticalFlex gap={8} className={"overflow-hidden"}>
          <HorizontalFlex className={"p-6 pb-0"}>
            {isLoading && (
              <HorizontalFlex align={"center"}>
                <Skeleton className="h-8 w-8 rounded-full" />
                <VerticalFlex gap={2}>
                  <Skeleton className="h-2 w-[150px]" />
                  <Skeleton className="h-2 w-[125px]" />
                </VerticalFlex>
              </HorizontalFlex>
            )}
            {!isLoading && (
              <HorizontalFlex align="center">
                <img
                  className={"h-8 w-8 overflow-hidden rounded-full text-sm"}
                  src={institution?.logo}
                  alt="Bank-logo"
                />
                <VerticalFlex gap={0}>
                  <span className={"text-sm"}>{institution?.name}</span>
                  <span className={"text-sm text-foreground/50"}>
                    {transaction?.accountIBAN
                      ? formatIBAN(transaction.accountIBAN)
                      : "IBAN"}
                  </span>
                </VerticalFlex>
              </HorizontalFlex>
            )}
            <FlexOne />
            <Button
              size={"icon"}
              onClick={onClose}
              variant={"ghost"}
              className={"rounded-xl"}
            >
              <X size={18} />
            </Button>
          </HorizontalFlex>
          <VerticalFlex
            className={"flex-1 overflow-y-auto overflow-x-hidden p-6 pt-0"}
          >
            <HorizontalFlex align={"center"}>
              <span className={"text-2xl font-bold"}>
                {transaction?.transactionAmount
                  ? formatMoney(transaction.transactionAmount)
                  : "BETRAG"}
              </span>
              <FlexOne />
              <BookingBadge transaction={transaction} />
            </HorizontalFlex>
            <HorizontalFlex align={"center"}>
              <VerticalFlex gap={0} className={"text-lg font-semibold"}>
                <span>
                  {transaction?.otherPartyName
                    ? transaction.otherPartyName
                    : "KREDITOR/DEBITOR"}
                </span>
                <span className={"text-sm text-foreground/50"}>
                  {transaction?.otherPartyIBAN
                    ? formatIBAN(transaction.otherPartyIBAN)
                    : "IBAN"}
                </span>
              </VerticalFlex>
              <FlexOne />
              <span className={"text-sm text-foreground/50"}>
                {transaction?.valueDate
                  ? formatSimpleDate(transaction.valueDate)
                  : "Rechnungsdatum"}
              </span>
            </HorizontalFlex>
            <Separator />
            <span className={"text-sm text-foreground/50"}>
              {transaction?.remittanceInformation}
            </span>

            <BookingErrorDisplay errors={transaction.bookingErrors} />
            <BookingDisplay bookings={transaction.bookings} />

            <Text>{t("common.matching")}</Text>

            {transaction.match && (
              <TransactionDocumentTabs transaction={transaction} />
            )}
            {!transaction.match && (
              <TransactionDocumentEmptyCard transaction={transaction} />
            )}
          </VerticalFlex>
        </VerticalFlex>
      </Card>
    </VerticalFlex>
  );
};
