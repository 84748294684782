export function triggerDownload(url: string, filename?: string): void {
  const anchor = document.createElement("a");
  anchor.href = url;

  if (filename) {
    anchor.download = filename;
  } else {
    anchor.download = "";
  }
  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);
}
