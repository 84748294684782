import React, { useContext, useState } from "react";
import { Card, CardContent, CardHeader } from "@/components/ui/card";
import { cn } from "@/lib/utils";
import { HorizontalFlex, VerticalFlex } from "@/components/layout/Flex";
import HorizontalGradientLine from "@/components/layout/HorizontalGradientLine";
import { Employee } from "@/feature/payroll/forms/questionnaire/schema/employee/EmployeeSchema";
import { Button } from "@/components/ui/button";
import {
  payrollTranslation,
  questionnaireTranslation,
} from "@/feature/payroll/components/TranslationConstants";
import { PencilIcon, X } from "lucide-react";
import { useSendQuestionnaireLinkMutation } from "@/api/endpoints/payrollApi";
import Timeline from "@/feature/payroll/employeeOverview/employeeCard/components/cardContent/Timeline";
import MainButtons from "@/feature/payroll/employeeOverview/employeeCard/components/cardContent/NavigationMenu";
import EmploymentTypeOptions from "@/feature/payroll/employeeOverview/employeeCard/components/cardContent/EmploymentOptions";
import { useNavigate } from "react-router-dom";
import EmployeeDetail from "@/feature/payroll/forms/questionnaire/components/EmployeeDetails";
import { Title } from "@/components/text/Title";
import { EmploymentTypeEnum } from "@/models/payroll";
import { ModalContext } from "@/components/modal/ModalProvider";
import { UpdateEmployee } from "@/feature/payroll/employeeOverview/employeeCard/components/UpdateEmployee";
import CloseButton from "@/feature/documents/components/Card/CardHeader/CloseButton";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";

export interface EmployeeCardProps {
  selectedEmployee?: Employee;
  onClose: () => void;
}

const EmployeeCard: React.FC<EmployeeCardProps> = ({
  selectedEmployee,

  onClose,
}) => {

  const [showEmploymentTypeOptions, setShowEmploymentTypeOptions] =
    useState(false);
  const [showMainButtons, setShowMainButtons] = useState(true);

  const [title, setTitle] = useState<string>("");



  const { showModal } = useContext(ModalContext);

  //open the modal to update the employee
  const openUpdateEmployeeModal = () => {
    showModal({
      title: questionnaireTranslation("employeeOverviewCard.updateEmployee"),
      className: "w-screen",
      content: ({ close }) => (
        <UpdateEmployee selectedEmployee={selectedEmployee} onClose={close} />
      ),
    });
  };


  //shows EmploymentTypeOptions and sendLinkButton
  const handleShowCreateQuestionnaire = (title: string) => {
    setShowMainButtons(false);
    setShowEmploymentTypeOptions(true);
    setTitle(title);
  };

  //allows Employer to close showCreateQuestionnaire and make it invisible
  const handleLink = () => {
    setShowEmploymentTypeOptions(false);
    setShowMainButtons(true);
  };

  const navigate = useNavigate();

  //navigation for MainButtons with state of employee
  const handleNavigate = (path: string) => {
    navigate(`/payroll/${selectedEmployee?.id}/${path}`, {
      state: {
        selectedEmployee: selectedEmployee,
      },
    });
  };
  console.log(selectedEmployee?.id);

  if (!selectedEmployee) return null;

  return (
    <VerticalFlex className={cn("sticky top-[74px]")}>
      <Card className={`h-auto w-[1/3] border-none shadow active:border-2`}>
        <CardHeader className={"top-0 mb-2 w-full pb-0"}>
          <VerticalFlex>
            <HorizontalFlex
              className={"align-start flex items-start justify-end"}
            >
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger>
                    {/* update employee */}
                    <Button
                      variant="ghost"
                      size="icon"
                      onClick={openUpdateEmployeeModal}
                      className={"group"}
                    >
                      <PencilIcon className="h-4 w-4 text-muted-foreground group-hover:-rotate-12 group-hover:text-foreground" />
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent>
                    <p>
                      {questionnaireTranslation(
                        "employeeOverviewCard.updateEmployee",
                      )}
                    </p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
              <CloseButton onClick={onClose} />
            </HorizontalFlex>
            <HorizontalGradientLine />

            {/* employee details */}
            <HorizontalFlex className={"m-4"}>
              <EmployeeDetail selectedEmployee={selectedEmployee} />
            </HorizontalFlex>


            <HorizontalGradientLine />
          </VerticalFlex>
        </CardHeader>

        <VerticalFlex className={"mb-2 pt-2"}>
          <CardContent className={"flex flex-1 flex-col text-sm"}>
            {/* shows title, buttons and selection of employmentType for sending the Email */}
            {showEmploymentTypeOptions && (
              <HorizontalFlex
                className={"mt-2 grid grid-cols-[1fr_auto] gap-4"}
              >
                <VerticalFlex className={"mr-4 flex items-center border-b-2"}>
                  <Title level={3}>{payrollTranslation(title)}</Title>
                </VerticalFlex>
                <VerticalFlex className={"flex items-center justify-end"}>
                  <Button
                    variant={"outline"}
                    size={"icon"}
                    className={"flex justify-center"}
                    onClick={handleLink}
                  >
                    <X />
                  </Button>
                </VerticalFlex>

              </HorizontalFlex>
            )}

            {/* shows the main buttons to navigate the payroll documents */}
            {showMainButtons && (
              <HorizontalFlex className={"flex items-center justify-center mb-4"}>
                <MainButtons
                  selectedEmployee={selectedEmployee}
                  showCreateQuestionnaire={handleShowCreateQuestionnaire}
                  setNavigationPath={handleNavigate}
                />
              </HorizontalFlex>
            )}

            <HorizontalGradientLine />

            {/* event timeline */}
            <HorizontalFlex>
              <HorizontalGradientLine />
              <Timeline selectedEmployee={selectedEmployee} />
            </HorizontalFlex>
          </CardContent>
        </VerticalFlex>
      </Card>
    </VerticalFlex>
  );
};

export default EmployeeCard;
