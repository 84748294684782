import { useMemo } from "react";
import { useGetEmployeeDataQuery } from "@/api/endpoints/payrollApi";
import {
  Employee,
  EmployeeEvent,
} from "@/feature/payroll/forms/questionnaire/schema/employee/EmployeeSchema";
import { defaultValuesQuestionnaire } from "@/feature/payroll/forms/questionnaire/schema/DefaultValuesQuestionnaire";
import { getEmploymentTypeLabel } from "@/feature/payroll/forms/questionnaire/functions/EmploymentTypeLabel";
import {
  countriesTranslation,
  payrollTranslation,
  questionnaireTranslation,
} from "@/feature/payroll/components/TranslationConstants";
import { formatDate } from "@/feature/payroll/forms/questionnaire/components/printForm/functions/FormateDateFunction";
import { EmploymentTypeEnum } from "@/models/payroll";
import { FormSchemaComplete } from "@/feature/payroll/forms/questionnaire/schema/QuestionnaireSchema";
import { z } from "zod";

const useEmployeeData = (selectedEmployee?: Employee) => {
  // get employee data from api
  const { data: employee } = useGetEmployeeDataQuery(
    { employeeId: selectedEmployee?.id ?? "" },
    { skip: !selectedEmployee?.id },
  );
  // update employmentType based on questionnaire data
  let employmentType = useMemo(() => {
    const sendQuestionnaire = employee?.events?.find(
      (event: EmployeeEvent) => event?.sendQuestionnaireEvent,
    )?.sendQuestionnaireEvent;
    return sendQuestionnaire?.employmentType || EmploymentTypeEnum.EMPLOYEE;
  }, [employee]);

  // get label based on current employmentType
  const employmentTypeLabel = getEmploymentTypeLabel(employmentType);

  // get latest employee and employer questionnaire events, sorted by createdAt

  const latestEmployeeEvent = employee?.events
    ?.filter((event: EmployeeEvent) => event?.employeeFillQuestionnaireEvent)
    .sort(
      (a, b) =>
        new Date(b?.createdAt ?? 0).getTime() -
        new Date(a?.createdAt ?? 0).getTime(),
    )
    .shift();

  const latestEmployerEvent = employee?.events
    ?.filter(
      (event: EmployeeEvent) => event?.employerCompleteQuestionnaireEvent,
    )
    .sort(
      (a, b) =>
        new Date(b?.createdAt ?? 0).getTime() -
        new Date(a?.createdAt ?? 0).getTime(),
    )
    .shift();

  const employerComplete =
    latestEmployerEvent?.employerCompleteQuestionnaireEvent;
  const employeeFill = latestEmployeeEvent?.employeeFillQuestionnaireEvent;

  // update formData state with data from the employee and employer events
  let formData: z.infer<typeof FormSchemaComplete> = useMemo(() => {
    let def = defaultValuesQuestionnaire;
    return {
      ...defaultValuesQuestionnaire,
      personalDetails: {
        ...def.personalDetails,
        birthName: employeeFill?.personalDetails.birthName,
        birthDate: formatDate(employeeFill?.personalDetails.birthDate),
        phone: employeeFill?.personalDetails.phone,
        address: {
          street: employeeFill?.personalDetails.address.street || "",
          houseNumber: employeeFill?.personalDetails?.address.houseNumber || "",
          city: employeeFill?.personalDetails?.address.city || "",
          postalCode: employeeFill?.personalDetails?.address.postalCode || "",
          country: employeeFill?.personalDetails?.address.country
            ? countriesTranslation(
                `${employeeFill?.personalDetails?.address.country}`,
              )
            : "",
          addressAdditional:
            employeeFill?.personalDetails?.address.addressAdditional,
        },
        insuranceNumber: employeeFill?.personalDetails.insuranceNumber,
        birthPlace: employeeFill?.personalDetails.birthPlace,
        birthCountry: employeeFill?.personalDetails.birthCountry,
        gender: employeeFill?.personalDetails.gender
          ? questionnaireTranslation(
              `personalCard.${employeeFill?.personalDetails.gender}`,
            )
          : "",
        nationality: employeeFill?.personalDetails.nationality
          ? countriesTranslation(`${employeeFill?.personalDetails.nationality}`)
          : "",
        disabled: employeeFill?.personalDetails.disabled
          ? questionnaireTranslation(
              `personalCard.${employeeFill?.personalDetails.disabled}`,
            )
          : "",
        employeeNumberSocialFund:
          employeeFill?.personalDetails.employeeNumberSocialFund,
        iban: employeeFill?.personalDetails.iban,
        bic: employeeFill?.personalDetails.bic,
      },

      children:
        employeeFill?.children?.map((child: any) => ({
          firstName: child.firstName,
          lastName: child.lastName,
          birthDate: formatDate(child.birthDate),
        })) || [],

      employmentDetails: {
        ...def.employmentDetails,
        highestSchoolDegree: employeeFill?.employmentDetails.highestSchoolDegree
          ? questionnaireTranslation(
              `employmentCard.eduValues.${employeeFill?.employmentDetails.highestSchoolDegree}`,
            )
          : "",
        highestProfessionalEducation: employeeFill?.employmentDetails
          .highestProfessionalEducation
          ? questionnaireTranslation(
              `employmentCard.profValues.${employeeFill?.employmentDetails.highestProfessionalEducation}`,
            )
          : "",
        jobTitle: employeeFill?.employmentDetails.jobTitle,
        constructionEmployment: formatDate(
          employeeFill?.employmentDetails.constructionEmployment,
        ),
        minorEmployment: employeeFill?.employmentDetails.minorEmployment
          ? payrollTranslation(
              `universal.${employeeFill?.employmentDetails.minorEmployment}`,
            )
          : "",
        additionalEmployment: employeeFill?.employmentDetails
          .additionalEmployment
          ? employeeFill?.employmentDetails.additionalEmployment
          : "",
        trainingStart: formatDate(
          employerComplete?.employmentDetails.trainingStart,
        ),
        trainingEnd: formatDate(
          employerComplete?.employmentDetails.trainingEnd,
        ),
        entryDate: formatDate(employerComplete?.employmentDetails.entryDate),
        firstEntryDate: formatDate(
          employerComplete?.employmentDetails.firstEntryDate,
        ),
        employmentCompany:
          employerComplete?.employmentDetails.employmentCompany,

        jobActivity: employerComplete?.employmentDetails.jobActivity,
        probation: employerComplete?.employmentDetails.probation
          ? payrollTranslation(
              `universal.${employerComplete?.employmentDetails.probation}`,
            )
          : "",
        probationDuration:
          employerComplete?.employmentDetails.probationDuration,
        weeklyWorkHours: employerComplete?.employmentDetails.weeklyWorkHours,
        vacationEntitlement:
          employerComplete?.employmentDetails.vacationEntitlement,
        contractForm: employerComplete?.employmentDetails.contractForm,
        hourDistribution: {
          monday: employerComplete?.employmentDetails.hourDistribution.monday,
          tuesday: employerComplete?.employmentDetails.hourDistribution.tuesday,
          wednesday:
            employerComplete?.employmentDetails.hourDistribution.wednesday,
          thursday:
            employerComplete?.employmentDetails.hourDistribution.thursday,
          friday: employerComplete?.employmentDetails.hourDistribution.friday,
          saturday:
            employerComplete?.employmentDetails.hourDistribution.saturday,
          sunday: employerComplete?.employmentDetails.hourDistribution.sunday,
        },
        costCenter: employerComplete?.employmentDetails.costCenter,
        departmentNumber: employerComplete?.employmentDetails.departmentNumber,
      },

      contractLimitation: {
        ...def.contractLimitation,
        limitType: employerComplete?.contractLimitation.limitType
          ? questionnaireTranslation(
              `limitCard.limitValues.${employerComplete?.contractLimitation.limitType}`,
            )
          : "",
        contractEndDate: formatDate(
          employerComplete?.contractLimitation.contractEndDate,
        ),
        contractSignedDate: formatDate(
          employerComplete?.contractLimitation.contractSignedDate,
        ),
      },

      payment: {
        ...def.payment,
        additionalPayments:
          employerComplete?.payment?.additionalPayments?.map(
            (payment: any) => ({
              additionalPaymentName: payment?.additionalPaymentName,
              additionalPaymentSum: payment?.additionalPaymentSum,
            }),
          ) || [],
        christmasMoney: employerComplete?.payment.christmasMoney,
        vacationMoney: employerComplete?.payment.christmasMoney,
        payoutMonthChristmas: employerComplete?.payment.payoutMonthChristmas
          ? payrollTranslation(
              `months.${employerComplete?.payment.payoutMonthChristmas}`,
            )
          : "",
        payoutMonthVacation: employerComplete?.payment.payoutMonthVacation
          ? payrollTranslation(
              `months.${employerComplete?.payment.payoutMonthVacation}`,
            )
          : "",
        description: employerComplete?.payment?.description,
        sum: employerComplete?.payment?.sum,
        dateSum: formatDate(employerComplete?.payment?.dateSum),
        hourlyWage: employerComplete?.payment?.hourlyWage,
        dateWage: formatDate(employerComplete?.payment?.dateWage),
        traineePayment: {
          ...def.payment.traineePayment,
          yearOne: {
            sum: employerComplete?.payment?.traineePayment?.yearOne?.sum,
            dateSum: formatDate(
              employerComplete?.payment?.traineePayment?.yearOne?.dateSum,
            ),
            hourlyWage:
              employerComplete?.payment?.traineePayment?.yearOne?.hourlyWage,
            dateWage: formatDate(
              employerComplete?.payment?.traineePayment?.yearOne?.dateWage,
            ),
          },
          yearTwo: {
            sum: employerComplete?.payment?.traineePayment?.yearTwo?.sum,
            dateSum: formatDate(
              employerComplete?.payment?.traineePayment?.yearTwo?.dateSum,
            ),
            hourlyWage:
              employerComplete?.payment?.traineePayment?.yearTwo?.hourlyWage,
            dateWage: formatDate(
              employerComplete?.payment?.traineePayment?.yearTwo?.dateWage,
            ),
          },
          yearThree: {
            sum: employerComplete?.payment?.traineePayment?.yearThree?.sum,
            dateSum: formatDate(
              employerComplete?.payment?.traineePayment?.yearThree?.dateSum,
            ),
            hourlyWage:
              employerComplete?.payment?.traineePayment?.yearThree?.hourlyWage,
            dateWage: formatDate(
              employerComplete?.payment?.traineePayment?.yearThree?.dateWage,
            ),
          },
        },
      },

      taxInformation: {
        ...def.taxInformation,
        identificationEmployer: employeeFill?.taxInformation
          .identificationEmployer
          ? questionnaireTranslation(
              `taxCard.${employeeFill?.taxInformation.identificationEmployer}`,
            )
          : "",
        miniJobberTax: {
          rollOff: employerComplete?.taxInformation.miniJobberTax.rollOff
            ? payrollTranslation(
                `universal.${employerComplete?.taxInformation.miniJobberTax.rollOff}`,
              )
            : "",
          generalize: employerComplete?.taxInformation.miniJobberTax.generalize,
        },
        taxId: employeeFill?.taxInformation.taxId,
        taxClass: employeeFill?.taxInformation.taxClass,
        taxFactor: employeeFill?.taxInformation.taxFactor,
        childAllowance: employeeFill?.taxInformation.childAllowance,
      },

      vwlDetails:
        employerComplete?.vwlDetails?.map((detail: any) => ({
          receptionThrough: detail?.receptionThrough,
          sumBenefits: detail?.sumBenefits,
          dateSince: formatDate(detail?.dateSince),
          contractNumber: detail?.contractNumber,
          iban: detail?.iban,
          bic: detail?.bic,
          employerShare: detail?.employerShare,
        })) || [],

      taxableEmployment: {
        ...def.taxableEmployment,
        previousEmployment:
          employeeFill?.taxableEmployment?.previousEmployment?.map(
            (employment: any) => ({
              dateFrom: formatDate(employment?.dateFrom),
              dateUntil: formatDate(employment?.dateUntil),
              typeOccupation: employment?.typeOccupation,
              daysOccupation: employment?.daysOccupation,
            }),
          ) || [],

        miniJobberLimit: {
          moneyLimit: employeeFill?.taxableEmployment?.miniJobberLimit
            ?.moneyLimit
            ? payrollTranslation(
                `universal.${employeeFill?.taxableEmployment?.miniJobberLimit?.moneyLimit}`,
              )
            : "",
        },
      },

      socialSecurity: {
        ...def.socialSecurity,

        statutoryHealthInsurance: employeeFill?.socialSecurity
          .statutoryHealthInsurance
          ? questionnaireTranslation(
              `socialSecurityCard.insuranceNames.${employeeFill?.socialSecurity.statutoryHealthInsurance}`,
            )
          : "",
        currentHealthInsurance: employeeFill?.socialSecurity
          .currentHealthInsurance
          ? questionnaireTranslation(
              `socialSecurityCard.insuranceNames.${employeeFill?.socialSecurity.currentHealthInsurance}`,
            )
          : "",
        parentProperty: employeeFill?.socialSecurity.parentProperty
          ? payrollTranslation(
              `universal.${employeeFill?.socialSecurity.parentProperty}`,
            )
          : "",
        miniJobberSecurity: {
          freePensionInsurance: employeeFill?.socialSecurity.miniJobberSecurity
            .freePensionInsurance
            ? payrollTranslation(
                `universal.${employeeFill?.socialSecurity.miniJobberSecurity.freePensionInsurance}`,
              )
            : "",
        },
        insuranceKey: employerComplete?.socialSecurity.insuranceKey
          ? questionnaireTranslation(
              `socialSecurityCard.insuranceKeyValue.${employerComplete?.socialSecurity.insuranceKey}`,
            )
          : "",

        deuevStatus: employerComplete?.socialSecurity.deuevStatus
          ? questionnaireTranslation(
              `socialSecurityCard.statusValues.${employerComplete?.socialSecurity.deuevStatus}`,
            )
          : "",
      },
    };
  }, [employee]);

  return { formData, employmentTypeLabel };
};

export default useEmployeeData;
