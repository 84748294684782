import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import MultiStepCard from "@/feature/payroll/MultiStepGeneral";
import { Form } from "@/components/ui/form";
import { Button } from "@/components/ui/button";
import {
  useGetEmployeeQuestionnaireQuery,
  useSaveEmployeeQuestionnaireMutation,
} from "@/api/endpoints/payrollApi";
import { FormSchemaEmployee } from "@/feature/payroll/forms/questionnaire/schema/employee/FormSchemaEmployee";
import { useParams } from "react-router";
import { defaultValuesEmployee } from "@/feature/payroll/forms/questionnaire/schema/employee/DefaultValuesEmployee";
import { useNavigate } from "react-router-dom";
import { useStepManagement } from "@/feature/payroll/forms/questionnaire/hooks/UseStepManagement";
import { getEmploymentTypeLabel } from "@/feature/payroll/forms/questionnaire/functions/EmploymentTypeLabel";
import { Employee } from "@/feature/payroll/forms/questionnaire/schema/employee/EmployeeSchema";
import {
  payrollTranslation,
  questionnaireTranslation,
} from "@/feature/payroll/components/TranslationConstants";
import { Trans } from "react-i18next";
import { StepsQuestionnaireEmployee } from "@/feature/payroll/forms/questionnaire/steps/totalSteps/EmployeeSteps";
import { HorizontalFlex, VerticalFlex } from "@/components/layout/Flex";
import { Title } from "@/components/text/Title";
import { UserTypeEnum } from "@/models/payroll";
import { Card } from "@/components/ui/card";

const EmployeeForm: React.FC<{
  userType?: UserTypeEnum;
  selectedEmployee?: Employee;
  setCurrentTitle?: (title: string) => void;
}> = ({ setCurrentTitle, selectedEmployee }) => {
  const form = useForm<z.infer<typeof FormSchemaEmployee>>({
    resolver: zodResolver(FormSchemaEmployee),
    mode: "onChange",
    defaultValues: defaultValuesEmployee,
  });

  const navigate = useNavigate();
  // manage form steps
  const { currentStep, totalSteps, handleStepChange, handleTotalStepsChange } =
    useStepManagement();

  // Watch form changes and save them to localStorage
  useEffect(() => {
    const subscription = form.watch((values) => {
      localStorage.setItem("employeeFormData", JSON.stringify(values));
    });
    return () => subscription.unsubscribe();
  }, [form]);

  // Load stored form data from localStorage
  useEffect(() => {
    const storedData = localStorage.getItem("employeeFormData");
    if (storedData) {
      try {
        const parsedData = JSON.parse(storedData);
        form.reset(parsedData);
      } catch (e) {
        console.error("Fehler beim Laden der Daten aus dem localStorage:", e);
      }
    }
  }, [form]);

  //get token from url
  const { token } = useParams<{ token: string }>();
  // save employee questionnaire
  const [saveEmployeeQuestionnaire] = useSaveEmployeeQuestionnaireMutation();
  // get employee data from api
  const { data, error } = useGetEmployeeQuestionnaireQuery({
    employeeToken: token!,
  });

  const [employeeName, setEmployeeName] = useState<string>("");
  const [employmentTypeLabel, setEmploymentTypeLabel] = useState<string>("");

  // Set employee name and employment type label
  useEffect(() => {
    if (data) {
      setEmployeeName(
        `${data?.employee?.firstName} ${data?.employee?.lastName}`,
      );
      setEmploymentTypeLabel(
        getEmploymentTypeLabel(data?.sendQuestionnaireEvent?.employmentType),
      );
    }
  }, [data]);

  if (error) {
    navigate("/*");
  }

  async function onSubmit(value: z.infer<typeof FormSchemaEmployee>) {
    try {
      const response = await saveEmployeeQuestionnaire({
        employeeForm: value,
        employeeToken: token!,
      }).unwrap();
      console.log("Successfully submitted:", response);
      navigate("/success");
    } catch (error) {
      navigate("/*");
      console.error(
        "Error while submitting the employee questionnaire:",
        error,
      );
    }
  }

  let resolvedEmployee = selectedEmployee || data?.employee;

  return (
    <VerticalFlex className="mx-auto w-screen p-10 md:w-[80vw] lg:w-[80vw]">
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <VerticalFlex>
            <Title>
              <Trans
                i18nKey={questionnaireTranslation("employeeGreeting")}
                values={{ employeeName }}
                components={{ strong: <strong /> }}
              />
            </Title>
            <Title level={3} className={"text-muted-foreground"}>
              <Trans
                i18nKey={questionnaireTranslation("employeeGreetingInfo")}
                values={{employmentTypeLabel }}
                components={{br: <br /> }}
              />
            </Title>
            <Card className="mt-4 h-auto w-full pl-8 pr-8 pt-8">
              {/* steps for employee */}
              {data?.sendQuestionnaireEvent?.employmentType &&
                resolvedEmployee && (
                  <MultiStepCard<typeof FormSchemaEmployee>
                    steps={StepsQuestionnaireEmployee(
                      form,
                      data?.sendQuestionnaireEvent?.employmentType,
                      resolvedEmployee,
                    )}
                    form={form}
                    setCurrentTitle={setCurrentTitle}
                    onStepChange={handleStepChange}
                    onTotalStepsChange={handleTotalStepsChange}
                  />
                )}
              {currentStep === totalSteps - 1 && (
                <HorizontalFlex className={"mb-8 flex justify-center"}>
                  <Button type="submit">
                    {payrollTranslation("universal.save")}
                  </Button>
                </HorizontalFlex>
              )}
            </Card>
          </VerticalFlex>
        </form>
      </Form>
    </VerticalFlex>
  );
};

export default EmployeeForm;
