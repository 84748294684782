import {
  FlexOne,
  HorizontalFlex,
  VerticalFlex,
} from "@/components/layout/Flex";
import { useTranslation } from "react-i18next";
import {
  BiDocument,
  BiDocumentKind,
  UploadDocumentStatus,
} from "@/models/document";

import { BookingBadge } from "@/feature/documents/components/BookingBadge";
import { formatMoney, formatSimpleDate } from "@/util/format";
import KindBadge from "./KindBadge";
import FakeProgressBar from "@/feature/documents/components/Document/FakeProgressBar";
import DocumentField from "@/feature/documents/components/Document/DocumentField";
import { cn } from "@/lib/utils";
import { FileWarning } from "lucide-react";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";

interface DocumentItemProps {
  document: BiDocument;
  highlight?: boolean;
  onClick?: (document: BiDocument) => void;
}

const DocumentItem = ({
  document,
  highlight = false,
  onClick,
}: DocumentItemProps) => {
  const { t } = useTranslation();
  const processing =
    document.uploadDocument?.ocrStatus === UploadDocumentStatus.Processing ||
    document.uploadDocument?.ocrStatus === UploadDocumentStatus.Pending;

  return (
    <HorizontalFlex
      id={document.id}
      key={document.id}
      className={cn(
        "cursor-pointer overflow-hidden rounded-xl bg-background",
        highlight
          ? "rounded-r-none border-2 border-background bg-muted/80 shadow hover:bg-muted"
          : "bg-muted/40 hover:bg-muted/50",
      )}
      style={{
        transition: "all 150ms ease-in-out",
        marginLeft: highlight ? "-16px" : "0px",
        marginRight: highlight ? "-8px" : "0px",
      }}
      onClick={() => onClick?.(document)}
      gap={0}
    >
      <KindBadge kind={document.kind} processing={processing} sideways />
      <VerticalFlex className="flex-1">
        {processing && (
          <FakeProgressBar document={document} eta={20} spread={2} />
        )}
        <HorizontalFlex>
          {/* Erste Spalte mit zwei Zeilen */}
          <div className="flex w-1/3 flex-col p-4">
            <div className="flex flex-row items-center gap-2">
              <DocumentField
                className="text-sm font-thin text-neutral-400"
                processing={processing}
                label={t("model.document.invoiceNumber")}
                value={document.invoiceNumber}
              />
            </div>
            <DocumentField
              className="mt-2 text-sm font-normal"
              processing={processing}
              label={
                document.kind === BiDocumentKind.Receipt
                  ? t("model.document.issuerSimple")
                  : t("model.document.recipientSimple")
              }
              value={
                document.kind === BiDocumentKind.Receipt
                  ? document.issuer.name
                  : document.recipient.name
              }
            />
          </div>

          {/* Rechnungsdatum */}
          <div className="flex w-1/6 flex-col items-start justify-center p-4">
            <DocumentField
              className="text-sm font-thin text-neutral-400"
              processing={processing}
              label={t("model.document.invoiceDate")}
              value={formatSimpleDate(document.invoiceDate)}
            />
          </div>

          {/* BookingBadge */}
          <div className="flex w-1/6 flex-col items-start justify-center p-2">
            <BookingBadge document={document} />
          </div>

          {/* Betrag */}
          <div className="flex w-1/3 flex-col items-end justify-center p-4">
            <DocumentField
              processing={processing}
              className="font-normal"
              label={t("model.document.totalAmount")}
              value={
                document.derived.totalAmount &&
                formatMoney(document.derived.totalAmount)
              }
            />
          </div>
        </HorizontalFlex>
      </VerticalFlex>
      {document.dirty && (
        <div
          className={
            "align-center flex h-full justify-center rounded-r-xl bg-red-600"
          }
        >
          <Tooltip>
            <TooltipTrigger asChild>
              <FileWarning className={"m-auto w-16 text-foreground"} />
            </TooltipTrigger>
            <TooltipContent>
              <div className={"w-56"}>
                {t("model.document.dirty.description")}
              </div>
            </TooltipContent>
          </Tooltip>
        </div>
      )}
    </HorizontalFlex>
  );
};

export default DocumentItem;
