import { z } from "zod";
import { FormSchemaEmployee } from "@/feature/payroll/forms/questionnaire/schema/employee/FormSchemaEmployee";
import { Employee } from "@/feature/payroll/forms/questionnaire/schema/employee/EmployeeSchema";
import useHasInfo from "@/feature/payroll/forms/questionnaire/hooks/UseHasInfo";
import { SelectEnum } from "@/models/payroll";

//Filter the Employee-Steps for Vwl, taxable employments and children
export const useEmployeeFilter = () => {
  const filter = (args: {
    hasVwl?: string;
    hasTaxableEmployment?: string;
    hasChildren?: string;
  }) => {
    return (form: z.infer<typeof FormSchemaEmployee>) => {
      if (args.hasVwl && form.additionalInfos?.hasVwl === SelectEnum.NO) {
        return false;
      }
      if (
        args.hasTaxableEmployment &&
        form.additionalInfos?.hasTaxableEmployment === SelectEnum.NO
      ) {
        return false;
      }
      if (
        args.hasChildren &&
        form.additionalInfos?.hasChildren === SelectEnum.NO
      ) {
        return false;
      }
      return true;
    };
  };

  return { filter };
};

//Filter the Employer-Steps for Vwl
export const useEmployerFilter = (selectedEmployee: Employee) => {
  const hasInfo = useHasInfo(selectedEmployee);

  const filter = (args: {
    hasVwl?: string;
    hasTaxableEmployment?: string;
    hasChildren?: string;
  }) => {
    return () => !(args.hasVwl && hasInfo?.hasVwl === SelectEnum.NO);
  };

  return { filter };
};
