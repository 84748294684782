// src/components/EventName.tsx
import React from "react";
import { EmployeeEvent } from "@/feature/payroll/forms/questionnaire/schema/employee/EmployeeSchema";
import { getEmploymentTypeLabel } from "@/feature/payroll/forms/questionnaire/functions/EmploymentTypeLabel";
import { payrollTranslation } from "@/feature/payroll/components/TranslationConstants";
import { Title } from "@/components/text/Title";

interface EventNameProps {
  event: EmployeeEvent;
}

const EventName = ({ event }: EventNameProps) => {
  const employmentType = event.sendQuestionnaireEvent?.employmentType;
  const employmentTypeLabel = employmentType
    ? getEmploymentTypeLabel(employmentType)
    : payrollTranslation("universal.unknown");

  // get label based on event
  switch (true) {
    case !!event.sendQuestionnaireEvent:
      return (
        <Title level={5} className="text-sm font-medium">
          {employmentTypeLabel}{" "}
          {payrollTranslation("timelineComponent.mailSent")}
        </Title>
      );
    case !!event.createEmployeeEvent:
      return (
        <Title level={5}>
          {payrollTranslation("timelineComponent.employeeCreated")}
        </Title>
      );
    case !!event.updateEmployeeEvent:
      return (
        <Title level={5}>
          {payrollTranslation("timelineComponent.employeeUpdated")}
        </Title>
      );
    case !!event.employeeFillQuestionnaireEvent:
      return (
        <Title level={5}>
          {payrollTranslation("timelineComponent.questionnaireFilled")}
        </Title>
      );
    case !!event.employerCompleteQuestionnaireEvent:
      return (
        <Title level={5}>
          {payrollTranslation("timelineComponent.questionnaireCompleted")}
        </Title>
      );
    default:
      return (
        <Title level={5}>
          {payrollTranslation("timelineComponent.unknown")}
        </Title>
      );
  }
};

export default EventName;
