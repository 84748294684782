import React from "react";
import { useLoggedIn } from "@/feature/auth/ProtectedRoutes";
import { blobUrl } from "@/api/reduxApi";

export const PrintCompany: React.FC = () => {
  const { activeCompany } = useLoggedIn();

  return (
    <div
      className={"page-break"}
      style={{
        lineHeight: "0.5",
        fontWeight: "bolder",
        paddingTop: "10px",
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
      }}
    >
      <div>
        {activeCompany?.logoBlobId && (
          <img
            key={activeCompany?.logoBlobId}
            src={blobUrl(activeCompany?.logoBlob)}
            style={{ height: "100px" }}
            alt={"logo preview"}
          />
        )}
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "end",
          textAlign: "right",
        }}
      >
        <p>{activeCompany?.name}</p>
        <p>{activeCompany?.contactInfo?.email}</p>
        <p>{activeCompany?.address?.lineOne}</p>
        <p>{activeCompany?.address?.lineTwo}</p>
        {activeCompany?.address?.lineThree ? (
          <p>{activeCompany?.address?.lineThree}</p>
        ) : null}

        <div>
          <p>{activeCompany?.address?.postcodeCode}</p>
          <p>{activeCompany?.address?.cityName}</p>
        </div>
      </div>
    </div>
  );
};
