import React, { useMemo } from "react";
import { Button } from "@/components/ui/button";
import { Maximize2, Minimize2 } from "lucide-react";
import { blobUrl } from "@/api/reduxApi";
import { BiDocument } from "@/models/document";
import { useTranslation } from "react-i18next";
import { useDocumentContext } from "@/feature/documents/DocumentContextProvider";

export const DocumentPreview = ({
  document,
  noButton,
}: {
  document: BiDocument;
  noButton?: boolean;
}) => {
  const { t } = useTranslation();
  const { mode, setMode } = useDocumentContext();
  const preview = useMemo(() => {
    let preview = [...(document.pagesPreview ?? [])];
    preview.sort((a, b) => a.pageNumber - b.pageNumber);
    return preview;
  }, [document.pagesPreview]);

  return (
    <>
      {preview.length === 0 && (
        <div
          className={"flex h-96 w-full items-center justify-center rounded-md"}
        >
          <span className={"opacity-50"}>{t("common.preview")}</span>
        </div>
      )}
      {preview.length > 0 && (
        <div className="rounded-2xl transition-all duration-300 ease-in-out">
          {(mode === "preview" || mode === "small") && !noButton && (
            <div className={"sticky top-0 h-0"}>
              <div className={"p-4"}>
                <Button
                  variant="secondary"
                  size={"icon"}
                  type="button"
                  className="group flex"
                  onClick={() => {
                    setMode(mode === "preview" ? "small" : "preview");
                  }}
                >
                  {mode === "preview" ? (
                    <Minimize2
                      className={
                        "transition-all group-hover:rotate-90 group-hover:scale-75"
                      }
                    />
                  ) : (
                    <Maximize2
                      className={
                        "transition-all group-hover:rotate-90 group-hover:scale-125"
                      }
                    />
                  )}
                </Button>
              </div>
            </div>
          )}
          <div className={"flex flex-col items-center"}>
            {preview.map((p) => (
              <img
                key={p.id}
                src={blobUrl(p.blob)}
                className={"shadow-s mb-4 shadow-black"}
                style={{
                  maxWidth: "100%",
                }}
                alt={"page preview"}
              />
            ))}
          </div>
        </div>
      )}
    </>
  );
};
