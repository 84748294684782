export const defaultValuesEmployer = {
  employmentDetails: {
    trainingStart: null,
    trainingEnd: null,
    entryDate: "",
    firstEntryDate:null,
    employmentCompany: "",
    minorEmployment:null,
    additionalEmployment:null,
    jobTitle:"",
    jobActivity: "",
    probation: null,
    probationDuration: null,
    weeklyWorkHours: null,
    vacationEntitlement: null,
    contractForm: "",
    hourDistribution: {
      monday:null,
      tuesday: null,
      wednesday: null,
      thursday: null,
      friday: null,
      saturday: null,
      sunday: null,
    },
    costCenter: "",
    departmentNumber:null,
    highestSchoolDegree: "",
    highestProfessionalEducation: "",
    constructionEmployment:null,
  },

  contractLimitation: {
    limitType: "",
    contractEndDate: null,
    contractSignedDate: null,
  },

  taxInformation: {
    miniJobberTax: {
      rollOff: "",
      generalize: "",
    },
  },

  payment: {
    additionalPayments: [
      {
        additionalPaymentName: "",
        additionalPaymentSum: null,
      },
    ],
    christmasMoney: null,
    payoutMonthChristmas: "",
    vacationMoney: null,
    payoutMonthVacation: "",
    description: "",
    sum:null,
    dateSum: null,
    hourlyWage:null,
    dateWage: null,
    traineePayment:{
      yearOne:{
        sum:null,
        dateSum:null,
        hourlyWage:null,
        dateWage: null,
      },
      yearTwo:{
        sum:null,
        dateSum:null,
        hourlyWage:null,
        dateWage: null,
      },
      yearThree:{
        sum:null,
        dateSum:null,
        hourlyWage:null,
        dateWage:null,
      },
    }


  },

  socialSecurity: {
    insuranceKey: "",
    deuevStatus: "",

  },

  vwlDetails:[
      {
        receptionThrough: "",
        sumBenefits: null,
        dateSince: "",
        contractNumber: null,
        iban: "",
        bic: "",
        employerShare:null,
      }
    ],

};


