import { HorizontalFlex } from "@/components/layout/Flex";
import { MouseEvent, ReactNode } from "react";
import { Loader2 } from "lucide-react";

interface InvoiceActionButtonProps {
  icon: ReactNode;
  label: string;
  loading?: boolean;
}

const InvoiceActionButton = ({
  icon,
  label,
  loading,
}: InvoiceActionButtonProps) => (
  <HorizontalFlex title={label}>
    {loading ? <Loader2 size={16} className="animate-spin" /> : icon}
    {label}
  </HorizontalFlex>
);

export default InvoiceActionButton;
