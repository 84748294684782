import { z } from "zod";
import { FormSchemaEmployer } from "@/feature/payroll/forms/questionnaire/schema/employer/FormSchemaEmployer";
import { FormSchemaEmployee } from "@/feature/payroll/forms/questionnaire/schema/employee/FormSchemaEmployee";
import { EmploymentTypeEnum } from "@/models/payroll";

export const EmailSchema = z.object({
  email: z.string().optional(),
  employmentType: z.nativeEnum(EmploymentTypeEnum).optional(),
});

export const CreateEmployeeSchema = z.object({
  firstName: z.string().optional(),
  lastName: z.string().optional(),
  email: z.string().optional(),
  personnelNumber: z.string().optional(),
});

export const UpdateEmployeeSchema = z.object({
  firstName: z.string().optional(),
  lastName: z.string().optional(),
  email: z.string().optional(),
});


export const EmployeeEventSchema = z.object({
  id: z.string().optional(),
  createdAt: z.string().optional(),
  createEmployeeEvent: CreateEmployeeSchema.optional(),
  updateEmployeeEvent: UpdateEmployeeSchema.optional(),
  employerCompleteQuestionnaireEvent: FormSchemaEmployer.optional(),
  employeeFillQuestionnaireEvent: FormSchemaEmployee.optional(),
  sendQuestionnaireEvent: EmailSchema.optional(),
});

export type EmployeeEvent = z.infer<typeof EmployeeEventSchema>;

export const EmployeeSchema = z.object({
  id: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  email: z.string(),
  personnelNumber: z.string(),
  token: z.string(),
  employmentType: z.string(),
  status: z.string(),
  events: z.array(EmployeeEventSchema),
});


export type CreateEmployee = z.infer<typeof CreateEmployeeSchema>;
export type UpdateEmployee = z.infer<typeof UpdateEmployeeSchema>;
export type Employee = z.infer<typeof EmployeeSchema>;
