import { z } from "zod";
import { PLACEHOLDER_EMPTY_ADDRESS } from "@/util/address";

export enum ContactType {
  Customer = "customer",
  Supplier = "supplier",
  Partner = "partner",
}

const contactTypeEnum = z.nativeEnum(ContactType);

export const address = z.object({
  street: z.string().optional(),
  postalCode: z.string().optional(),
  city: z.string().optional(),
  country: z.string().optional(),
});
export const addressSchema = address.refine(
  (address) => {
    console.log(address);
    console.log(PLACEHOLDER_EMPTY_ADDRESS);
    // skip if addr is the Placeholder addr
    if (JSON.stringify(address) === JSON.stringify(PLACEHOLDER_EMPTY_ADDRESS)) {
      return true;
    }

    return !!(address.street || address.postalCode || address.city);
  },
  {
    message: "Adresse darf nicht leer sein",
  },
);

const contactInfoSchema = z.object({
  contactPersonName: z.string().optional(),
  email: z.string().email().optional(),
  phone: z.string().optional(),
  website: z.string().url().optional(),
  notes: z.string().optional(),
});

const paymentInfoSchema = z.object({
  iban: z.string().min(15).max(34).optional(), // IBAN hat eine Länge zwischen 15 und 34 Zeichen
  bic: z.string().length(8).or(z.string().length(11)).optional(), // BIC hat entweder 8 oder 11 Zeichen
  vatId: z.string().optional(),
  taxNumber: z.string().optional(),
  leitwegId: z.string().optional(),
});

// required for datev
export const CONTACT_NO_MIN = 1000;
export const CONTACT_NO_MAX = 29999;

export const ContactInfoSchema = z.object({
  companyName: z.string().min(1).max(255), // Pflichtfeld
  customerNumber: z.number().min(CONTACT_NO_MIN).max(CONTACT_NO_MAX), // Pflichtfeld
  type: contactTypeEnum.optional(),
  addresses: z.array(addressSchema).optional(),
  contactInfo: contactInfoSchema.optional(),
  paymentInfo: paymentInfoSchema.optional(),
});

export const SampleContact = {
  companyName: "Example Company",
  customerNumber: 1234,
  type: ContactType.Customer,
  addresses: [
    {
      city: "Sample City",
      postalCode: "12345",
      country: "Sample Country",
      street: "Main Street",
    },
  ],
  contactInfo: {
    contactPersonName: "John Doe",
    email: "john.doe@example.com",
    phone: "123-456-7890",
    website: "https://example.com/whyisthissooolongloremlorem",
    notes: "Important customer",
  },
  paymentInfo: {
    iban: "DE12345678901234567890",
    bic: "DEUTDEDB123",
    vatId: "DE123456789",
    taxNumber: "123/456/78901",
    leitwegId: "123456",
  },
};

export type Contact = z.infer<typeof ContactInfoSchema>;

export type ContactWithId = Contact & { id: string };

export const ISO_COUNTRIES = [
  { code: "DE", name: "Deutschland" },
  { code: "AT", name: "Österreich" },
  { code: "FR", name: "Frankreich" },
  { code: "AF", name: "Afghanistan" },
  { code: "AX", name: "Åland" },
  { code: "AL", name: "Albanien" },
  { code: "DZ", name: "Algerien" },
  { code: "AS", name: "Amerikanisch-Samoa" },
  { code: "AD", name: "Andorra" },
  { code: "AO", name: "Angola" },
  { code: "AI", name: "Anguilla" },
  { code: "AQ", name: "Antarktis" },
  { code: "AG", name: "Antigua und Barbuda" },
  { code: "AR", name: "Argentinien" },
  { code: "AM", name: "Armenien" },
  { code: "AW", name: "Aruba" },
  { code: "AU", name: "Australien" },
  { code: "AZ", name: "Aserbaidschan" },
  { code: "BS", name: "Bahamas" },
  { code: "BH", name: "Bahrain" },
  { code: "BD", name: "Bangladesch" },
  { code: "BB", name: "Barbados" },
  { code: "BY", name: "Weißrussland (Belarus)" },
  { code: "BE", name: "Belgien" },
  { code: "BZ", name: "Belize" },
  { code: "BJ", name: "Benin" },
  { code: "BM", name: "Bermuda" },
  { code: "BT", name: "Bhutan" },
  { code: "BO", name: "Bolivien" },
  { code: "BA", name: "Bosnien und Herzegowina" },
  { code: "BW", name: "Botsuana" },
  { code: "BV", name: "Bouvetinsel" },
  { code: "BR", name: "Brasilien" },
  { code: "IO", name: "Britisches Territorium im Indischen Ozean" },
  { code: "BN", name: "Brunei" },
  { code: "BG", name: "Bulgarien" },
  { code: "BF", name: "Burkina Faso" },
  { code: "BI", name: "Burundi" },
  { code: "KH", name: "Kambodscha" },
  { code: "CM", name: "Kamerun" },
  { code: "CA", name: "Kanada" },
  { code: "CV", name: "Kap Verde" },
  { code: "KY", name: "Kaimaninseln" },
  { code: "CF", name: "Zentralafrikanische Republik" },
  { code: "TD", name: "Tschad" },
  { code: "CL", name: "Chile" },
  { code: "CN", name: "China" },
  { code: "CX", name: "Weihnachtsinsel" },
  { code: "CC", name: "Kokosinseln" },
  { code: "CO", name: "Kolumbien" },
  { code: "KM", name: "Komoren" },
  { code: "CG", name: "Republik Kongo" },
  { code: "CD", name: "Demokratische Republik Kongo" },
  { code: "CK", name: "Cookinseln" },
  { code: "CR", name: "Costa Rica" },
  { code: "CI", name: "Elfenbeinküste" },
  { code: "HR", name: "Kroatien" },
  { code: "CU", name: "Kuba" },
  { code: "CY", name: "Zypern" },
  { code: "CZ", name: "Tschechien" },
  { code: "DK", name: "Dänemark" },
  { code: "DJ", name: "Dschibuti" },
  { code: "DM", name: "Dominica" },
  { code: "DO", name: "Dominikanische Republik" },
  { code: "EC", name: "Ecuador" },
  { code: "EG", name: "Ägypten" },
  { code: "SV", name: "El Salvador" },
  { code: "GQ", name: "Äquatorialguinea" },
  { code: "ER", name: "Eritrea" },
  { code: "EE", name: "Estland" },
  { code: "ET", name: "Äthiopien" },
  { code: "FK", name: "Falklandinseln" },
  { code: "FO", name: "Färöer" },
  { code: "FJ", name: "Fidschi" },
  { code: "FI", name: "Finnland" },
  { code: "GF", name: "Französisch-Guayana" },
  { code: "PF", name: "Französisch-Polynesien" },
  { code: "TF", name: "Französische Süd- und Antarktisgebiete" },
  { code: "GA", name: "Gabun" },
  { code: "GM", name: "Gambia" },
  { code: "GE", name: "Georgien" },
  { code: "GH", name: "Ghana" },
  { code: "GI", name: "Gibraltar" },
  { code: "GR", name: "Griechenland" },
  { code: "GL", name: "Grönland" },
  { code: "GD", name: "Grenada" },
  { code: "GP", name: "Guadeloupe" },
  { code: "GU", name: "Guam" },
  { code: "GT", name: "Guatemala" },
  { code: "GG", name: "Guernsey" },
  { code: "GN", name: "Guinea" },
  { code: "GW", name: "Guinea-Bissau" },
  { code: "GY", name: "Guyana" },
  { code: "HT", name: "Haiti" },
  { code: "HM", name: "Heard und McDonaldinseln" },
  { code: "VA", name: "Vatikanstadt" },
  { code: "HN", name: "Honduras" },
  { code: "HK", name: "Hongkong" },
  { code: "HU", name: "Ungarn" },
  { code: "IS", name: "Island" },
  { code: "IN", name: "Indien" },
  { code: "ID", name: "Indonesien" },
  { code: "IR", name: "Iran" },
  { code: "IQ", name: "Irak" },
  { code: "IE", name: "Irland" },
  { code: "IM", name: "Isle of Man" },
  { code: "IL", name: "Israel" },
  { code: "IT", name: "Italien" },
  { code: "JM", name: "Jamaika" },
  { code: "JP", name: "Japan" },
  { code: "JE", name: "Jersey" },
  { code: "JO", name: "Jordanien" },
  { code: "KZ", name: "Kasachstan" },
  { code: "KE", name: "Kenia" },
  { code: "KI", name: "Kiribati" },
  { code: "KR", name: "Südkorea" },
  { code: "KW", name: "Kuwait" },
  { code: "KG", name: "Kirgisistan" },
  { code: "LA", name: "Laos" },
  { code: "LV", name: "Lettland" },
  { code: "LB", name: "Libanon" },
  { code: "LS", name: "Lesotho" },
  { code: "LR", name: "Liberia" },
  { code: "LY", name: "Libyen" },
  { code: "LI", name: "Liechtenstein" },
  { code: "LT", name: "Litauen" },
  { code: "LU", name: "Luxemburg" },
  { code: "MO", name: "Macao" },
  { code: "MK", name: "Nordmazedonien" },
  { code: "MG", name: "Madagaskar" },
  { code: "MW", name: "Malawi" },
  { code: "MY", name: "Malaysia" },
  { code: "MV", name: "Malediven" },
  { code: "ML", name: "Mali" },
  { code: "MT", name: "Malta" },
  { code: "MH", name: "Marshallinseln" },
  { code: "MQ", name: "Martinique" },
  { code: "MR", name: "Mauretanien" },
  { code: "MU", name: "Mauritius" },
  { code: "YT", name: "Mayotte" },
  { code: "MX", name: "Mexiko" },
  { code: "FM", name: "Mikronesien" },
  { code: "MD", name: "Moldawien" },
  { code: "MC", name: "Monaco" },
  { code: "MN", name: "Mongolei" },
  { code: "ME", name: "Montenegro" },
  { code: "MS", name: "Montserrat" },
  { code: "MA", name: "Marokko" },
  { code: "MZ", name: "Mosambik" },
  { code: "MM", name: "Myanmar" },
  { code: "NA", name: "Namibia" },
  { code: "NR", name: "Nauru" },
  { code: "NP", name: "Nepal" },
  { code: "NL", name: "Niederlande" },
  { code: "AN", name: "Niederländische Antillen" },
  { code: "NC", name: "Neukaledonien" },
  { code: "NZ", name: "Neuseeland" },
  { code: "NI", name: "Nicaragua" },
  { code: "NE", name: "Niger" },
  { code: "NG", name: "Nigeria" },
  { code: "NU", name: "Niue" },
  { code: "NF", name: "Norfolkinsel" },
  { code: "MP", name: "Nördliche Marianen" },
  { code: "NO", name: "Norwegen" },
  { code: "OM", name: "Oman" },
  { code: "PK", name: "Pakistan" },
  { code: "PW", name: "Palau" },
  { code: "PS", name: "Palästinensische Gebiete" },
  { code: "PA", name: "Panama" },
  { code: "PG", name: "Papua-Neuguinea" },
  { code: "PY", name: "Paraguay" },
  { code: "PE", name: "Peru" },
  { code: "PH", name: "Philippinen" },
  { code: "PN", name: "Pitcairninseln" },
  { code: "PL", name: "Polen" },
  { code: "PT", name: "Portugal" },
  { code: "PR", name: "Puerto Rico" },
  { code: "QA", name: "Katar" },
  { code: "RE", name: "Réunion" },
  { code: "RO", name: "Rumänien" },
  { code: "RU", name: "Russland" },
  { code: "RW", name: "Ruanda" },
  { code: "BL", name: "Saint-Barthélemy" },
  { code: "SH", name: "St. Helena" },
  { code: "KN", name: "St. Kitts und Nevis" },
  { code: "LC", name: "St. Lucia" },
  { code: "MF", name: "Saint-Martin" },
  { code: "PM", name: "Saint-Pierre und Miquelon" },
  { code: "VC", name: "St. Vincent und die Grenadinen" },
  { code: "WS", name: "Samoa" },
  { code: "SM", name: "San Marino" },
  { code: "ST", name: "São Tomé und Príncipe" },
  { code: "SA", name: "Saudi-Arabien" },
  { code: "SN", name: "Senegal" },
  { code: "RS", name: "Serbien" },
  { code: "SC", name: "Seychellen" },
  { code: "SL", name: "Sierra Leone" },
  { code: "SG", name: "Singapur" },
  { code: "SK", name: "Slowakei" },
  { code: "SI", name: "Slowenien" },
  { code: "SB", name: "Salomonen" },
  { code: "SO", name: "Somalia" },
  { code: "ZA", name: "Südafrika" },
  { code: "GS", name: "Südgeorgien und Südliche Sandwichinseln" },
  { code: "ES", name: "Spanien" },
  { code: "LK", name: "Sri Lanka" },
  { code: "SD", name: "Sudan" },
  { code: "SR", name: "Suriname" },
  { code: "SJ", name: "Svalbard und Jan Mayen" },
  { code: "SZ", name: "Eswatini" },
  { code: "SE", name: "Schweden" },
  { code: "CH", name: "Schweiz" },
  { code: "SY", name: "Syrien" },
  { code: "TW", name: "Taiwan" },
  { code: "TJ", name: "Tadschikistan" },
  { code: "TZ", name: "Tansania" },
  { code: "TH", name: "Thailand" },
  { code: "TL", name: "Timor-Leste" },
  { code: "TG", name: "Togo" },
  { code: "TK", name: "Tokelau" },
  { code: "TO", name: "Tonga" },
  { code: "TT", name: "Trinidad und Tobago" },
  { code: "TN", name: "Tunesien" },
  { code: "TR", name: "Türkei" },
  { code: "TM", name: "Turkmenistan" },
  { code: "TC", name: "Turks- und Caicosinseln" },
  { code: "TV", name: "Tuvalu" },
  { code: "UG", name: "Uganda" },
  { code: "UA", name: "Ukraine" },
  { code: "AE", name: "Vereinigte Arabische Emirate" },
  { code: "GB", name: "Großbritannien" },
  { code: "US", name: "USA" },
  { code: "UM", name: "Amerikanische Überseeinseln" },
  { code: "UY", name: "Uruguay" },
  { code: "UZ", name: "Usbekistan" },
  { code: "VU", name: "Vanuatu" },
  { code: "VE", name: "Venezuela" },
  { code: "VN", name: "Vietnam" },
  { code: "VG", name: "Britische Jungferninseln" },
  { code: "VI", name: "Amerikanische Jungferninseln" },
  { code: "WF", name: "Wallis und Futuna" },
  { code: "EH", name: "Westsahara" },
  { code: "YE", name: "Jemen" },
  { code: "ZM", name: "Sambia" },
  { code: "ZW", name: "Simbabwe" },
];
