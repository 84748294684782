import React from "react";
import { CardContent, CardHeader } from "@/components/ui/card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import PersonalData from "@/feature/payroll/forms/questionnaire/components/employeeData/steps/PersonalData";
import ChildrenData from "@/feature/payroll/forms/questionnaire/components/employeeData/steps/ChildrenData";
import EmploymentData from "@/feature/payroll/forms/questionnaire/components/employeeData/steps/EmploymentData";
import TaxData from "@/feature/payroll/forms/questionnaire/components/employeeData/steps/TaxData";

import VwlData from "@/feature/payroll/forms/questionnaire/components/employeeData/steps/VwlData";
import TaxableData from "@/feature/payroll/forms/questionnaire/components/employeeData/steps/TaxableData";
import {
  payrollTranslation,
  questionnaireTranslation,
} from "@/feature/payroll/components/TranslationConstants";
import { Employee } from "@/feature/payroll/forms/questionnaire/schema/employee/EmployeeSchema";
import { Title } from "@/components/text/Title";
import { HorizontalFlex, VerticalFlex } from "@/components/layout/Flex";
import { QuestionnairePropsEmployee, SelectEnum } from "@/models/payroll";
import {
  SocialSecurityData
} from "@/feature/payroll/forms/questionnaire/components/employeeData/steps/SocialSecurityData";


export function OverviewCard(
  { form }: QuestionnairePropsEmployee,
  selectedEmployee?: Employee,
) {
  // get form values
  const formValues = form.getValues();
  const vwl = form.watch("additionalInfos.hasVwl");
  const children = form.watch("additionalInfos.hasChildren");
  const taxableEmployment = form.watch("additionalInfos.hasTaxableEmployment");
  return (
    <HorizontalFlex className={"flex w-full flex-wrap justify-center"}>
      <VerticalFlex className="flex w-[80vw] flex-col flex-wrap">
        <CardHeader>
          <Title level={3}>
            {payrollTranslation("universal.dataOverview")}
          </Title>
          <p className="text-sm text-gray-600">
            {payrollTranslation("universal.isCorrect")}
          </p>
        </CardHeader>
        <CardContent className="flex-grow overflow-auto">
          <Tabs defaultValue="personalData">
            <TabsList className={"mb-4"}>
              <TabsTrigger value="personalData">
                {questionnaireTranslation("personalCard.personalInfo")}
              </TabsTrigger>
              {children === SelectEnum.YES && (
                <TabsTrigger value="children">
                  {questionnaireTranslation("childCard.kids")}
                </TabsTrigger>
              )}

              <TabsTrigger value="employment">
                {questionnaireTranslation("employmentCard.occupation")}
              </TabsTrigger>
              <TabsTrigger value="taxes">
                {questionnaireTranslation("taxCard.taxes")}
              </TabsTrigger>
              <TabsTrigger value="socialSecurity">
                {questionnaireTranslation("socialSecurityCard.socialSecurity")}
              </TabsTrigger>
              {vwl === SelectEnum.YES && (
                <TabsTrigger value="Vwl">
                  {questionnaireTranslation("benefitsCard.benefits")}
                </TabsTrigger>
              )}
              {taxableEmployment === SelectEnum.YES && (
                <TabsTrigger value="Vorbeschäftigung">
                  {questionnaireTranslation(
                    "taxableCard.taxableEmploymentShort",
                  )}
                </TabsTrigger>
              )}
            </TabsList>
            <TabsContent value="personalData">
              <PersonalData
                personalDetails={formValues.personalDetails}
                selectedEmployee={selectedEmployee}
              />
            </TabsContent>
            <TabsContent value="children">
              <ChildrenData children={formValues.children} />
            </TabsContent>
            <TabsContent value="employment">
              <EmploymentData
                employmentDetails={formValues.employmentDetails}
              />
            </TabsContent>
            <TabsContent value="taxes">
              <TaxData taxInformation={formValues.taxInformation} />
            </TabsContent>
            <TabsContent value="socialSecurity">
              <SocialSecurityData selectedEmployee={selectedEmployee} form={form} socialSecurity={formValues.socialSecurity} />
            </TabsContent>
            <TabsContent value="Vwl">
              <VwlData vwlDetails={formValues.vwlDetails} />
            </TabsContent>
            <TabsContent value="Vorbeschäftigung">
              <TaxableData taxableEmployment={formValues.taxableEmployment} />
            </TabsContent>
          </Tabs>
        </CardContent>
      </VerticalFlex>
    </HorizontalFlex>
  );
}
