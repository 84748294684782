import { HorizontalFlex } from "@/components/layout/Flex";
import { useTranslation } from "react-i18next";
import { BiDocumentKind } from "@/models/document";
import { motion } from "framer-motion";
import { cn } from "@/lib/utils";

const KindBadge = ({
  kind,
  processing,
  sideways,
  className,
  small,
}: {
  kind: BiDocumentKind;
  processing: boolean;
  sideways: boolean;
  className?: string;
  small?: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <HorizontalFlex
      justify={"center"}
      align={"center"}
      className={cn(
        sideways && "w-2",
        !sideways && "rounded-2xl text-xs",
        kind === BiDocumentKind.Invoice && "bg-blue-500",
        kind === BiDocumentKind.Receipt && "bg-neutral-300",
        className,
      )}
    >
      {processing ? (
        <motion.span
          style={
            sideways
              ? {
                  textOrientation: "sideways",
                  writingMode: "vertical-lr",
                  transform: "scale(-1)",
                  margin: 2,
                  minHeight: "4rem",
                  display: "flex",
                  justifyContent: "center",
                }
              : {}
          }
          className={cn(small && "overflow-visible p-1 align-middle")}
          animate={{
            boxShadow: [
              "0 0 5px rgba(28, 200, 221, 0.5)",
              "0 0 20px rgba(28, 200, 221, 0.7)",
              "0 0 5px rgba(28, 200, 221, 0.5)",
            ],
          }}
          transition={{ duration: 1.5, repeat: Infinity }}
        >
          {/* {t("model.document.kind." + kind)} */}
        </motion.span>
      ) : (
        <span
          style={
            sideways
              ? {
                  textOrientation: "sideways",
                  writingMode: "vertical-lr",
                  transform: "scale(-1)",
                  margin: 2,
                  minHeight: "4rem",
                  display: "flex",
                  justifyContent: "center",
                }
              : {}
          }
          className={cn(small && "p-1 align-middle")}
        >
          {/* {t("model.document.kind." + kind)} */}
        </span>
      )}
    </HorizontalFlex>
  );
};

export default KindBadge;
