"use client";

import remarkGfm from "remark-gfm";
import remarkMath from "remark-math";

import { cn } from "@/lib/utils";
import { MemoizedReactMarkdown } from "./Markdown";
import { Sparkles } from "lucide-react";
import { ChatMessageActions } from "./ChatMessageActions";
import { Chart } from "./Chart";
import { ExternalLinkIcon } from "@radix-ui/react-icons";

export function ChatMessage({ message, ...props }) {
  // Nachrichten mit role="system" ignorieren
  if (message.role === "system") {
    return null;
  }

  let parsedContent;

  try {
    parsedContent = JSON.parse(message.content);
  } catch (error) {
    parsedContent = message.content;
  }

  //
  // ============================
  // Layout für User-Nachrichten
  // ============================
  //
  if (message.role === "user") {
    return (
      <div
        className={cn(
          "group relative mb-4 flex flex-row-reverse items-start",
          "transition-transform transition-opacity duration-500 ease-out animate-slideIn"
        )}
        {...props}
      >
        {/* Nachricht selbst (rechts) */}
        <div
          className={cn(
            "relative px-1 ml-2 space-y-2 overflow-hidden",
            "-mt-4 w-fit max-w-xl text-left",
            "bg-secondary dark:bg-secondary rounded-2xl py-2 px-4",
            "self-end" // damit sie ganz rechts sitzt
          )}
        >
          <MemoizedReactMarkdown
            className={cn(
              "prose dark:prose-invert inline leading-normal break-words min-w-0 [word-break:break-word]"
            )}
            remarkPlugins={[remarkGfm, remarkMath]}
            components={{
              p({ children }) {
                return (
                  <p className="mb-2 mt-2 leading-relaxed font-thin text-base">
                    {children}
                  </p>
                );
              },
              a({ href, children }) {
                return (
                  <div className="text-[#1cc8dd]">
                    <a
                      href={href}
                      className="hover:underline inline-flex items-center"
                      target="_blank"
                      rel="nofollow noopener"
                    >
                      {children}
                      <ExternalLinkIcon className="ml-1 w-4 h-4" />
                    </a>
                  </div>
                );
              },
              /* usw. – je nach Bedarf */
            }}
          >
            {/* Inhalt ist einfach message.content, da der User-Text nicht JSON-parsiert wird */}
            {message.content}
          </MemoizedReactMarkdown>
        </div>

        {/* Actions/Links links vom User-Text */}
        <div className="mr-2 mt-4 flex-shrink-0">
          <ChatMessageActions
            message={message}
            isUser={message.role === "user"}
          />
        </div>
      </div>
    );
  }

  //
  // ===============================
  // Layout für Assistant-Nachrichten
  // ===============================
  //
  return (
    <div
      className={cn(
        "group relative mb-4 flex items-start",
        "transition-transform transition-opacity duration-500 ease-out animate-slideIn"
      )}
      {...props}
    >
      {/* Symbol (Sparkles) nur für den Assistenten */}
      <div
        className={cn(
          "flex mt-3 h-10 w-10 shrink-0 select-none items-center justify-center border-2 rounded-full p-2 shadow",
          "text-primary"
        )}
      >
        <Sparkles />
      </div>

      {/* Nachricht selbst (links) */}
      <div
        className={cn(
          "px-1 ml-2 -mt-4 space-y-2 overflow-hidden",
          "text-left rounded-lg py-3 px-4 w-fit max-w-2xl"
        )}
      >
        <MemoizedReactMarkdown
          className={cn(
            "prose dark:prose-invert inline leading-normal break-words min-w-0 [word-break:break-word]"
          )}
          remarkPlugins={[remarkGfm, remarkMath]}
          components={{
            p({ children }) {
              return (
                <p className="mb-4 mt-4 leading-relaxed font-normal text-base">
                  {children}
                </p>
              );
            },
            a({ href, children }) {
              return (
                <div className="text-[#1cc8dd]">
                  <a
                    href={href}
                    className="hover:underline inline-flex items-center"
                    target="_blank"
                    rel="nofollow noopener"
                  >
                    {children}
                    <ExternalLinkIcon className="ml-1 w-4 h-4" />
                  </a>
                </div>
              );
            },
            /* usw. – je nach Bedarf */
          }}
        >
          {/* Assistenten-Inhalt aus parsedContent[0] */}
          {Array.isArray(parsedContent) ? parsedContent[0] : parsedContent}
        </MemoizedReactMarkdown>

        {/* Beispiel: Chart einblenden, wenn vorhanden */}
        {Array.isArray(parsedContent) &&
          parsedContent[1] &&
          parsedContent[1][0] && (
            <div className="pt-0">
              <Chart content={parsedContent[1][0]} />
            </div>
          )}

        {/* Actions/Links unter der Assistenten-Antwort */}
        <div className="pt-0">
          <ChatMessageActions message={message} />
        </div>
      </div>
    </div>
  );
}
