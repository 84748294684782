export const defaultValuesAbsence = {
  generalDetails: {
    personnelNumber: "",
    companyName: "",
    companyAddress: "",
    firstName: "",
    lastName: "",
  },

  sickness: {
    absenceType: "",
    lastDay: undefined,
    reasonSickness: "",
    onInjuryThird: "",
    onFirstDay: "",
  },

  childCare: {
    typeChildCare: "",
    childFirstName: "",
    childLastName: "",
    childBirthDate: undefined,
    childOnFirstDay: "",
    childAccident: "",
  },

  maternity: {
    probableDueDate: undefined,
    realDueDate: undefined,
    typeBirth: "",
    paymentBefore: [
      {
        gross: "",
        net: "",
        monthYear: undefined,
      },
    ],
    additionalEmployment: "",
    employmentBan: "",
    onEmploymentBan: "",
  },

  additionalAbsence: {
    additionAbsenceType: "",
  },
};
