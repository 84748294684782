import { SelectEnum } from "@/models/payroll";

export const defaultValuesEmployee = {
  additionalInfos: {
    hasVwl: SelectEnum.NO,
    hasTaxableEmployment: SelectEnum.NO,
    hasChildren: SelectEnum.NO,
    isInConstruction: SelectEnum.NO,
  },
  personalDetails: {
    birthName: "",
    birthDate: "",
    phone: "",
    address: {
      street: "",
      houseNumber: "",
      city: "",
      postalCode: "",
      country: "",
      addressAdditional: "",
    },
    insuranceNumber: "",
    birthPlace: "",
    birthCountry: "",
    gender: "",
    nationality: "",
    disabled: null,
    employeeNumberSocialFund: "",
    iban: "",
    bic: "",
  },

  children: [
    {
      lastName: "",
      firstName: "",
      birthName: "",
    },
  ],

  employmentDetails: {
    highestSchoolDegree: "",
    highestProfessionalEducation: "",
    jobTitle: "",
    constructionEmployment: null,
    minorEmployment: null,
    additionalEmployment: null,
  },

  taxInformation: {
    taxId: "",
    taxClass: "",
    taxFactor: null,
    childAllowance: null,
  },

  socialSecurity: {
    statutoryHealthInsurance: "",
    currentHealthInsurance: "",
    parentProperty: "",
    miniJobberSecurity: {
      freePensionInsurance: "",
    },
  },

  vwlDetails: [
    {
      receptionThrough: "",
      sumBenefits: null,
      dateSince: "",
      contractNumber: null,
      iban: "",
      bic: "",
    },
  ],

  taxableEmployment: {
    previousEmployment: [
      {
        dateFrom: null,
        dateUntil: null,
        typeOccupation: "",
        daysOccupation: null,
      },
    ],
    miniJobberLimit: {
      moneyLimit: "",
    },
  },
};