import React from "react";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { PhoneInput } from "@/components/ui/phone-input";
import { UseFormReturn } from "react-hook-form";
import { t } from "i18next";
import { CompanyUpdateData } from "@/models/company";

const CompanyInfoFormFields = ({
  form,
}: {
  form: UseFormReturn<CompanyUpdateData>;
}) => {
  console.log(form.getValues())
  return (
    <div className={"grid grid-cols-2 gap-4"}>
      <FormField
        control={form.control}
        name="contactInfo.contactPersonName"
        render={({ field }) => (
          <FormItem>
            <FormLabel>{t("component.contacts.contactPersonName")}</FormLabel>
            <FormControl>
              <Input {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name="contactInfo.email"
        render={({ field }) => (
          <FormItem>
            <FormLabel>{t("component.contacts.email")}</FormLabel>
            <FormControl>
              <Input {...field} />
            </FormControl>

            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name="contactInfo.phone"
        render={({ field }) => (
          <FormItem>
            <FormLabel>{t("component.contacts.phone")}</FormLabel>
            <FormControl>
              <PhoneInput {...field} />
            </FormControl>

            <FormMessage />
          </FormItem>
        )}
      />{" "}
      <FormField
        control={form.control}
        name="contactInfo.website"
        render={({ field }) => (
          <FormItem>
            <FormLabel>{t("component.contacts.website")}</FormLabel>
            <FormControl>
              <Input {...field} />
            </FormControl>

            <FormMessage />
          </FormItem>
        )}
      />
    </div>
  );
};


export default CompanyInfoFormFields;
