import { useModal, useModalCallback } from "@/components/modal/useModal";
import { useTranslation } from "react-i18next";
import { Text } from "@/components/text/Text";
import { Title } from "@/components/text/Title";

import {
  FlexOne,
  HorizontalFlex,
  VerticalFlex,
} from "@/components/layout/Flex";
import { DateRangePicker } from "@/components/ui/date-range-picker";
import { useState } from "react";
import { Button } from "@/components/ui/button";
import { exportDatevAPI } from "@/api/endpoints/exportDatevApi";
import { simpleDateFrom, simpleDateToSimpleIso } from "@/models/document";
import Spinner from "@/components/loading/spinner";
import { triggerDownload } from "@/util/download";

export const useExportDocumentExportModal = () =>
  useModalCallback({ content: Content });

const Content: React.FC<{ close: () => void }> = ({ close }) => {
  let { t } = useTranslation();
  const [range, setRange] = useState<{
    from: Date;
    to: Date | undefined;
  }>();

  const [loading, setLoading] = useState<boolean>(false);

  const onDownload = () => {
    if (!range || !range?.to) {
      return;
    }

    let link = exportDatevAPI.exportDocuments({
      from: simpleDateToSimpleIso(simpleDateFrom(range.from)),
      to: simpleDateToSimpleIso(simpleDateFrom(range.to)),
    });

    setLoading(true);
    // this delay improves the readability of the UI
    window.setTimeout(() => {
      triggerDownload(link, "export.zip");
    }, 500);
    window.setTimeout(() => {
      close();
    }, 3000);
  };

  return (
    <VerticalFlex>
      <Title>{t("component.documents.export.document.title")}</Title>
      <Text muted>{t("component.documents.export.document.description")}</Text>
      <VerticalFlex align="center">
        <DateRangePicker preset={"month"} onUpdate={(r) => setRange(r.range)} />
      </VerticalFlex>
      <div />
      <HorizontalFlex justify="center">
        <Button disabled={!range?.to || loading} onClick={onDownload}>
          <HorizontalFlex gap={4}>
            {loading && <Spinner size={14} />}
            {t("common.download")}
          </HorizontalFlex>
        </Button>
      </HorizontalFlex>
    </VerticalFlex>
  );
};
