import {
  payrollTranslation,
  questionnaireTranslation,
} from "@/feature/payroll/components/TranslationConstants";
import { EmploymentTypeEnum } from "@/models/payroll";

export const getEmploymentTypeLabel = (
  employmentType: EmploymentTypeEnum,
): string => {
  switch (employmentType) {
    case EmploymentTypeEnum.MINIJOB:
      return questionnaireTranslation("miniJobContract");
    case EmploymentTypeEnum.EMPLOYEE:
      return questionnaireTranslation("normalEmployee");
    case EmploymentTypeEnum.TRAINEE:
      return questionnaireTranslation("trainee");
    default:
      return payrollTranslation("universal.unknown");
  }
};
