import React from "react";
import HorizontalGradientLine from "@/components/layout/HorizontalGradientLine";
import {
  payrollTranslation,
  questionnaireTranslation,
} from "@/feature/payroll/components/TranslationConstants";
import { QuestionnaireEmployeeType } from "@/feature/payroll/forms/questionnaire/schema/employee/FormSchemaEmployee";
import { Title } from "@/components/text/Title";
import { Underline } from "@/components/text/Underline";
import { HorizontalFlex } from "@/components/layout/Flex";

const ChildrenDetails: React.FC<{
  children: QuestionnaireEmployeeType["children"];
}> = ({ children }) => {
  return (
    <section className={"mb-8 font-light"}>
      <Underline>
        <Title level={4}>{questionnaireTranslation("childCard.kids")}</Title>
      </Underline>

      {children.length > 0 &&
        children.map((child, index) => (
          <HorizontalFlex
            key={index}
            className="mb-4 grid grid-cols-2 gap-4 md:grid-cols-4"
          >
            <span className="col-span-1 font-medium">
              {payrollTranslation("universal.lastName")}:
            </span>
            <span className="col-span-1">{child.lastName || "-"}</span>
            <span className="col-span-1 font-medium">
              {payrollTranslation("universal.firstName")}:
            </span>
            <span className="col-span-1">{child.firstName || "-"}</span>
            <span className="col-span-1 font-medium">
              {payrollTranslation("universal.birthDate")}:
            </span>
            <span className="col-span-1">
              {" "}
              {child.birthDate
                ? new Date(child.birthDate).toLocaleDateString("de-DE")
                : "-"}
            </span>
            <HorizontalGradientLine className={"col-span-4"} />
          </HorizontalFlex>
        ))}
    </section>
  );
};

export default ChildrenDetails;
