import React, { useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { Form } from "@/components/ui/form";
import { Button } from "@/components/ui/button";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  InvoiceDraft,
  InvoiceRequest,
  invoiceRequestSchema,
} from "@/models/invoice";
import { useDebounce } from "@/util/debounce";
import BuyerCard from "@/feature/invoices/edit/form/components/buyer/BuyerCard";
import GeneralCard from "@/feature/invoices/edit/form/components/general/GeneralCard";
import ItemsAccordionItem from "@/feature/invoices/edit/form/components/items/ItemsAccordionItem";
import { Accordion } from "@/components/ui/accordion";
import { invoiceApi } from "@/api/endpoints/invoiceApi";
import AdditionalInformationCard from "@/feature/invoices/edit/form/components/AdditionalInformationCard";
import { Mail, MailOpen, TicketX } from "lucide-react";
import { useNavigate } from "react-router-dom";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import { HorizontalFlex, VerticalFlex } from "@/components/layout/Flex";
import { useToast } from "@/components/ui/use-toast";
import useIsScrollingUp from "@/util/scroll";
import { cn } from "@/lib/utils";
import ConfirmFinalizeInvoice from "@/feature/invoices/edit/ConfirmFinalizeInvoice";
import { t } from "i18next";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";

interface InvoiceFormProps {
  setPreview: (preview: string) => void;
  invoiceDraft: Partial<InvoiceDraft>;
}

export const EditInvoiceForm = ({
  setPreview,
  invoiceDraft,
}: InvoiceFormProps) => {
  const { toast } = useToast();
  const navigate = useNavigate();
  const form = useForm<InvoiceRequest>({
    resolver: zodResolver(invoiceRequestSchema),
    mode: "onTouched",
    defaultValues: invoiceDraft.draftRequest as InvoiceRequest,
  });
  const formData = useWatch({ control: form.control });

  const debouncedFormData = useDebounce(formData, 700);

  const [triggerPreview, { data, isLoading }] =
    invoiceApi.useLazyPreviewQuery();

  const [update] = invoiceApi.useUpdateMutation();
  const [finalize] = invoiceApi.useFinalizeMutation();

  useEffect(() => {
    if (debouncedFormData && invoiceDraft.id) {
      triggerPreview({ id: invoiceDraft.id, body: debouncedFormData })
        .then((response) => {
          if (response.data) {
            setPreview(response.data);
          }
        })
        .catch((err) => {
          console.error("Error fetching preview:", err);
        });
      update({ id: invoiceDraft.id, body: debouncedFormData });
    }
  }, [debouncedFormData, triggerPreview, setPreview, invoiceDraft.id, update]);

  useEffect(() => {
    if (data && !isLoading) {
      setPreview(data);
    }
  }, [data, isLoading, setPreview]);

  const handleSave = (values: InvoiceRequest) => {
    if (!invoiceDraft.id) return;
    update({ id: invoiceDraft.id, body: values }).then((response) => {
      if ("data" in response) {
        toast({
          icon: MailOpen,
          title: t("common.success"),
          description: t("component.invoices.saveSuccess"),
        });
      }
    });
  };

  const onSubmit = async () => {
    if (!form.formState.isValid) {
      toast({
        title: t("common.error") + "!",
        description: t("common.formError"),
      });
      console.log(form.formState.errors);
      return;
    }
  };

  const [accordionValue, setAccordionValue] = useState([
    "buyer",
    "general",
    "items",
    "additional",
  ]);

  const isScrollingUp = useIsScrollingUp();

  const [alertOpen, setAlertOpen] = useState(false);

  return (
    <Form {...form}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (form.formState.isValid) {
            onSubmit().then((r) => {});
          }
        }}
        className="flex h-full w-full flex-col"
        autoComplete="off nope"
      >
        <HorizontalFlex
          className={cn(
            "sticky top-16 z-20 mb-4 rounded-xl border bg-muted/60 px-4 py-1 shadow-background backdrop-blur-md transition-all",
            isScrollingUp ? "top-16" : "top-0",
          )}
        >
          <VerticalFlex justify={"center"}>
            <Breadcrumb>
              <BreadcrumbList>
                <BreadcrumbItem>
                  <BreadcrumbLink href="/invoices">
                    {t("component.app.sidebar.invoice")}
                  </BreadcrumbLink>
                </BreadcrumbItem>
                <BreadcrumbSeparator />
                <BreadcrumbItem>
                  <BreadcrumbLink>
                    {t("component.invoices.editInv", {
                      invoiceNumber: invoiceDraft.invoiceNumber,
                    })}
                  </BreadcrumbLink>
                </BreadcrumbItem>
              </BreadcrumbList>
            </Breadcrumb>
          </VerticalFlex>
          <HorizontalFlex className={"ml-auto"}>
            {!form.formState.isValid && (
              <Button
                type={"button"}
                size={"icon"}
                variant={"ghost"}
                title={t("component.invoices.check")}
                className={"text-red-500/50 hover:text-red-500"}
                onClick={() => form.trigger()}
              >
                <TicketX size={16} />
              </Button>
            )}
            <AlertDialog open={alertOpen} onOpenChange={setAlertOpen}>
              <Button
                type="button"
                variant={"default"}
                className={cn(
                  "card-shine-effect group relative overflow-hidden p-3 pl-7 hover:animate-gradientShift",
                )}
                title={
                  !form.formState.isValid
                    ? t("component.invoices.formNotValid")
                    : t("common.complete")
                }
                onClick={(e) => {
                  if (!form.formState.isValid) {
                    e.preventDefault();
                    form.trigger();
                    return;
                  } else {
                    setAlertOpen(true);
                  }
                }}
              >
                <Mail
                  size={16}
                  className="absolute left-4 top-1/2 -translate-x-1/2 -translate-y-1/2 scale-100 transform text-purple-900/50 opacity-0 transition-all duration-1000 ease-in-out group-hover:rotate-12 group-hover:scale-125 group-hover:opacity-100"
                />
                <MailOpen
                  size={16}
                  className="absolute left-4 top-1/2 -translate-x-1/2 -translate-y-1/2 transform text-purple-500 transition-all duration-200 ease-in-out group-hover:translate-y-[-10px] group-hover:opacity-0"
                />
                {t("common.complete")}
              </Button>

              <AlertDialogContent>
                <AlertDialogHeader>
                  <AlertDialogTitle>
                    {t("component.invoices.confirmTitle")}
                  </AlertDialogTitle>
                  <AlertDialogDescription>
                    <ConfirmFinalizeInvoice />
                  </AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter>
                  <AlertDialogCancel>{t("common.cancel")}</AlertDialogCancel>
                  <AlertDialogAction
                    onClick={() => {
                      finalize({
                        id: invoiceDraft.id!,
                        body: form.getValues(),
                      }).then((response) => {
                        if ("data" in response) {
                          toast({
                            icon: Mail,
                            title: t("common.success"),
                            description: t(
                              "component.invoices.finalizeSuccess",
                            ),
                          });
                        }
                      });
                      navigate(`/inv/${invoiceDraft.id}/finalize`);
                    }}
                  >
                    {t("common.confirm")}
                  </AlertDialogAction>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialog>
          </HorizontalFlex>
        </HorizontalFlex>

        <Accordion
          type={"multiple"}
          className="flex h-full flex-col gap-4"
          defaultValue={accordionValue}
          value={accordionValue}
          onValueChange={setAccordionValue}
        >
          <BuyerCard form={form} />
          <GeneralCard form={form} />
          <ItemsAccordionItem form={form} />
          <AdditionalInformationCard form={form} />
        </Accordion>
        <div
          className={
            "sticky bottom-0 grid grid-cols-4 gap-2 rounded-xl border bg-muted/60 px-4 py-1 shadow-background backdrop-blur-md"
          }
        >
          <Button
            type="reset"
            className="col-start-3 hover:bg-red-900"
            variant={"ghost"}
            onClick={() => navigate(-1)}
          >
            {t("common.abort")}
          </Button>
          <Button type={"button"} onClick={() => handleSave(form.getValues())}>
            {t("common.save")}
          </Button>
        </div>
      </form>
    </Form>
  );
};
