import React, { useEffect, useMemo, useState } from "react";
import { z } from "zod";
import { Progress } from "@/components/ui/progress";
import { NavigationButtons } from "@/feature/payroll/components/formFunctions/NavigationButtons";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { AlertCircle } from "lucide-react";
import { payrollTranslation } from "@/feature/payroll/components/TranslationConstants";
import { HorizontalFlex, VerticalFlex } from "@/components/layout/Flex";
import { MultiStepCardProps } from "@/models/payroll";
import { Title } from "@/components/text/Title";

const MultiStepCard = <T extends z.ZodTypeAny>({
  form,
  setCurrentTitle,
  onStepChange,
  onTotalStepsChange,
  steps,
}: MultiStepCardProps<T>) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [showAlert, setShowAlert] = useState(false);

  // Filter steps based on form values and filters in step definition
  const filteredSteps = useMemo(() => {
    const values = form.getValues() as z.infer<T>;
    return steps.filter((step) => (step.filter ? step.filter(values) : true));
  }, [currentStep, steps]);

  const totalSteps = filteredSteps.length;
  // Update total steps if it changes
  useEffect(() => {
    if (onTotalStepsChange) {
      onTotalStepsChange(totalSteps);
    }
  }, [totalSteps, onTotalStepsChange]);

  // Handle moving to the next step, after validating the current step fields
  const nextStep = async () => {
    const fieldsInCurrentComponent = filteredSteps[currentStep].requiredFields;
    const result = await form.trigger(fieldsInCurrentComponent);

    if (result) {
      if (currentStep < totalSteps - 1) {
        const newStep = currentStep + 1;
        setCurrentStep(newStep);
        onStepChange?.(newStep);
        setShowAlert(false);
      }
    } else {
      setShowAlert(true);
    }
  };

  // Handle moving to the previous step
  const prevStep = () => {
    if (currentStep > 0) {
      const newStep = currentStep - 1;
      setCurrentStep(newStep);
      onStepChange?.(newStep);
    }
  };

  // Calculate progress percentage based on the current step
  const progressPercentage = useMemo(() => {
    return (currentStep / (totalSteps - 1)) * 100;
  }, [currentStep, totalSteps]);

  // Update title of the current step when it changes
  useEffect(() => {
    const currentTitleComponent = filteredSteps[currentStep].title;
    if (setCurrentTitle) {
      setCurrentTitle(currentTitleComponent);
    }
  }, [currentStep, filteredSteps, setCurrentTitle]);

  return (
    <VerticalFlex>
      <HorizontalFlex>
        <Title level={2}>{filteredSteps[currentStep].title}</Title>
        <span className="flex items-center justify-start">
          {filteredSteps[currentStep].icon}
        </span>
      </HorizontalFlex>
      <Progress value={progressPercentage} className="mt-2 w-full" />

      <VerticalFlex className="card w-full p-4">
        {filteredSteps[currentStep].component}
        {/* back and forth buttons */}
        <NavigationButtons
          currentStep={currentStep}
          totalSteps={totalSteps}
          onNext={nextStep}
          onPrev={prevStep}
        />
      </VerticalFlex>

      <VerticalFlex className={"mb-4 mt-4 w-full"}>
        {showAlert && (
          <Alert variant="destructive">
            <AlertCircle className="h-4 w-4" />
            <AlertTitle>{payrollTranslation("universal.error")}</AlertTitle>
            <AlertDescription>
              {payrollTranslation("universal.warning")}
            </AlertDescription>
          </Alert>
        )}
      </VerticalFlex>
    </VerticalFlex>
  );
};

export default MultiStepCard;
