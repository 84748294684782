import { useModalCallback } from "@/components/modal/useModal";
import { useTranslation } from "react-i18next";
import { Text } from "@/components/text/Text";
import { Title } from "@/components/text/Title";

import { HorizontalFlex, VerticalFlex } from "@/components/layout/Flex";
import { DateRangePicker } from "@/components/ui/date-range-picker";
import { useState } from "react";
import { Button } from "@/components/ui/button";
import { exportDatevAPI } from "@/api/endpoints/exportDatevApi";
import { simpleDateFrom, simpleDateToSimpleIso } from "@/models/document";
import Spinner from "@/components/loading/spinner";
import { Switch } from "@/components/ui/switch";
import { Checkbox } from "@/components/ui/checkbox";
import { Badge } from "@/components/ui/badge";
import { useFeatureFlag } from "@/util/useFeatureFlag";
import { FeatureFlags } from "@/models/user";
import { triggerDownload } from "@/util/download";

export const useExportDatevExportModal = () =>
  useModalCallback({ content: (props) => <Content {...props} /> });

const Content: React.FC<{ close: () => void }> = ({ close }) => {
  let { t } = useTranslation();
  const [range, setRange] = useState<{
    from: Date;
    to: Date | undefined;
  }>();

  const [includeDocuments, setIncludeDocuments] = useState(true);
  const [includeBookings, setIncludeBookings] = useState(true);
  const [includeOnlyNew, setIncludeOnlyNew] = useState(true);

  const [loading, setLoading] = useState<boolean>(false);

  const onDownload = () => {
    if (!range || !range?.to) {
      return;
    }

    let link = exportDatevAPI.exportDatev({
      documents: includeDocuments,
      bookings: includeBookings,
      onlyNew: includeOnlyNew,
      from: simpleDateToSimpleIso(simpleDateFrom(range.from)),
      to: simpleDateToSimpleIso(simpleDateFrom(range.to)),
    });

    setLoading(true);
    // this delay improves the readability of the UI
    window.setTimeout(() => {
      triggerDownload(link, "export.zip");
    }, 500);
    window.setTimeout(() => {
      close();
    }, 3000);
  };

  return (
    <VerticalFlex>
      <Title>{t("component.documents.export.datev.title")}</Title>
      <Text muted>
        {t("component.documents.export.datev.description" + "_true")}
      </Text>
      <HorizontalFlex justify={"center"}>
        <VerticalFlex>
          <HorizontalFlex>
            <Switch
              onCheckedChange={setIncludeDocuments}
              checked={includeDocuments}
            />{" "}
            <Text>{t("component.documents.export.datev.documents")}</Text>
          </HorizontalFlex>
          <HorizontalFlex>
            <Switch
              onCheckedChange={setIncludeBookings}
              checked={includeBookings}
            />{" "}
            <Text>
              {t("component.documents.export.datev.bookings")}{" "}
              <Badge variant="blue">BETA</Badge>
            </Text>
          </HorizontalFlex>
        </VerticalFlex>
      </HorizontalFlex>
      <div />
      <Text muted>{t("component.documents.export.datev.description2")}</Text>
      <VerticalFlex justify="center">
        <DateRangePicker preset={"month"} onUpdate={(r) => setRange(r.range)} />
      </VerticalFlex>
      <HorizontalFlex justify="center">
        <Checkbox
          onCheckedChange={(c) => setIncludeOnlyNew(!!c)}
          checked={includeOnlyNew}
        />{" "}
        <Text>Nur Neue exportieren</Text>
      </HorizontalFlex>
      <div />
      <HorizontalFlex justify="center">
        <Button
          disabled={
            !range?.to || loading || (!includeBookings && !includeDocuments)
          }
          onClick={onDownload}
        >
          <HorizontalFlex gap={4}>
            {loading && <Spinner size={14} />}
            {t("common.download")}
          </HorizontalFlex>
        </Button>
      </HorizontalFlex>
    </VerticalFlex>
  );
};
