import React from "react";
import {
  FlexOne,
  HorizontalFlex,
  VerticalFlex,
} from "@/components/layout/Flex";
import { Title } from "@/components/text/Title";
import { CreateInvoiceComboButton } from "@/feature/invoices/create/CreateInvoiceComboButton";
import InvoiceTable from "@/feature/invoices/read/InvoiceTable";
import { t } from "i18next";
import GuardConditionCheck from "@/feature/invoices/Guard";

const Invoices = () => {
  return (
    <VerticalFlex gap={8}>
      <GuardConditionCheck>
        <HorizontalFlex className="pb-1">
          <Title>{t("component.invoices.my")}</Title>
          <FlexOne />
          <CreateInvoiceComboButton />
        </HorizontalFlex>
        <InvoiceTable />
      </GuardConditionCheck>
    </VerticalFlex>
  );
};

export default Invoices;
