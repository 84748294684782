import { HorizontalFlex } from "@/components/layout/Flex";
import { InvoiceDraft } from "@/models/invoice";
import { useInvoiceActionHandlers } from "@/feature/invoices/read/_table/useInvoiceActionHandlers";
import InvoiceActionButton from "@/feature/invoices/read/_table/InvoiceActionButton";
import {
  Copy,
  DownloadIcon,
  MoreHorizontal,
  Pencil,
  Trash,
  Undo,
} from "lucide-react";
import { t } from "i18next";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";

export const InvoiceActions = ({
  invoice,
  setSelectedInvoiceId,
  selectedInvoiceId,
}: {
  invoice: InvoiceDraft;
  setSelectedInvoiceId: (
    value:
      | ((prevState: string | undefined) => string | undefined)
      | string
      | undefined,
  ) => void;
  selectedInvoiceId: string | undefined;
}) => {
  const {
    handlers: {
      handleEdit,
      handleDuplicate,
      handleDelete,
      handleCancellation,
      handleDownload,
    },
    states: { isCancelling, isDuplicating },
  } = useInvoiceActionHandlers({
    invoice,
    setSelectedInvoiceId,
    selectedInvoiceId,
  });

  return (
    <HorizontalFlex className="flex-row-reverse gap-2">
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            variant="ghost"
            className="flex h-8 w-8 p-0 data-[state=open]:bg-muted"
          >
            <MoreHorizontal />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end" className="w-[160px]">
          {handleCancellation && invoice.typeCode !== "384" && (
            <DropdownMenuItem
              onClick={handleCancellation}
              disabled={isCancelling}
            >
              <InvoiceActionButton
                icon={<Undo size={16} />}
                label={t("component.invoices.table.cancel")}
                loading={isCancelling}
              />
            </DropdownMenuItem>
          )}

          {handleDownload && (
            <DropdownMenuItem onClick={handleDownload}>
              <InvoiceActionButton
                icon={<DownloadIcon size={16} />}
                label={t("component.invoices.table.download")}
              />
            </DropdownMenuItem>
          )}
          {invoice.typeCode === "380" && (
            <DropdownMenuItem
              onClick={handleDuplicate}
              disabled={isDuplicating}
            >
              <InvoiceActionButton
                icon={<Copy size={16} />}
                label={t("component.invoices.table.duplicate")}
                loading={isDuplicating}
              />
            </DropdownMenuItem>
          )}

          {handleDelete && invoice.status === "DRAFT" && (
            <DropdownMenuItem onClick={handleDelete}>
              <InvoiceActionButton
                icon={<Trash size={16} />}
                label={t("component.invoices.table.delete")}
              />
            </DropdownMenuItem>
          )}
        </DropdownMenuContent>
      </DropdownMenu>

      {handleEdit && (
        <Button onClick={handleEdit} size={"default"} variant={"ghost"}>
          <Pencil size={16} className={"ml-auto mr-2 h-4 w-4"} />
          {t("component.invoices.table.edit")}
        </Button>
      )}
    </HorizontalFlex>
  );
};

export default InvoiceActions;
