import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useToast } from "@/components/ui/use-toast";
import { useConfirmModal } from "@/components/modal/useConfirmModal";
import { invoiceApi } from "@/api/endpoints/invoiceApi";
import { CreateInvoiceRequest, InvoiceDraft } from "@/models/invoice";
import { preventBubbling } from "@/lib/utils";
import { t } from "i18next";
import { Check } from "lucide-react";
import { documentApi } from "@/api/endpoints/documentApi";
import { blobUrl } from "@/api/reduxApi";
import { triggerDownload } from "@/util/download";

interface UseInvoiceActionHandlersProps {
  invoice: InvoiceDraft;
  setSelectedInvoiceId?: (
    value:
      | ((prevState: string | undefined) => string | undefined)
      | string
      | undefined,
  ) => void;
  selectedInvoiceId?: string;
}

export const useInvoiceActionHandlers = ({
  invoice,
  setSelectedInvoiceId,
  selectedInvoiceId,
}: UseInvoiceActionHandlersProps) => {
  const [create] = invoiceApi.useCreateInvoiceDraftMutation();
  const [deleteInvoice] = invoiceApi.useDeleteMutation();
  const [getDocument] = documentApi.useLazyGetDocumentByIdQuery();
  const [isCancelling, setIsCancelling] = useState(false);
  const [isDuplicating, setIsDuplicating] = useState(false);

  const navigation = useNavigate();
  const { toast } = useToast();
  const { confirmDelete } = useConfirmModal();

  const isEditable = invoice.status === "DRAFT";
  const isCancellable = invoice.status !== "CANCELLED";
  const isDownloadable =
    invoice.documentId &&
    invoice.documentId !== "00000000-0000-0000-0000-000000000000"; // <- Note: this is because of [WEB-364] bug.

  const handlePreview = preventBubbling(() => {
    if (!setSelectedInvoiceId) return;

    if (selectedInvoiceId === invoice.id) {
      setSelectedInvoiceId(undefined);
      return;
    }
    setSelectedInvoiceId(invoice.id);
  });

  const handleEdit = preventBubbling(() => {
    navigation(
      `/inv/${invoice.id}${invoice.typeCode === "384" ? "/cancel" : ""}`,
    );
  });

  const handleDuplicate = preventBubbling(async () => {
    setIsDuplicating(true);
    const req: CreateInvoiceRequest = {
      typeCode: "380", // 380 = Normale Rechnung
      originalInvoiceId: invoice.id,
    };

    try {
      const res = await create(req);
      if ("data" in res) {
        navigation(`/inv/${res.data.id}`);
      }
    } catch (error) {
      toast({
        title: t("common.error") + "!",
        description: t("component.invoices.duplicateError"),
        variant: "destructive",
      });
    } finally {
      setIsDuplicating(false);
    }
  });

  const handleDelete = preventBubbling(() => {
    confirmDelete({
      text: t("component.invoices.deleteConfirm", {
        invoiceNumber: invoice.invoiceNumber,
      }),
      onAction: async () => {
        const res = await deleteInvoice(invoice.id);
        if ("data" in res) {
          setSelectedInvoiceId?.(undefined);
          toast({ title: t("common.successDelete"), icon: Check });
        }
      },
    });
  });

  const handleCancellation = preventBubbling(async () => {
    setIsCancelling(true);
    const req: CreateInvoiceRequest = {
      typeCode: "384", // 384 = Stornorechnung
      originalInvoiceId: invoice.id,
    };

    try {
      const res = await create(req);
      if ("data" in res) {
        navigation(`/inv/${res.data.id}/cancel`);
      }
    } catch (error) {
      toast({
        title: t("common.error") + "!",
        description: t("component.invoices.cancelError"),
        variant: "destructive",
      });
    } finally {
      setIsCancelling(false);
    }
  });

  const handleDownload = preventBubbling(async () => {
    const { data } = await getDocument(invoice.documentId);
    if (data === undefined) return null;

    const blobData = data.uploadDocument?.blob;
    if (!blobData) {
      return null;
    }

    const url = blobUrl(blobData);
    triggerDownload(url);
  });

  return {
    handlers: {
      handlePreview,
      handleEdit: isEditable ? handleEdit : undefined,
      handleDuplicate: handleDuplicate,
      handleDelete,
      handleCancellation: isCancellable ? handleCancellation : undefined,
      handleDownload: isDownloadable ? handleDownload : undefined,
    },
    states: {
      isCancelling,
      isDuplicating,
    },
  };
};
